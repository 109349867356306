import React from 'react';
import Loading from '../Loading'
import SemImagem from '../../assets/images/sem-imagem.jpg';

const ShowImageUploading = ({
    width = '100px',
    height = '100px',
    image = '',
    loading = false,
    status = 0,
}) => {

    const hasImage = (image !== '');
    const divStyle={
        width: width,
        height: height,
        border: '1px solid #ced4da'
    }

    const showMessage = () => {
        const arr = [
            (<div></div>), 
            (<div>"Imagem não carregada"</div>), 
            (<div>"Imagem carregada com sucesso"</div>)
        ];

        return arr[status];
    }
    
    const showImage = (
        <img src={ hasImage ? image : SemImagem } alt="" style={divStyle} />
    )
    return (
        <>
            <div style={divStyle}>
                {loading ? (<Loading containerStyle={divStyle} />) : showImage }
            </div>
            
        </>
    );

}

export default ShowImageUploading;