import React, { useContext } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { isAuthenticated } from "./services/NexiDigitalAPI"
import Login from "./containers/Login";
import AtivaCadastro from "./containers/AtivaCadastro";
import Termos from "./containers/Termos";
import Registro from "./containers/Registro";
import Cockpit from "./containers/Cockpit";
import User from "./containers/User";
import Obrigado from "./containers/Obrigado";
import AddUser from "./containers/AddUser";
import Reports from "./containers/Reports";
import SideBar from "./containers/SideBar";
import EditUser from "./containers/EditUser";
import ImportUsers from "./containers/ImportUsers"
import Header from './containers/Header'
import { colors } from './utils/Colors'
import { transformText, getMenuRoutes } from './utils/Utils';

//CMS
import MenuSelector from "./containers/MenuSelector";
import GestaoConteudo from "./containers/cms/GestaoConteudo"
import TelaConfiguracao from "./containers/configuracao/Configuracao"
import LayoutManager from "./containers/LayoutManager/LayoutManager.js"
import ContentListGroup from "./containers/cms/ContentListGroup"
import TemplateSelector from "./containers/cms/TemplateSelector";
import ZoomMeetings from "./containers/configuracao/ZoomMeetings";
import ZoomAuth from "./containers/configuracao/ZoomAuth";
import Tags from "./containers/configuracao/Tags"
import Groups from "./containers/configuracao/Groups";
import GroupDetail from "./containers/configuracao/Groups/GroupDetail";
import { AuthContext } from "./context/AuthContext";
import Upload from "./containers/cms/UploadContent";
import Biblioteca from "./containers/Biblioteca";
import Profiles from "./containers/Profiles";
import updateProfile from "./containers/Profiles/updateProfile";
import Loja from "./containers/Loja";
import Campaigns from "./containers/Gamification/Campaigns";
import ReportsGamification from "./containers/Gamification/Reports/";
import Calendario from "./containers/Calendario";
import CompanyManager from "./containers/CompanyManager/CompanyManager";
import AddCompany from "./containers/CompanyManager/AddCompany";
import EditCompany from "./containers/CompanyManager/EditCompany";
import AppColorManager from "./containers/AppColorManager/";
import QrcodeManager from "./containers/QrcodeManager";
import QrAddUser from "./containers/QrAddUser";
import SectionDesign from "./containers/LayoutManager/SectionDesign";
import MenuDesign from "./containers/LayoutManager/MenuDesign";

function Routes({location}) {
    const { loginData } = useContext(AuthContext)
    const isAdmin = loginData?.isAdmin || 0

    const PrivateRoute = ({ component: Component, ...rest }) => (
        <Route
            {...rest}
            render={props => {
                return (
                    isAuthenticated() ? (
                            <div className="route-section-content" style={{ backgroundColor: colors.blueSmooth }}>
                                <Component {...props} />
                            </div>
                        ) : (
                            <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
                        )
                )
            }
            }
        />
    )

    function showSideBar(location){
        // Define quais rotas NÃO deve mostrar o Side Bar
        const showOnRoutes = [
            "/login",
            "/termos",
            "/registro",
            "/obrigado",
            "/selfregister"
        ]
        let showSideBar = showOnRoutes.includes(location.pathname)
        return !showSideBar
    }

    function getRoutes(){
        let routes = JSON.parse(localStorage.getItem("contentGroups"))

        if(routes && routes.length > 0){
            return routes
        }else{
            return []
        }

    }

    return (
        <div style={{ height: '100%', display: 'flex' }}>
            {
                showSideBar(location) &&
                <>
                    <SideBar />
                    <Header />
                </>
            }
            {
            <section className="route-section">
                <Route exact path="/login" component={Login} />
                <Route exact path="/ativa" component={AtivaCadastro} />
                <Route exact path="/termos" component={Termos} />
                <Route exact path="/registro" component={Registro} />
                <Route exact path="/selfregister" component={QrAddUser} />

                <PrivateRoute exact path="/" component={Cockpit} />
                <PrivateRoute path="/layout-manager" component={LayoutManager} />
                <PrivateRoute path="/section-design/:sectionId" component={SectionDesign} />
                <PrivateRoute path="/menu-design/:sectionId" component={MenuDesign} />
                {
                    // Admin only routes
                    isAdmin === 1 &&
                    <>
                        <PrivateRoute exact path="/user" component={User} />
                        <PrivateRoute exact path="/reports" component={Reports} />
                        <PrivateRoute exact path="/configuracao" component={TelaConfiguracao} />
                        <PrivateRoute path="/configuracao/zoom_auth" component={ZoomAuth} />
                        <PrivateRoute path="/configuracao/zoom" component={ZoomMeetings} />
                        <PrivateRoute path="/configuracao/tags" component={Tags} />
                        <PrivateRoute path="/configuracao/gestao-de-cor-e-logo/" component={AppColorManager} />
                        <PrivateRoute exact path="/configuracao/grupos" component={Groups} />
                        <PrivateRoute path="/configuracao/grupos/:groupId/:groupTitle" component={GroupDetail} />
                    </>
                }
                    <PrivateRoute path="/biblioteca/:sectionto" component={() => <Biblioteca /> } />
                    <PrivateRoute path="/upload/:id" component={(props) => <Upload {...props} /> } />
                    {
                        getRoutes().map((item, index) => {
                            if(item.text === "") {return} // If path name is empty, return nothing
                            return (
                                <PrivateRoute key={index} path={`/gestao-de-conteudo/${transformText(item.name)}/`} component={() => <ContentListGroup item={item} />} />
                            )
                        })
                    }
                    {
                        getMenuRoutes().map((item, index) => {
                            if(item.text === "") {return} // If path name is empty, return nothing
                            return (
                                <PrivateRoute key={index} path={`/${transformText(item.text)}/${item.sectionTo}`} component={() => <MenuSelector />} />
                            )
                        })
                    }

                    <PrivateRoute exact path="/loja" component={Loja} />
                    <PrivateRoute exact path="/gamification/campaigns" component={Campaigns} />
                    <PrivateRoute exact path="/gamification/campaigns/:id" component={Campaigns} />                    
                    <PrivateRoute exact path="/gamification/reports/:id" component={ReportsGamification} />     
                    <PrivateRoute exact path="/cms" component={GestaoConteudo} />
                    {/* <PrivateRoute exact path="/gestao-de-agenda/:id" component={GestaoAgenda} /> */}
                    <PrivateRoute exact path="/calendario" component={Calendario} />                    
                    <PrivateRoute path="/import-users" component={ImportUsers} />
                    <PrivateRoute path="/user/add" component={AddUser} />
                    <PrivateRoute path="/add-company" component={AddCompany} />
                    <PrivateRoute path="/edit-company" component={EditCompany} />
                    <PrivateRoute path="/company-manager" component={CompanyManager} />
                    <Route exact path="/obrigado" component={Obrigado} />
                    <PrivateRoute exact path="/edit-user" component={EditUser} />
                    <PrivateRoute path="/cms/content/:id" component={() => <TemplateSelector /> } />
                    <PrivateRoute path="/profiles" component={Profiles} />
                    <PrivateRoute path="/updateprofile/:idProfile" component={updateProfile} />
                    <PrivateRoute path="/app-color-manager/" component={AppColorManager} />
                    <PrivateRoute path="/qrcode-manager/" component={QrcodeManager} />
            </section>
        }
        </div>
    );
}

export default withRouter(Routes)
