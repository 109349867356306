import React from 'react';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import styles from './styles.module.scss'

const Table = ({ dados ,loading }) => {
    const columns = [
      {
        name: "Evento",
        selector: "event",
        sortable: true,
        center: true,
      },
      {
        name: "Pontuação",
        selector: "points",
        sortable: true,
        center: true,
      },
      {
        name: "Data/Hora",
        selector: "eventDate",
        sortable: true,
        center: true,
      },
      
    ];
 
    
    const tableData = {
        columns: columns,
        data: dados
    }

    const loadingComponent = () => {
        return (
          <div className="tela-login-loading">
            <div className="loader-container" style={{ margin: '300px 0' }}>
              <div
                style={{
                  //   borderTopColor: colors.gray,
                  position: 'initial',
                  margin: 'initial'
                }}
                className="loader loader-center loader-big">
              </div>
              <span style={{
                marginTop: 10,
                // color: colors.gray,
                fontSize: 20
              }}>
                Carregando dados...
              </span>
            </div>
          </div>
        )
      }
    return (
        <DataTableExtensions filter={false} print={false} {...tableData} exportHeaders={true}>
          <DataTable
            columns={columns}
            data={dados}
            pagination
            className={styles.dataTableFormat}            
            progressPending={ loading }
            progressComponent={loadingComponent()}
            noHeader={true}
            noDataComponent="Não foi encontrado nenhum dado"
           
          />
        </DataTableExtensions>
    )

}

export default Table;