import React, { useEffect, useState } from "react";
import Switch from "@material-ui/core/Switch";
import Loading from "../../components/Loading";
import axios from "axios";
import "./styles.scss";
import { getSectionsStructureList } from "../../services/NexiDigitalAPI";
import SortableTree from "react-sortable-tree";

function MenuManagement() {
  const [menuList, setMenuList] = useState([]);

  const [loading, setLoading] = useState(true);

  const api_url = process.env.REACT_APP_BACKEND_API;
  const token = localStorage.getItem("token");
  const appDetails = JSON.parse(localStorage.getItem("appDetails"));

  const handleChange = async (menu) => {
    try {
      if (menu.hidden) {
        await axios.delete(`${api_url}/companies/hiddenMenus/${menu.idMenu}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          "Content-Type": "application/json",
        });
      } else {
        await axios.post(
          `${api_url}/companies/hiddenMenus/${menu.idMenu}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            "Content-Type": "application/json",
          }
        );
      }
      let oldList = [];
      setMenuList((old) => {
        oldList = old;
        return old;
      });
      setMenuList(await getSectionsList(oldList));
    } catch (err) {
      console.log(err);
    }
  };

  function transformArray(sectionListArray, old) {
    if (!Boolean(sectionListArray)) {
      return [];
    }
    sectionListArray.forEach((item, index) => {
      item.title = (
        <div>
          <Switch
            color="primary"
            checked={!item.hidden}
            onChange={() => handleChange(item, index)}
            name="antoine"
          />
          {item.text}
        </div>
      );
      item.expanded = old ? old[index].expanded : true;
      if (item.children?.length > 0) {
        item.children = transformArray(item.children);
      }
    });

    return sectionListArray;
  }

  const getSectionsList = async (old) => {
    try {
      const res = await getSectionsStructureList(appDetails?.idAppType);
      let result = res?.result?.appStructure.filter((item) => item.active);
      return transformArray(result, old);
    } catch (err) {
      console.log("Erro na chamada de API.", err);
    } finally {
      setLoading(false);
    }
  };

  const setPageValues = async () => {
    setMenuList(await getSectionsList());
  };

  useEffect(() => {
    setPageValues();
  }, []);

  return (
    <>
      <div className="header-title-wrapper">
        <div className="header-title">Gestão do Menu</div>
      </div>
      {loading ? (
        <Loading center> Carregando lista de menu... </Loading>
      ) : (
        <SortableTree
          treeData={menuList}
          canDrag={false}
          onChange={(treeData) => setMenuList(treeData)}
        />
      )}
    </>
  );
}

export default MenuManagement;
