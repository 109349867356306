import { getMenuData, getToken } from '../services/NexiDigitalAPI';
import jwtDecode from 'jwt-decode'

import BallotIcon from "@material-ui/icons/Ballot";
import PersonIcon from "@material-ui/icons/Person";
import AssessmentIcon from "@material-ui/icons/Assessment";
import SettingsIcon from "@material-ui/icons/Settings";
import PublishIcon from "@material-ui/icons/Publish";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import StoreIcon from '@material-ui/icons/Store';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import PeopleIcon from '@material-ui/icons/People';
import CreateIcon from '@material-ui/icons/Create';
import ListIcon from '@material-ui/icons/List';
import DashboardIcon from '@material-ui/icons/Dashboard';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import LockOpenIcon from '@material-ui/icons/LockOpen';

export function getMenuIconTypes(idSectionType) {
  switch (idSectionType) {
    case 26: return BallotIcon;
    case 27: return PersonIcon;
    case 28: return AssessmentIcon;
    case 29: return DashboardIcon;
    case 30: return SettingsIcon;
    case 35: return PublishIcon;
    case 37: return AccountBalanceIcon;
    case 39: return PeopleIcon;
    case 40: return CalendarTodayIcon;
    case 41: return StoreIcon;
    case 42: return SportsEsportsIcon;
    case 46: return CreateIcon;
    case 47: return LockOpenIcon;
    case 50: return ListIcon;
    default: return NewReleasesIcon;
  }
}

export function desabilitaConsoleLog() {
  console.log = function () { }
  console.warn = function () { }
  console.info = function () { }
}

function parseJwtToken(token) {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}

export function getProfileInfo() {
  let token = {}
  try {
      token = jwtDecode(getToken())
  } catch {
      console.log('error on token get')
  }
  return token
}

export {
  parseJwtToken
}

export function transformText(text) {
  return text.toLowerCase().replace(/ /g, "-").normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

export function capitalizeSentence(str) {
  return str.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
}

export function capitalizeFirstLetter(str) {
  if (typeof str !== 'string') return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function urlExtension(url) {
  return url.split('.').pop().split(/\#|\?/)[0];
}

export function getMenuImage(image) {
  let allMenu = getMenuData()

  if (!allMenu) { return [] }
  allMenu = allMenu.menu
  let getUrlImage = "";

  allMenu.forEach(element => {
    element.menuOption.forEach(elem => {
      if (transformText(elem.text) === transformText(image)) {
        getUrlImage = elem.image
      }
    })
  });
  return getUrlImage
}

export function getTypeName(typeId) {

  switch (typeId) {
    case 1:
      return "Audio";
    case 2:
      return "Video";
    case 3:
      return "Post";
    case 4:
      return "Site";
    case 5:
      return "SinglePage";
    case 6:
      return "YoutubeLive";
    case 7:
      return "YTPlaylist";
    case 8:
      return "Meeting";
    case 15:
      return "Jornadinha";
    case 22:
        return "Jornada IMPACT";
    case "tags":
      return "Tags";
    case "zoom":
      return "Meeting";
    case "grupos":
      return "Grupos";
    case "gestao-de-cor-e-logo":
      return "GestaoCorELogo"
    default:
      return "-"
  }
}

export function getMenuRoutes(){
  const menuOptionList = JSON.parse(localStorage.getItem('menu-option'))

  if(menuOptionList && menuOptionList.length > 0){
      return menuOptionList
  }else{
      return []
  }
}

export function getDesignBySectionType(idType) {
  let menuData = JSON.parse(localStorage.getItem('menu-data'))

  // return empty object if theres no data 
  if (!menuData) { return {} }
  if (!menuData.design) { return {} }

  let section = menuData.design.filter(obj => {
      return obj.idSectionType === parseInt(idType)
  })[0]

  // return empty object if theres no data on section
  if (!section) { return {} }

  return section.format
}

export function getColors(type) {

  let design = getDesignBySectionType(1)
  if (design) {
      switch (type) {
          case 'image':
              if (design.backgroundImage) {
                  return design.backgroundImage
              } else {
                  return {
                      image: "https://blast.etc.br/common/app0000001/section/backGroudBlast.jpg",
                      showImage: true
                  }
              }
          case 'footer':
              if (design.footer) {
                  return design.footer
              } else {
                  return {
                      color: "#933939",
                      showfooter: false
                  }
              }

          case 'header':
              if (design.header) {
                  return design.header
              } else {
                  return {
                      color: "#861414"
                  }
              }

          case 'home':
              if (design.home) {
                  return design.home
              } else {
                  return { homeType: null }
              }

          case 'statusBar':
              if (design.statusBar) {
                return design.statusBar
              } else {
                return {
                  color: "#000"
                }
              }

          case 'menu':
              if (design.menu) {
                  return design.menu
              } else {
                  // return default bedigital colors in case none is set
                  return {
                      "firstColor": "#620606",
                      "fourthColor": "#f2f2f2",
                      "secondColor": "#861414",
                      "thirdColor": "#861414"
                  }
              }

              
          case 'menuItem':
              if (design.menuItem) {
                  return design.menuItem
              } else {
                  // return default bedigital colors in case none is set
                  return {
                  }
              }

          default:
              return {}
      }
  } else {
      return {}
  }
}

export function getAppType() {
  const appDetails = JSON.parse(localStorage.getItem('appDetails'))
  return appDetails?.idAppType
}

// return zoom authorize domain based on URL
export function getZoomUrl(){
  const appDetails = JSON.parse(localStorage.getItem('appDetails'))
  const domain = `https://${appDetails?.adminBucket}` 

  return `https://zoom.us/oauth/authorize?response_type=code&client_id=oSTRh9L0Sm6y0dkde3DEA&redirect_uri=${domain}/%23/configuracao/zoom_auth`
}

export function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getPermission(array, name){
  return Boolean(array.filter((menu) => menu.name === name).length)
}
