import React, { useState } from 'react';
import styles from './styles.module.scss';
import GenericDashboardCard from '../GenericDashboardCard';
import { topRanking } from "../../../../../services/NexiDigitalAPI";

const BestRankingAccess = ( { filter } ) => {

    const title = "Conteúdo com mais avaliações"
    const link = "?tabs=avaliacao&rel=0"

    const [loading, setLoading] = useState(false);
    const [dados, setDados] = useState([]);
    const [max, setMax] = useState(0);

    const actionFilter = async (values) => {
        setLoading(true);
        const data = await topRanking(values);
        data.sort( compare );
        const selectedData = data.slice(0, 5);
        //const selectedData = Boolean(data.length) ? [data[0],data[0],data[0],data[0],data[0]] : [];
        setMax( Boolean(selectedData.length) ? selectedData[0].quantityVotes : 0)

        setDados(selectedData);
        setLoading(false);
    }

    function compare( a, b ) {
        if ( a.quantityVotes > b.quantityVotes ){
          return -1;
        }
        if ( a.quantityVotes < b.quantityVotes ){
          return 1;
        }
        return 0;
    }

    const colorArray = [ '#642D8F', '#2D99FF', '#FF6C40', '#FFE700', '#2CD8C5']

    const hasData = Boolean(dados.length)
    const noData = (
        <div className={styles.nenhumDado}>
            Nenhum dado disponível
        </div>
    );

    const data = (
        <div>
            <div className={styles.dadosGrafico}>
                { !hasData ? noData : dados.map( (item, index) => {

                    const barSize = (item.quantityVotes / max) * 100;
                    const barStyle = {
                        backgroundColor: `${colorArray[index]}`,
                        height: `${barSize}%`
                    }

                    return(
                        <div className={styles.dadosColuna} key={index}>
                            <div className={styles.dadosNumero}> {item.quantityVotes} </div>
                            <div className={styles.dadosBarra} style={barStyle}>&nbsp;</div>
                        </div>
                    )
                })}
            </div>
            <div className={styles.legends}>
                {dados.map( (item, index) => {
                    const markerStyle = {
                        backgroundColor: `${colorArray[index]}`
                    }

                    return(
                        <div className={styles.legendaItem} key={index}>
                            <div className={styles.legendaMarker} style={markerStyle}>&nbsp;</div>
                            <div className={styles.legendaNome}> {item.contentName} </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )

    

    return (
        <GenericDashboardCard title={title} data={data} actionFilter={actionFilter} link={link} loading={loading} filter={filter} />
    );
};

export default BestRankingAccess;