import React, {useContext} from 'react';
import { AuthContext } from "../../context/AuthContext";

const CmsPost = ({content, setContent, referenceLink, setReferenceLink}) => {

    const { loginData } = useContext(AuthContext);
    const fourthColor = loginData?.fourthColor;
    const secondaryColor = loginData?.secondaryColor;

    return(
        <div className="form-group">
            <div className="card">
                <div
                    style={{ backgroundColor: fourthColor || secondaryColor }}
                    className="card-header template-card-header"
                >
                    Post
                </div>
                
                <div className="card-body template-card-body">
                    <div className="input-group mb-4">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">
                                Conteúdo
                            </span>
                        </div>
                        <textarea
                            type="text"
                            className="form-control"
                            id="content"
                            aria-describedby="content"
                            rows="5"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                        />
                    </div>
                    <div className="input-group mb-4">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">
                                Link Saiba Mais
                            </span>
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            id="referenceLink"
                            aria-describedby="referenceLink"
                            value={referenceLink}
                            onChange={(e) => setReferenceLink(e.target.value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CmsPost