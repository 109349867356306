import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
  useContext,
} from "react";

import "jodit";
import JoditEditorContent from "../../components/jodit/index";

import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _, { iteratee } from "underscore";
import ReactTags from "react-tag-autocomplete";
import axios from "axios";
import moment from "moment";
import {
  updateContent,
  getTags,
  uploadImageApi,
} from "../../services/NexiDigitalAPI";
import SaveIcon from "@material-ui/icons/Save";
import PublishIcon from "@material-ui/icons/Publish";
import SemImagem from "../../assets/images/sem-imagem.jpg";
import ErrorIcon from "../../assets/images/error.svg";
import SuccessIcon from "../../assets/images/success.svg";
import { Button, Modal, Form } from "react-bootstrap";
import AccessTable from "../AccessTable";
import { AuthContext } from "../../context/AuthContext";
import Tags from "../../components/Tags";

import Switch from "@material-ui/core/Switch";
import { DatePickerInput } from "rc-datepicker";

const MainMenu = (props) => {

  const { data, contentTags, permissionApproveContent } = props;
  // Lista de variáveis em ordem alfabética (para bater com a lista recebida do json)
  const [active, setActive] = useState(data.active ||  0);
  const [autor, setAutor] = useState(data.author || "");
  const [autorEmail, setAutorEmail] = useState(data.authorEmail || "");
  const [backConfirmation, setBackConfirmation] = useState(
    data.backConfirmation || ""
  );
  const [backgroundColor, setBackgroundColor] = useState(
    data.backgroundColor || ""
  );
  const [content, setContent] = useState(data.content || "");
  const [contentLocation, setContentLocation] = useState(
    data.contentLocation || ""
  );
  const [description, setDescription] = useState(data.description || "");
  const [endDate, setEndDate] = useState(
    data.endDate === null ? null : new Date(data.endDate)
  );
  const [idContentGroup, setIdContentGroup] = useState(data.idContentGroup);
  const [idContentItem, setIdContentItem] = useState(data.idContentItem);
  const [idContentType, setIdContentType] = useState(data.idContentType);
  const [idSection, setIdSection] = useState(data.idSection);
  const [imagemS3, setImagemS3] = useState(data.image || "");
  const [imageStyle, setImageStyle] = useState(data.imageStyle || "");
  const [initDate, setInitDate] = useState(
    data.initDate === null ? new Date() : new Date(data.initDate)
  );
  const [logoutConfirmation, setLogoutConfirmation] = useState(
    data.logoutConfirmation
  );
  const [referenceLink, setReferenceLink] = useState(data.referenceLink || "");
  const [showBottomMenu, setShowBottomMenu] = useState(data.showBottomMenu);
  const [showTopBar, setShowTopBar] = useState(data.showTopBar);
  const [titulo, setTitulo] = useState(data.text || "");
  const [tag, setTag] = useState("");
  const [imagemUploadStatus, setImagemUploadStatus] = useState(false);
  const [mensagemImagemUpload, setMensagemImagemUpload] = useState("");
  const [test, setTest] = useState("oi");
  const [videoLoading, setVideoLoading] = useState(false);
  const [videoSuccess, setVideoSuccess] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const [modal, setModal] = useState(true);

  const { loginData } = useContext(AuthContext);
  const fourthColor = loginData?.fourthColor;
  const secondaryColor = loginData?.secondaryColor;

  // variáveis de auxílio
  let tagsDeConteudo = [];
  for (let i = 0; i < contentTags.length; i++) {
    tagsDeConteudo.push({ id: i, name: contentTags[i].tag });
  }

  const [imagemOriginal, setImagemOriginal] = useState(data.image || "");
  const [isDisabled, setIsDisabled] = useState(false);
  const [carregaImagem, setCarregaImagem] = useState(false);
  const [carregaAudio, setCarregaAudio] = useState(false);
  const [temEndDate, setTemEndDate] = useState(endDate ? true : false);
  const [tags, setTags] = useState(tagsDeConteudo);
  const [lista, setLista] = useState([]);

  useEffect(() => {
    getTags()
      .then((res) => {
        const tagSearch = res.data.tagsearch;
        const tagsSorted = _.sortBy(tagSearch, "tag");
        let nova = [];

        for (let i = 0; i < tagsSorted.length; i++) {
          nova.push({ id: tagsSorted[i].idTag, name: tagsSorted[i].tag });
        }

        setLista(nova);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleChange(state) {
    setIsDisabled(state);
  }

  const uploadImagem = (arquivo) => {
    setCarregaImagem(true);
    let selectedFile = arquivo.files[0];
    const filename = "thumb" + idContentItem + "-" + Date.now();
    // let newfile = new File(
    //   [selectedFile],
    //   filename + selectedFile.type.replace("image/", ".")
    // );

    const data = new FormData(); // If file selected
    if (selectedFile) {
      data.append("fileName", filename);
      data.append("fileData", selectedFile);
      //data.append("fileData", newfile, newfile.name);
      uploadImageApi(data)
        .then((response) => {
          if (response.data.error) {
            setImagemS3(imagemOriginal);
            setCarregaImagem(false);
            setImagemUploadStatus(false);
            setMensagemImagemUpload("Erro ao fazer upload do arquivo");
          } else {
            setImagemS3(response.data.location);
            setCarregaImagem(false);
            setImagemUploadStatus(true);
            setMensagemImagemUpload("Imagem atualizada com successo");
          }
        })
        .catch((error) => {
          // If another error
          console.log("error", error);
          setImagemS3(arquivo.value);
          setCarregaImagem(false);
          setImagemUploadStatus(false);
          setMensagemImagemUpload("Erro ao fazer upload do arquivo");
        });
    }
  };

  const formatAppId = (id) => {
    const digitos = 7;
    let len = id.length;

    let zeros = "0".repeat(digitos - len);

    return `app${zeros}${id}`;
  };

  function checkUploading() {
    if (videoLoading && !videoSuccess) {
    }
  }

  const removePrefixoURL = (url) => {
    const app_id = loginData.app_id;
    const app_code = formatAppId(app_id);

    if (url.includes(app_code) === false) {
      return url;
    }

    let uri = url.split(app_code)[1];
    //remove a barra que sobrou no começo
    while (uri.substring(0, 1) === "/") {
      uri = uri.substring(1);
    }
    return uri;
  };

  const sendData = () => {
    const publishDate = moment().format("YYYY-MM-DD hh:mm:ss.SSS");
    const userId = loginData.userid; // Usuário logado
    const tagList = _.map(tags, function (t) {
      return t.name;
    }).join(";");

    let obj = {
      idContentItem: idContentItem,
      idContentGroup: idContentGroup,
      image: imagemS3,
      text: titulo,
      description: description,
      contentLocation: contentLocation,
      publishDate: publishDate,
      initDate: moment(initDate).format("YYYY-MM-DD hh:mm:ss.SSS"),
      endDate:
        endDate === null
          ? null
          : moment(endDate).format("YYYY-MM-DD hh:mm:ss.SSS"),
      content: content,
      idUserPublisher: parseInt(userId, 10),
      author: autor,
      authorEmail: autorEmail,
      active: parseInt(active),
      idContentType: idContentType,
      tags: tagList,
      backConfirmation: backConfirmation === 1 ? 1 : 0,
      backgroundColor: backgroundColor,
      idSection: idSection,
      imageStyle: imageStyle,
      logoutConfirmation: logoutConfirmation,
      referenceLink: referenceLink,
      showBottomMenu: showBottomMenu,
      showTopBar: showTopBar,
    };

    //if (window.confirm("Deseja salvar?")) {
    updateContent(obj)
      .then((res) => {
        if (res.data.success) {
          props.history.goBack();
        }
      })
      .catch((error) => {
        // If another error
        console.log("error", error);
      });
    //}
  };

  const usaDataFim = (op) => {
    setTemEndDate(op);

    if (op === false) {
      setEndDate(null);
    }
  };

  const onDelete = (i) => {
    tags.splice(i, 1);
  };

  const onAddition = (e) => {
    setTags([...tags, { id: e.id, name: e.name }]);
  };

  return (
    <div>
      <div className="header-title-wrapper">
        <div className="header-title">Single Page</div>
      </div>

      <div className="post-form">
        <form>
          <div className="form-group">
            <div className="card">
              <div
                style={{ backgroundColor: fourthColor || secondaryColor }}
                className="card-header template-card-header"
              >
                Título
              </div>
              <div className="card-body template-card-body">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      Título
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="titulo"
                    aria-describedby="titulo"
                    value={titulo}
                    onChange={(e) => setTitulo(e.target.value)}
                  />
                </div>
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      Descrição
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="description"
                    aria-describedby="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="card">
              <div
                style={{ backgroundColor: fourthColor || secondaryColor }}
                className="card-header template-card-header"
              >
                Conteúdo
              </div>
              <div className="card-body template-card-body">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    {videoLoading && (
                      <div className="video-loading">
                        {!(videoError || videoSuccess) && (
                          <div className="loader" style={{ marginRight: 10 }} />
                        )}
                        <div>
                          {videoSuccess ? (
                            <div>
                              <img
                                src={SuccessIcon}
                                style={{
                                  height: 20,
                                  width: 20,
                                  marginRight: 10,
                                }}
                              />
                              Video salvo com sucesso
                            </div>
                          ) : videoError ? (
                            <div>
                              <img
                                src={ErrorIcon}
                                style={{
                                  height: 20,
                                  width: 20,
                                  marginRight: 10,
                                }}
                              />
                              Erro ao salvar arquivo
                            </div>
                          ) : (
                            "Salvando video..."
                          )}
                        </div>
                      </div>
                    )}
                    <JoditEditorContent
                      content={content}
                      onChange={(e) => setContent(e)}
                      loading={(e) => setCarregaImagem(e)}
                      idContentItem={idContentItem}
                    ></JoditEditorContent>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="card">
              <div
                style={{ backgroundColor: fourthColor || secondaryColor }}
                className="card-header template-card-header"
              >
                Tags
              </div>
              <div className="card-body template-card-body">
                <div className="row">
                  <div className="col-md-12">
                    <Tags
                      handleNewTags={(newTags) => setTags(newTags)}
                      lista={lista}
                      contentTags={contentTags}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="card">
              <div
                style={{ backgroundColor: fourthColor || secondaryColor }}
                className="card-header template-card-header"
              >
                Imagem
              </div>
              <div className="card-body template-card-body">
                <div className="row">
                  <div className="col-md-6">
                    <input
                      type="file"
                      className="form-control-file"
                      id="imagem"
                      accept="image/png, image/jpeg, image/gif"
                      onChange={(e) => uploadImagem(e.target)}
                    />
                  </div>
                  {carregaImagem ? (
                    <div className="col-md-6">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="col-md-6">
                      <img
                        src={imagemS3 || SemImagem}
                        alt="Imagem"
                        className="img-fluid img-thumbnail image-effect"
                        style={{ height: 130 }}
                      />
                      <br />
                      <br />
                      {imagemUploadStatus ? (
                        <span style={{ color: "green" }}>
                          {mensagemImagemUpload}
                        </span>
                      ) : (
                        <span style={{ color: "red" }}>
                          {mensagemImagemUpload}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="card">
              <div
                style={{ backgroundColor: fourthColor || secondaryColor }}
                className="card-header template-card-header"
              >
                Autor
              </div>
              <div className="card-body template-card-body">
                <div className="form-row">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="autor">
                        Nome
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={autor}
                      onChange={(e) => setAutor(e.target.value)}
                    />
                  </div>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="autor">
                        E-mail
                      </span>
                    </div>
                    <input
                      type="email"
                      className="form-control"
                      value={autorEmail}
                      onChange={(e) => setAutorEmail(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="card overflow-visible">
              <div
                style={{ backgroundColor: fourthColor || secondaryColor }}
                className="card-header template-card-header"
              >
                Publicação
              </div>
              <div className="card-body template-card-body">
                <div className="form-row">
                  {permissionApproveContent && (
                    <div className="col-md-3">
                      <Form.Group className="access-table-select">
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                          as="select"
                          value={active}
                          onChange={(e) => setActive(e.target.value)}
                        >
                          <option value={0}>Avaliação pendente</option>
                          <option value={1}>Aprovado</option>
                          <option value={2}>Cancelado</option>
                        </Form.Control>
                      </Form.Group>
                    </div>
                  )}
                  <div className="col-md-3">
                    <Form.Group className="access-table-select">
                      <Form.Label>Data de publicação</Form.Label>
                      <DatePickerInput
                        name="initDate"
                        locale="pt-br"
                        onChange={(date) => setInitDate(date)}
                        value={initDate}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <div className="access-table-select form-group">
                      <label
                        className="form-label"
                        htmlFor="tira-do-ar"
                        style={{ margin: "-3 0" }}
                      >
                        Agendar término publicação?
                      </label>
                      <br />
                      <Switch
                        className="form-group"
                        id="tira-do-ar"
                        color="primary"
                        checked={temEndDate}
                        onChange={(e) => usaDataFim(e.target.checked)}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </div>
                  </div>
                  {temEndDate ? (
                    <div className="col-md-3">
                      <Form.Group className="access-table-select">
                        <Form.Label>Data de retirada do conteúdo</Form.Label>
                        <DatePickerInput
                          name="endDate"
                          placeholderText="Sem prazo"
                          locale="pt-br"
                          onChange={(date) => setEndDate(date)}
                          value={endDate}
                        />
                      </Form.Group>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div
              style={{ backgroundColor: fourthColor || secondaryColor }}
              className="card-header template-card-header"
            >
              Permissões de acesso ao conteúdo
            </div>
            <div className="card-body template-card-body">
              <AccessTable id={data.idContentItem} />
            </div>
          </div>
          <br />
          <div className="row" style={{ padding: 5 }}>
            <div className="col-md-8"></div>
            <div className="col-md-4">
              <button
                type="button"
                disabled={videoLoading && !(videoSuccess || videoError)}
                onClick={sendData}
                className="btn btn-primary"
              >
                <SaveIcon /> Salvar
              </button>
            </div>
          </div>
        </form>
        <br />
      </div>
    </div>
  );
};

export default withRouter(MainMenu);
