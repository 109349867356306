import React from 'react';
import styles from './styles.module.scss'

const ReportWrapper = ({ children }) => {
    return (
        <div className={styles.reportWrapper}>
            { children }
        </div>
    )
}

export default ReportWrapper;