import React, { useState, useEffect } from "react";
import { getSectionDesignList, saveDesignParameter } from "../../services/NexiDigitalAPI";
import Loading from "../../components/Loading";
import ErrorIcon from '@material-ui/icons/Error';
import TextField from '@material-ui/core/TextField';
import { CircularProgress, Switch } from '@material-ui/core';
import { useHistory } from 'react-router-dom'
import { ArrowBack, Check, Error } from "@material-ui/icons";

const SectionDesign = ({
  match: {
    params: { sectionId },
  },
}) => {
  const [designList, setDesignList] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory()

  const transformIntoGroups = (data) => {
    return data.reduce(function (r, a) {
      r[a.parameterGroup] = r[a.parameterGroup] || [];
      r[a.parameterGroup].push(a);
      return r;
    }, Object.create(null));
  };
  const _getSectionDesignList = () => {
    setLoading(true);
    setError(false);
    getSectionDesignList(Number(sectionId))
      .then((res) => {
        if (res.data.success === true) {
          setDesignList(transformIntoGroups(res.data.sectionDesign));
        } else {
          console.log("Error", res);
          setError(true)
        }
      })
      .catch((err) => {
        console.log('err', err)
        setError(true)
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const transformCamelCase = (text) => {
    const textWithSpace = text.replace(/([A-Z])/g, " $1");
    return textWithSpace.charAt(0).toUpperCase() + textWithSpace.slice(1);
  }

  const isBooleanTypeWithString = (value) => {
    return (
      value == 'true' || 
      value == true || 
      value == 'false' ||
      value == false
    )
  }

  const _saveDesignParameter = (bodyParams, groupData, groupIndex) => {
    let newDesignList = {...designList}
    newDesignList[groupData][groupIndex].loadingStatus = "loading"
    setDesignList(newDesignList)
    saveDesignParameter(bodyParams).then((res) => {
      if (res.data.success === true) {
        newDesignList[groupData][groupIndex].loadingStatus = "success"
        setDesignList({...newDesignList})
        // setSuccessMessage("Dados salvo com sucesso!");
      } else {
        newDesignList[groupData][groupIndex].loadingStatus = "error"
        setDesignList({...newDesignList})
        console.log("Erro ao gravar dados!");
      }
    })
    .catch((err) => {
      // setSaving(false);
      newDesignList[groupData][groupIndex].loadingStatus = "error"
      setDesignList({...newDesignList})
      console.log('err', err)
    });
  }

  const writeInput = (
      { target: { value } },
      groupData, 
      groupIndex
    ) => {
    let newDesignList = {...designList}
    newDesignList[groupData][groupIndex].value = value

    setDesignList(newDesignList)
  }

  const saveInput = (
      { target: { value } },
      groupData, 
      groupIndex,
      itemParameter
    ) => {

    const saveParams = {
      "idSection": itemParameter.idSection,
      "parameterGroup": itemParameter.parameterGroup,
      "parameter": itemParameter.parameter,
      "value": value
    }
    _saveDesignParameter(saveParams, groupData, groupIndex)
  }

  const saveSwitch = (
      { target: { checked } },
      groupData, 
      groupIndex,
      itemParameter
    ) => {
    let newDesignList = {...designList}
    newDesignList[groupData][groupIndex].value = checked ? "true" : "false"

    setDesignList(newDesignList)

    const saveParams = {
      "idSection": itemParameter.idSection,
      "parameterGroup": itemParameter.parameterGroup,
      "parameter": itemParameter.parameter,
      "value": checked ? "true" : "false"
    }
    _saveDesignParameter(saveParams, groupData, groupIndex)
  }

  const getLoadingStatus = (status = null) => {
    switch(status) {
      case "loading":
        return (
          <div className="design-manager-loading-status">
            <CircularProgress style={{ height: 20, width: 20 }}/>
          </div>
        )
      case "success":
        return (
          <div className="design-manager-loading-status">
            <Check style={{ height: 20, width: 20, color: 'green' }}/>
          </div>
        )
      case "error":
        return (
          <div className="design-manager-loading-status">
            <Error style={{ height: 20, width: 20, color: 'red' }}/>
          </div>
        )
      default: break
    }
  }

  useEffect(() => {
    _getSectionDesignList();
  }, []);

  return (
    <div>
      <div>
        <div style={{ marginBottom: "80px" }} className="header-title-wrapper">
          <div className="header-title">
            Design de seção 
            <span style={{ fontSize: 16, color: 'gray', marginLeft: 10 }}>(Section ID {sectionId})</span>
          </div>
        </div>
        <div 
          className="user-edit-container" 
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <div style={{ width: '100%', marginBottom: 10 }}>
            <div 
              onClick={() => history.push(`/layout-manager`)}
              className="design-manager-back-container"
            >
              <ArrowBack style={{ marginRight: 5 }}/>
              <span >Voltar para o gerenciador de interface</span>
            </div>
          </div>
          {loading ? (
            <Loading>
              Carregando seção...
            </Loading>
          ) : (
            !error ? ( 
            <>
              {Object.keys(designList).length > 0 &&
                Object.keys(designList).sort().map((groupParameter) => {
                  return (
                    <div className="design-manager-group-container">
                      <div className="design-manager-group-title">
                        {transformCamelCase(groupParameter)}
                      </div>
                      {designList[groupParameter].map((itemParameter, index) => {
                        return isBooleanTypeWithString(itemParameter.value) ? (
                          <div style={{ position: 'relative' }}>
                            {
                              getLoadingStatus(itemParameter.loadingStatus)
                            }
                            <Switch
                              checked={itemParameter.value === "true"}
                              onChange={(input) => saveSwitch(input, groupParameter, index, itemParameter)}
                              name={index}
                              color="primary"
                            />
                            <span>{transformCamelCase(itemParameter.parameter)}</span>
                          </div>
                        ) : (
                          <div style={{ position: 'relative' }}>
                              {
                                getLoadingStatus(itemParameter.loadingStatus)
                              }
                            <TextField
                              style={{ backgroundColor: 'white', borderRadius: 5 }}
                              id={index}
                              label={transformCamelCase(itemParameter.parameter)}
                              variant="outlined" 
                              value={itemParameter.value}
                              onBlur={(input) => saveInput(input, groupParameter, index, itemParameter)}
                              onKeyDown={input => { if (input.key === 'Enter') { input.target.blur() } }}
                              onChange={(input) => writeInput(input, groupParameter, index, itemParameter)}
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
            </> 
            ) : (
              <div className="design-manager-error-container">
                <ErrorIcon style={{ marginBottom: 15, fontSize: 50}} />
                <span>Erro ao consultar seção</span>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionDesign;
