import React, { useState, useEffect } from "react";
import {
    Col,
    Form
} from "react-bootstrap";
import {
    FaFilter,
} from "react-icons/fa";
import moment from "moment";
import "moment/locale/pt-br.js";
import { DatePickerInput } from "rc-datepicker";
import FiltroWrapper from '../../../Components/FiltroWrapper';
import styles from './styles.module.scss';
import ButtonWithLoading from "../../../../../components/ButtonWithLoading";

const Filtro = ({ actionFilter, loading }) => {
    const [values, setValues] = useState({
        startDate: moment(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD")
    })

    function changeDateStart (jsDate){
        setValues({
            ...values,
            startDate: moment(jsDate).format("YYYY-MM-DD")
        })
    }

    function changeDateFinish (jsDate){
        setValues({
            ...values,
            endDate: moment(jsDate).format("YYYY-MM-DD")
        })
    }

    const submitFilter = () => {
        actionFilter(values)
    }

    useEffect(() => {
        submitFilter();
    }, []);

    return (
        <FiltroWrapper>
            <Form style={{ marginTop: 30, marginBottom: 30 }}>
                <Form.Row className="align-items-end">
                    <Col xs="12" md="4" lg="2" className={styles.formSpace}>
                    <Form.Label htmlFor="dateStart">Data de Início</Form.Label>
                    <DatePickerInput
                        name="dateStart"
                        locale="pt-br"
                        onChange={changeDateStart}
                        value={values.startDate}
                    />
                    </Col>
                    <Col xs="12" md="4" lg="2" className={styles.formSpace}>
                    <Form.Label htmlFor="dateStart">Data de Término</Form.Label>
                    <DatePickerInput
                        name="dateFinish"
                        locale="pt-br"
                        onChange={changeDateFinish}
                        value={values.endDate}
                    />
                    </Col>
                    <Col xs="12" md="4" lg="2" className={styles.formSpace}>
                    <Form.Group className="mb-0">
                        <ButtonWithLoading 
                            onClickHandler={submitFilter}
                            loading={loading}
                            text={( <>Aplicar filtro <FaFilter style={{ marginLeft: 5 }} /> </> )}
                        />
                    </Form.Group>
                    </Col>
                </Form.Row>
            </Form>
        </FiltroWrapper>
    );
}

export default Filtro;