import React, { useState, useContext } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import "./Registro.css";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { getAppType } from "../utils/Utils"
import { AuthContext } from "../context/AuthContext";
import { CircularProgress } from "@material-ui/core";

const api_url = process.env.REACT_APP_BACKEND_API;

export default function Registro() {
    const history = useHistory();
    
    const [company, setCompany] = useState("");
    const [cnpj, setCnpj] = useState("");
    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [cpf, setCpf] = useState("");
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Erro no envio do registro");
    const fone = localStorage.getItem("fone")
    const pin = localStorage.getItem("pin")
    const aceitaTermos = getAcceptedTerms();
    const communicationAccepted = getCommunicationValue()

    const { loginData } = useContext(AuthContext)
    const primaryColor = loginData?.primaryColor

    function validateForm() {
        const emailValido = email.length > 0;

        return emailValido ;
    }

    async function handleSubmit(event) {
        event.preventDefault();

        try {
            setError(false)
            if (validateForm()) {
              setLoading(true)
                axios
                    .post(`${api_url}/contract/activate`, {
                        companyName: company,
                        cnpj,
                        userName: nome,
                        email,
                        cpf,
                        phone: fone.replace(/\D/g,''),
                        communicationAccepted: communicationAccepted,
                        userLicenceTermsAccepted: aceitaTermos,
                        pin,
                        idChannel: 2,
                        idAppType: getAppType(),
                    }, { "Content-Type": "application/json"})
                    .then(({data}) => {
                        if (data.success) {
                            history.push("/obrigado");
                        } else {
                          setErrorMessage(data.message)
                          setError(true)
                        }
                    })
                    .catch((e) => console.log("Erro ao gravar os dados de registro", e))
                    .finally(() => setLoading(false))
            } else {
                console.log("falha ao enviar o registro")
                setError(true)
            }
            
        } catch (e) {
            alert(e.message);
        }
    }

    function getCommunicationValue(){
        let value = localStorage.getItem("aceitaComunica")
        if(value == true || value == 'true'){
            return 1
        }else{
            return 0
        }
    }

    function getAcceptedTerms(){
        let value =  localStorage.getItem("aceitaTermo")
        if(value == true || value == 'true'){
            return 1
        }else{
            return 0
        }
    }


    return (
        <div className="Registro">
            <Form className="registro-container" onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicCompany">
                    <Form.Label>Empresa</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Digite o nome da Empresa"
                        value={company}
                        onChange={e => setCompany(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId="formBasicCnpj">
                    <Form.Label>CNPJ</Form.Label>
                    <CpfCnpj
                        className="registro-input"
                        placeholder="Digite o CNPJ"
                        value={cnpj}
                        onChange={e => setCnpj(e.target.value)}
                    />
                    <Form.Text className="text-muted">
                        Digite apenas os números
                    </Form.Text>
                </Form.Group>

                <Form.Group controlId="formBasicName">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Digite o seu nome"
                        value={nome}
                        onChange={e => setNome(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control 
                        type="email" 
                        placeholder="Digite o seu email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </Form.Group>

                <Form.Group controlId="formBasicCpf">
                    <Form.Label>CPF</Form.Label>
                    <CpfCnpj
                        maxLength={14}
                        className="registro-input"
                        placeholder="Digite o seu CPF"
                        value={cpf}
                        onChange={e => setCpf(e.target.value)}
                    />
                    <Form.Text className="text-muted">
                        Digite apenas os números
                    </Form.Text>
                </Form.Group>

                <Button style={{ background:  primaryColor, borderColor: primaryColor}} variant="primary" disabled={!validateForm()} type="submit">
                    Efetuar o Registro
                    {
                      loading &&
                      <CircularProgress 
                        style={{
                          height: 20,
                          width: 20,
                          marginLeft: 10,
                          marginBottom: -4,
                          color: 'white',
                        }}
                      />
                    }
                </Button>

            </Form>
            {
              error && <Card style={{ marginTop: 15, padding: '0px 5px' }} bg='warning' text='dark'> {errorMessage}</Card>
            }
        </div>
    );
}