import React, { useState, useEffect } from "react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./styles.scss";

import { useParams } from "react-router-dom";

import ReportResumo from "./ReportResumo";
import ReportUser from "./ReportUser";
import ReportRanking from "./ReportRanking";
import ReportChat from "./ReportChat";
import ReportUploads from "./ReportUploads";
import ReportsContent from "./ReportsContent";
import ReportDayWeek from "./ReportDayWeek";
import ReportContentIntent from "./ReportContentIntent";
import ReportsGamification from "./ReportsGamification";
import { getMenuRoutes, getParameterByName, getPermission } from "../../utils/Utils";

export default function Reports() {
  const initialRel = {
    'conteudo': false,
    'usuario': false,
    'avaliacao': false,
    'chat': false,
    'uploads': false,
    'acessoporsemana': false,
    'interacaodeconteudo': false,
    'gamification': false,
  }

  const [chooseTab, setChooseTab] = useState(0)
  const [chooseRel, setChooseRel] = useState(initialRel)

  const getTab = (text) => {
    switch(text){
      case 'conteudo': return 1;
      case 'usuario': return 2;
      case 'avaliacao': return 3;
      case 'chat': return 4;
      case 'uploads': return 5;
      case 'acessoporsemana': return 6; 
      case 'interacaodeconteudo': return 7;
      case 'gamification': return 8;
      default: return 0;
    }
  }

  /* [Permission Module] - Permissions states */
  const [permissionDashboard, setPermissionDashboard] = useState(false);
  const [permissionTabContent, setPermissionTabContent] = useState(false);
  const [permissionTabUser, setPermissionTabUser] = useState(false);
  const [permissionTabRating, setPermissionTabRating] = useState(false);
  const [permissionTabChat, setPermissionTabChat] = useState(false);
  const [permissionTabUploads, setPermissionTabUploads] = useState(false);
  const [permissionGamification, setPermissionGamification] = useState(false);
  const [permissionTabAccessPerWeek, setPermissionTabAccessPerWeek] =  useState(false);
  const [permissionTabContentInteract, setPermissionTabContentInteract] = useState(false);

  /* [Permission Module] - Menu Options */
  async function getMenuOption() {
    const menuOptionList = getMenuRoutes();

    const objCurrentMenu = menuOptionList.filter(
      (menu) => menu.text === "Relatórios"
    );

    if (Boolean(objCurrentMenu.length)) {
      const resources = objCurrentMenu.map((menu, index) => menu.resource);

      getPermissionsPage(resources);
    }
  }

  /* [Permission Module] - Permission attrs in page by login data information */
  function getPermissionsPage(resources) {
    try {
        setPermissionDashboard( getPermission(resources[0], "Dashboard") );
        setPermissionTabContent( getPermission(resources[0], "Relatórios de Conteúdo") );
        setPermissionTabUser( getPermission(resources[0], "Relatórios de Usuário") );
        setPermissionTabRating( getPermission(resources[0], "Relatórios de Avaliação") );
        setPermissionTabChat( getPermission(resources[0], "Relatórios de Chat") );
        setPermissionTabUploads( getPermission(resources[0], "Relatórios de Upload") );
        setPermissionTabAccessPerWeek( getPermission(resources[0], "ABA ACESSOS POR SEMANA") );
        setPermissionGamification( getPermission(resources[0], "Gamificação") );
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    /* [Permission Module] - Function calls */
    getMenuOption();
    const tabs = getParameterByName('tabs');
    const rel = getParameterByName('rel');
    setChooseTab(getTab( tabs ));
    setChooseRel({
      'conteudo': (tabs === 'conteudo') ? rel : false,
      'usuario': (tabs === 'usuario') ? rel : false,
      'avaliacao': (tabs === 'avaliacao') ? rel : false,
      'chat': (tabs === 'chat') ? rel : false,
      'uploads': (tabs === 'uploads') ? rel : false,
      'acessoporsemana': (tabs === 'acessoporsemana') ? rel : false,
      'interacaodeconteudo': (tabs === 'interacaodeconteudo') ? rel : false,
      'gamification': (tabs === 'gamification') ? rel : false,
    })
  }, []);

  return (
    <div className="Reports">
      <div className="header-title-wrapper">
        <div className="header-title">Relatórios</div>
      </div>

      <Tabs selectedIndex={chooseTab} onSelect={(tabIndex) => { setChooseTab(tabIndex); setChooseRel(initialRel)} }>
        <TabList>
          {permissionDashboard && <Tab>Dashboard</Tab>}
          {permissionTabContent && <Tab>Conteúdo</Tab>}
          {permissionTabUser && <Tab>Usuário</Tab>}
          {permissionTabRating && <Tab>Avaliação</Tab>}
          {permissionTabChat && <Tab>Chat</Tab>}
          {permissionTabUploads && <Tab>Uploads</Tab>}
          {permissionGamification && <Tab>Gamificação</Tab>}
        </TabList>

        {permissionDashboard && (
          <TabPanel>
            <ReportResumo />
          </TabPanel>
        )}
        {permissionTabContent && (
          <TabPanel>
            <ReportsContent rel={chooseRel['conteudo']} />
          </TabPanel>
        )}
        {permissionTabUser && (
          <TabPanel>
            <ReportUser rel={chooseRel['usuario']} />
          </TabPanel>
        )}
        {permissionTabRating && (
          <TabPanel>
            <ReportRanking rel={chooseRel['avaliacao']} />
          </TabPanel>
        )}
        {permissionTabChat && (
          <TabPanel>
            <ReportChat rel={chooseRel['chat']} />
          </TabPanel>
        )}
        {permissionTabUploads && (
          <TabPanel>
            <ReportUploads rel={chooseRel['uploads']} />
          </TabPanel>
        )}
        {permissionGamification && (
          <TabPanel>
            <ReportsGamification rel={chooseRel['gamification']} />
          </TabPanel>
        )}
      </Tabs>
    </div>
  );
}
