// Basic
import React, { useContext, useState, useEffect } from "react";

// UI and icons
import "./../Cockpit.css";
import { Link, Redirect } from 'react-router-dom'
import { AuthContext } from "../../context/AuthContext";
import { contentGroups, getProfiles, addProfileApi } from "../../services/NexiDigitalAPI";
import PersonIcon from '@material-ui/icons/Person';
import { FiUserPlus } from 'react-icons/fi';
import Button from '@material-ui/core/Button';
import { Col} from "react-bootstrap";

export default function Cockpit() {
  const { loginData } = useContext(AuthContext);
  const primaryColor = loginData?.primaryColor;
  const [redirect, setRedirect] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [profileName, setProfileName] = useState("");

  async function lookingForProfiles() {
    const profilesApi = await getProfiles();
    setProfiles(profilesApi);
  }

  function handleChangeProfileName(e){
    const name = e.target.value;
    if(name && name !== undefined && name != null && name !== "") setProfileName(e.target.value);
    else setProfileName("");
  }

  const addProfileForm = async () =>{
    if(profileName && profileName !== undefined && profileName != null && profileName !== "") {
      const result = await addProfileApi(profileName);

      if (result) {
        setProfileName("");
        lookingForProfiles();
      }
    }
  }

  useEffect(() => {

    lookingForProfiles();

    contentGroups().then(res => {
      let array = res.data.contentGroups;
      localStorage.setItem("contentGroups", JSON.stringify(array))
    }).catch(err => {
      console.log(err)
    });
  }, [])

  if (redirect) {
    return <Redirect to='/login' />;
  }

  return (
    <div className="Cockpit">
      <div className="header-title-wrapper">
        <div className="header-title"> Perfis e Recursos </div>
      </div>
      <hr />
      Cadastrar <br /><br />
      <div>
        <form className="formAddProfile" noValidate autoComplete="off">
          <div className="form-row">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                  <span className="input-group-text" id="labelProfiileName">
                    Nome
                  </span>
              </div>
              <Col xs={8} sm={6} style={{paddingLeft:0}}>
              <input
                type="text"
                className="form-control"
                value={profileName}
                onChange={(e) => handleChangeProfileName(e)}
              />
              </Col>
              <Col xs={12}  sm={4} style={{paddingLeft:0}}>

              <Button onClick={addProfileForm} className="blast-button" style={{  backgroundColor: primaryColor, marginRight: '10px', color: '#FFF', marginTop: '3px',  }}>
                <FiUserPlus color="white" size={22} style={{ marginRight: 5 }} />
                Adicionar Perfil
              </Button>
              </Col>
            </div>
          </div>
        </form>
      </div>

      <hr />

      <div className="cockpit-cards-container" >
        {
          profiles.map((item, index) => {
            return (
              <Link to={`/updateprofile/${item.idProfile}`} className="cockpit-card" key={index}>
                <div className="cockpit-card-header" style={{ backgroundColor: primaryColor }}>
                  <PersonIcon style={{ color: 'white', fontSize: 50 }} />
                </div>
                <div className="cockpit-card-content">
                  {item.name}
                </div>
              </Link>
            )
          })
        }
      </div>
    </div>
  );
}
