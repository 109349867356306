import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/pt-br.js";
import Filtro from './Filtro';
import Table from './Table';
import ReportAcordeon from '../../../Components/ReportAcordeon'
import { 
    getReportUserActivity
} from "../../../../../services/NexiDigitalAPI";


const Activity = ({ index, expanded }) => {
    const name = "Histórico do usuário"
    const description = "Detalhes sobre as atividades do usuário.";

    const [loading, setLoading] = useState(false);
    const [dados, setDados] = useState([]);

    const actionFilter = async (values) => {

        setLoading(true);
        // Sessions
        const responseData = await getReportUserActivity(values)
        const convertedData = responseData.map((response) => {return {
            ...response,
            timeStart: moment(new Date(response.timeStart)).format("DD/MM/YYYY HH:mm") 
        }});
        setDados(convertedData);
   
        setLoading(false);
    }

    const filter = (
        <Filtro 
            actionFilter={actionFilter} 
            loading={loading} 
        />
    );

    const result = (
        <Table 
            dados={ dados } 
            loading={ loading }
        />
    );
    
    return (
        <ReportAcordeon
            name={name}
            description={description}
            filter={filter}
            result={result}
            index={ index }
            expanded={expanded}
        />
        
    )
}

export default Activity;