import React, { useState, useEffect, useCallback } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { RiSave3Line } from "react-icons/ri";
import { BiArrowBack } from "react-icons/bi";
import { MdEdit } from "react-icons/md";
import { getChannel, getCompanyByChannel, updateCompany, getChannelCampaign } from "../../services/NexiDigitalAPI";
import queryString from "query-string";
import MaskedFormControl from "react-bootstrap-maskedinput";
import { useHistory } from "react-router-dom";
import { DatePickerInput } from 'rc-datepicker';
import MaskInput from "react-maskinput";
import moment from 'moment'
import { cpf as cpfValidator, cnpj as cnpjValidator } from "cpf-cnpj-validator";

export default function EditCompany(props) {
  const history = useHistory();
  const goBack = useCallback(() => history.push("/company-manager"), [history]);
  const [error, setError] = useState("");
  const [errorSave, setErrorSave] = useState("");
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [validCpf, setValidCpf] = useState(true);
  const [validCnpj, setValidCnpj] = useState(true);
  const [campaignList, setCampaignList] = useState([]);
  const [channels, setChannels] = useState([{ idChannel: 0, nome: 'Carregando...' }]);
  const [companyData, setCompanyData] = useState({
    idCompany: 1,
    responsibleEmail: "",
    cnpj: "",
    name: "",
    numberOfLicences: 0,
    responsibleName: "",
    responsiblePhone: "",
    trialPeriod: 0,
    responsibleCPF: "",
    originCode: "",
    campaign: "",
    companyLicenceTermsAccepted: 0,
    idChannel: 0,
    idChannelCampaign: "",
  });

  function _getChannel(){
    getChannel().then(res => {
        if(res.data.success == true){
          // setCompanyData({...companyData, idChannel: res.data.channel[0].idChannel})
            setChannels(res.data.channel)
        }
    })
  }

  function _getChannelCampaign(){
    getChannelCampaign().then(res => {
        if(res.data.success == true){
          // setCompanyData({...companyData, idChannel: res.data.channel[0].idChannel})
            setCampaignList(res.data.campaign)
        }
    })
  }

  function handleValidateDate(jsDate) {
    let date = moment(jsDate).format('YYYY-MM-DD hh:mm:ss')
    setCompanyData({ ...companyData, lastValidationCheck: date })
  }

  // função para receber os dados da empresa
  function getCompanyData(id, idChannel) {
    getCompanyByChannel(idChannel)
      .then((res) => {
        _getChannel()
        _getChannelCampaign()
        if (res.data.success == true) {
          const filteredCompany = res.data.companies?.filter(item => item.idCompany === parseInt(id))?.[0] || null
          if(filteredCompany === null){
            setError("Erro ao carregar dados da empresa")
            return
          }
          setLoading(false);
          setCompanyData(filteredCompany);
        } else {
          setError("Erro ao carregar dados da empresa");
        }
      })
      .catch((err) => {
        setError("Erro ao carregar dados da empresa");
      });
  }

  const getCompanyDataCallBack = useCallback(() => {
    let search = queryString.parse(props.location.search);
    if (search.id && search.idChannel) {
      getCompanyData(search.id, search.idChannel);
    }
  }, [props.location.search]);

  useEffect(() => {
    getCompanyDataCallBack();
    window.scrollTo(0, 0);
  }, [getCompanyDataCallBack]);

  function saveData(event) {
    event.preventDefault();
    setValidCnpj(true)
    setValidCpf(true)    
    
    if(!cnpjValidator.isValid(companyData.cnpj)) {
      setValidCnpj(false)
      return
    }

    if(companyData.responsibleCPF && !cpfValidator.isValid(companyData.responsibleCPF)) {
      setValidCpf(false)
      return
    }

    const isPhoneValid =
      /^\([1-9]{2}\) (?:[2-8]|[1-9][1-9])[0-9]{3}\-[0-9]{4}$/.test(
        companyData.responsiblePhone
      );
    if (!isPhoneValid) {
      setInvalidPhone(true);
      return;
    }

    // resetar o status de erros
    setSuccessMessage("");
    setErrorSave("");
    setInvalidPhone(false);

    let data = Object.assign({});
    const companyId = companyData.idCompany;

    // adicionar campos necessarios para a chamada
    data = { ...companyData };

    let newCompanyData = {
      idCompany: companyData.idCompany,
      responsibleEmail: companyData.responsibleEmail,
      cnpj: companyData.cnpj,
      name: companyData.name,
      responsibleName: companyData.responsibleName,
      responsiblePhone: companyData.responsiblePhone,
      idChannel: companyData.idChannel,
      agreementId: companyData.agreementId,
      trialPeriod: companyData.trialPeriod,
      originCode: companyData.originCode,
      idChannelCampaign: companyData.idChannelCampaign,
      cpf: companyData.responsibleCPF,
      companyLicenceTermsAccepted: companyData.companyLicenceTermsAccepted,
      numberOfLicences: companyData.numberOfLicences,
    }

    // salva os dados da empresa
    setSaving(true);
    updateCompany(newCompanyData)
      .then((res) => {
        setSaving(false);
        if (res.data.success == true) {
          setSuccessMessage("Dados salvo com sucesso!");
        } else {
          setErrorSave("Erro ao gravar dados!");
        }
      })
      .catch((err) => {
        setSaving(false);
        setErrorSave("Erro ao gravar dados!");
      });
  }

  
  function setCampaignValues(selectedIdChannelCampaign){
    const filteredCampaign = campaignList.find( campaignItem => campaignItem.idChannelCampaign === selectedIdChannelCampaign )
    setCompanyData({ 
      ...companyData,
      idChannelCampaign: filteredCampaign.idChannelCampaign,
      trialPeriod: filteredCampaign.trialDays,
      numberOfLicences: filteredCampaign.licenceNumber,
    })
  }

  return (
    <>
      <div className="not-header-title" />
      <div className="user-edit-container" style={{marginTop:"8%"}}>
        {loading && (
          <div className="user-edit-loading">
            {error.length > 0 ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {error}
                <Button onClick={goBack} variant="secondary">
                  <div>
                    <BiArrowBack
                      size={22}
                      style={{ marginBottom: 3, marginRight: 10 }}
                    />
                    Voltar
                  </div>
                </Button>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  className="loader loader-big"
                  style={{ marginBottom: 15 }}
                />
                Carregando dados da empresa...
              </div>
            )}
          </div>
        )}
        <Form onSubmit={(event) => saveData(event)}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              fontSize: 22,
              marginBottom: 25,
            }}
          >
            <MdEdit style={{ marginTop: 4, marginRight: 10 }} />
            Edição de empresa
          </div>
          <div className="user-edit-inside">
            <div className="user-edit-inside-part">
              <Form.Group>
                <Row>
                  <Col lg="12" md="12" xs="12" sm="12">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      required
                      value={companyData?.name}
                      type="text"
                      placeholder="Digite um nome"
                      onChange={(e) =>
                        setCompanyData({ ...companyData, name: e.target.value })
                      }
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group>
                <Row>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>Nome do administrador</Form.Label>
                    <Form.Control
                      required
                      value={companyData.responsibleName}
                      type="text"
                      placeholder="Digite um nome"
                      onChange={(e) =>
                        setCompanyData({ ...companyData, responsibleName: e.target.value })
                      }
                    />
                  </Col>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>Email do administrador</Form.Label>
                    <Form.Control
                      required
                      value={companyData.responsibleEmail}
                      type="email"
                      placeholder="Digite um email"
                      onChange={(e) =>
                        setCompanyData({ ...companyData, responsibleEmail: e.target.value })
                      }
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group>
                <Row>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>Telefone</Form.Label>
                    <MaskedFormControl
                      required
                      value={companyData.responsiblePhone}
                      mask="(11) 11111-1111"
                      type="tel"
                      placeholder="Digite um Telefone"
                      onChange={(e) =>
                        setCompanyData({ ...companyData, responsiblePhone: e.target.value })
                      }
                    />
                  </Col>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Group className="company-dropdown-label-edit">
                      <Form.Label>Canal</Form.Label>
                      <Form.Control 
                        value={companyData.idChannel} 
                        onChange={(e) => setCompanyData({ ...companyData, idChannel: e.target.value }) } 
                        as="select"
                      >
                        {
                            channels.map((item, index) => {
                                return(
                                <option key={index} value={item.idChannel}>{item.nome}</option>
                                )
                            })
                        }
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group>
                <Row>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>CNPJ da Empresa</Form.Label>
                    <br />
                    <MaskInput
                      style={{ width: '100%' }}
                      className="mask-input"
                      alwaysShowMask
                      mask={"00.000.000/0000-00"}
                      size={20}
                      showMask
                      maskChar="_"
                      placeholder="Digite o número do CNPJ"
                      value={companyData.cnpj}
                      onChange={(e) => setCompanyData({ ...companyData, cnpj: e.target.value })}
                    />
                  </Col>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>
                      CPF
                    </Form.Label>
                    <br />
                    <MaskInput
                      required
                      style={{ width: '100%' }}
                      className="mask-input"
                      alwaysShowMask
                      mask={"000.000.000-00"}
                      size={20}
                      showMask
                      maskChar="_"
                      placeholder="Digite o número do CPF"
                      value={companyData?.responsibleCPF || ""}
                      onChange={(e) => setCompanyData({ ...companyData, responsibleCPF: e.target.value })}
                    />
                  </Col>
                </Row>
                <Row>
                </Row>
              </Form.Group>
              
              <Form.Group>
                <Row>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>Código de origem</Form.Label>
                    <Form.Control
                      required
                      value={companyData.originCode}
                      type="text"
                      placeholder="Origem"
                      onChange={(e) =>
                        setCompanyData({ ...companyData, originCode: e.target.value })
                      }
                    />
                  </Col>
                  <Col lg="6" md="6" xs="12" sm="7">
                  <Form.Group className="company-dropdown-label-edit">
                      <Form.Label>Campanha</Form.Label>
                      <Form.Control 
                        value={companyData.idChannelCampaign} 
                        onChange={(e) => setCampaignValues(parseInt(e.target.value)) } 
                        as="select"
                      >
                        {
                            campaignList.map((item, index) => {
                                return(
                                <option key={index} value={item.idChannelCampaign}>{item.name}</option>
                                )
                            })
                        }
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </Form.Group>

              
              <Form.Group>
                <Row>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>Numero de licença</Form.Label>
                    <Form.Control
                      disabled
                      value={companyData.numberOfLicences}
                      type="number"
                      placeholder="Numero de licenças"
                      onChange={(e) =>
                        setCompanyData({ ...companyData, numberOfLicences: parseInt(e.target.value) })
                      }
                    />
                  </Col>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>Período de trials (em dias)</Form.Label>
                    <Form.Control
                      disabled
                      value={companyData.trialPeriod}
                      type="number"
                      placeholder="Tempo do trial"
                      onChange={(e) =>
                        setCompanyData({ ...companyData, trialPeriod: parseInt(e.target.value) })
                      }
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group>
                <Row>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>
                      Data da ultima validação
                    </Form.Label>
                    <br />
                    <DatePickerInput
                        locale="pt-br"
                        onChange={handleValidateDate}
                        value={companyData.lastValidationCheck}
                      />
                  </Col>
                </Row>
                <Row>
                </Row>
              </Form.Group>
            </div>
          </div>

          <hr style={{ marginTop: 30 }} />

          {/* Botões */}
          <div style={{ display: "flex", marginTop: 30 }}>
            <Button
              onClick={goBack}
              variant="secondary"
              style={{ marginRight: 15 }}
            >
              <div>
                <BiArrowBack
                  size={22}
                  style={{ marginBottom: 3, marginRight: 10 }}
                />
                Voltar
              </div>
            </Button>

            <Button variant="success" type="submit">
              {saving ? (
                <div style={{ display: "flex" }}>
                  <RiSave3Line
                    size={22}
                    style={{ marginBottom: 3, marginRight: 10 }}
                  />
                  Salvando{" "}
                  <div
                    className="loader loader-white"
                    style={{ marginLeft: 15 }}
                  />
                </div>
              ) : (
                <div>
                  <RiSave3Line
                    size={22}
                    style={{ marginBottom: 3, marginRight: 10 }}
                  />
                  Salvar
                </div>
              )}
            </Button>
          </div>

          {/* Mensagens de erro e sucesso */}
          <div style={{ marginTop: 10 }}>
            <div style={{ color: "green" }}>{successMessage}</div>
            <div style={{ color: "red" }}>
              {errorSave}
              {invalidPhone && "Telefone invalido."}
              {!validCpf && "CPF inválido"}
              {!validCnpj && "CNPJ inválido"}
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}
