// Basic
import React, { useState, useEffect } from "react";
import LojaWrapper from '../Components/LojaWrapper';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Modal from './Components/Modal';
import { getProductList, postCreateProduct, postEditProduct } from '../../../services/NexiDigitalAPI';
import Table from './Components/Table'


const CadastroProduto = () => {

  const initInfo = {
    title: '',
    imageURL: '',
    description: '',
    content: '',
};
  const [info, setInfo] = useState(initInfo);
  const [show, setShow] = useState(false);
  const [productList, setProductList] = useState([])

  const showModal = () => setShow(true);
  const hideModal = () => {
    setInfo(initInfo);
    setShow(false);
  }
  const editProduct = (itemInfo) => {
    setInfo(itemInfo);
    setShow(true);
  }
  const submitEditProduct = async (product,id) => {
    const submit = await postEditProduct(product,id);
    if(submit){
      products();
      hideModal();
    }
  }

  const submitcreateProduct = async (product) => {
    const submit = await postCreateProduct(product);
    if(submit){
      products();
      hideModal();
    }
  }

  const colorStyle = {backgroundColor: localStorage.getItem("secondaryColor")}

  const products = async () => {
    const list = await getProductList();
    setProductList(list);
  }
  useEffect(() => {
    products()
  }, [])

  return (
    <LojaWrapper>
      <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '20px'}}>
        <button className="blast-button btn btn-primary" onClick={showModal} style={colorStyle}>
          <AddCircleIcon style={{height:'26px', width:'26px'}}/> Cadastrar Produto
        </button>
      </div>
      
      <Table List={productList} editProduct={editProduct}/>
      <Modal show={show} hideModal={hideModal} info={info} submitEdit={submitEditProduct} submitCreate={submitcreateProduct}/>
    </LojaWrapper>
  )
}

export default CadastroProduto