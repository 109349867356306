import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, FormGroup, Card, Image, Row, Col } from "react-bootstrap";
import "./Termos.css";
import styles from "./ActiveAccount/styles.module.scss";
import Scrollbar from "react-scrollbars-custom";
import BreadCrumbPhase3 from '../assets/images/breadcrumb03.png';
import { AuthContext } from "../context/AuthContext";

export default function Termos() {
  const history = useHistory();
  const [aceitaTermo, setAceitaTermo] = useState(false);
  const [aceitaComunica, setAceitaComunica] = useState(false);
  const { loginData } = useContext(AuthContext)
  const primaryColor = loginData?.primaryColor

  function validateForm() {
    return aceitaTermo;
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (validateForm()) {
      localStorage.setItem("aceitaTermo", aceitaTermo)
      localStorage.setItem("aceitaComunica", aceitaComunica)

      history.push("/registro");
    }
  }
  
  return (
    <div className={styles.activeAccountPage}>
    <Row className={styles.rowCentered}>
        <Col lg="6" md="6" xs="12">
            <Card>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Text className={styles.titleText}>
                          Aceite dos termos de uso
                      </Form.Text>
                      <center><Image src={BreadCrumbPhase3} className={styles.imageStyle} fluid /></center>
                      <Form.Text style={{ padding: '10px 0px' }} className={styles.centeredText}>
                          Para utilizar este aplicativo, é necessário ler e aceitar os termos de uso. Caso queira receber informações a respeito de novos produtos ou servicos, marque a opção correspondente abaixo.
                      </Form.Text>
                      <Scrollbar style={{ height: 250, border: '1px solid lightgray', overflow: 'hidden', borderRadius: 5  }}>
                      <div
                      style={{ marginRight: 10, marginLeft: 3}} 
                      dangerouslySetInnerHTML={{ __html: `<html xmlns:v="urn:schemas-microsoft-com:vml"
xmlns:o="urn:schemas-microsoft-com:office:office"
xmlns:w="urn:schemas-microsoft-com:office:word"
xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
xmlns="http://www.w3.org/TR/REC-html40">

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=ProgId content=Word.Document>
<meta name=Generator content="Microsoft Word 15">
<meta name=Originator content="Microsoft Word 15">
<link rel=File-List
href="BMI%20BeDigital%20Termos%20de%20Uso%20rev.Maurício_arquivos/filelist.xml">
<link rel=Preview
href="BMI%20BeDigital%20Termos%20de%20Uso%20rev.Maurício_arquivos/preview.wmf">
<link rel=themeData
href="BMI%20BeDigital%20Termos%20de%20Uso%20rev.Maurício_arquivos/themedata.thmx">
<link rel=colorSchemeMapping
href="BMI%20BeDigital%20Termos%20de%20Uso%20rev.Maurício_arquivos/colorschememapping.xml">

<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Helvetica;
	panose-1:2 11 6 4 2 2 2 2 2 4;
	mso-font-charset:0;
	mso-generic-font-family:swiss;
	mso-font-pitch:variable;
	mso-font-signature:-536858881 -1073711013 9 0 511 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;
	mso-font-charset:0;
	mso-generic-font-family:roman;
	mso-font-pitch:variable;
	mso-font-signature:3 0 0 0 1 0;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;
	mso-font-charset:0;
	mso-generic-font-family:swiss;
	mso-font-pitch:variable;
	mso-font-signature:-469750017 -1073732485 9 0 511 0;}
@font-face
	{font-family:"Segoe UI";
	panose-1:2 11 5 2 4 2 4 2 2 3;
	mso-font-charset:0;
	mso-generic-font-family:swiss;
	mso-font-pitch:variable;
	mso-font-signature:-469750017 -1073683329 9 0 511 0;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:"";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	line-height:107%;
	mso-pagination:widow-orphan;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:minor-bidi;
	mso-fareast-language:EN-US;}
p.MsoCommentText, li.MsoCommentText, div.MsoCommentText
	{mso-style-noshow:yes;
	mso-style-priority:99;
	mso-style-link:"Texto de comentário Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	font-size:10.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:minor-bidi;
	mso-fareast-language:EN-US;}
span.MsoCommentReference
	{mso-style-noshow:yes;
	mso-style-priority:99;
	mso-ansi-font-size:8.0pt;
	mso-bidi-font-size:8.0pt;}
a:link, span.MsoHyperlink
	{mso-style-priority:99;
	color:#0563C1;
	mso-themecolor:hyperlink;
	text-decoration:underline;
	text-underline:single;}
a:visited, span.MsoHyperlinkFollowed
	{mso-style-noshow:yes;
	mso-style-priority:99;
	color:#954F72;
	mso-themecolor:followedhyperlink;
	text-decoration:underline;
	text-underline:single;}
p
	{mso-style-noshow:yes;
	mso-style-priority:99;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	mso-fareast-font-family:"Times New Roman";}
p.MsoCommentSubject, li.MsoCommentSubject, div.MsoCommentSubject
	{mso-style-noshow:yes;
	mso-style-priority:99;
	mso-style-parent:"Texto de comentário";
	mso-style-link:"Assunto do comentário Char";
	mso-style-next:"Texto de comentário";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	font-size:10.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:minor-bidi;
	mso-fareast-language:EN-US;
	font-weight:bold;}
p.MsoAcetate, li.MsoAcetate, div.MsoAcetate
	{mso-style-noshow:yes;
	mso-style-priority:99;
	mso-style-link:"Texto de balão Char";
	margin:0cm;
	mso-pagination:widow-orphan;
	font-size:9.0pt;
	font-family:"Segoe UI",sans-serif;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-fareast-language:EN-US;}
span.TextodebaloChar
	{mso-style-name:"Texto de balão Char";
	mso-style-noshow:yes;
	mso-style-priority:99;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Texto de balão";
	mso-ansi-font-size:9.0pt;
	mso-bidi-font-size:9.0pt;
	font-family:"Segoe UI",sans-serif;
	mso-ascii-font-family:"Segoe UI";
	mso-hansi-font-family:"Segoe UI";
	mso-bidi-font-family:"Segoe UI";}
span.TextodecomentrioChar
	{mso-style-name:"Texto de comentário Char";
	mso-style-noshow:yes;
	mso-style-priority:99;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Texto de comentário";
	mso-ansi-font-size:10.0pt;
	mso-bidi-font-size:10.0pt;}
span.AssuntodocomentrioChar
	{mso-style-name:"Assunto do comentário Char";
	mso-style-noshow:yes;
	mso-style-priority:99;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-parent:"Texto de comentário Char";
	mso-style-link:"Assunto do comentário";
	mso-ansi-font-size:10.0pt;
	mso-bidi-font-size:10.0pt;
	font-weight:bold;}
span.SpellE
	{mso-style-name:"";
	mso-spl-e:yes;}
.MsoChpDefault
	{mso-style-type:export-only;
	mso-default-props:yes;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:Calibri;
	mso-fareast-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:minor-bidi;
	mso-fareast-language:EN-US;}
.MsoPapDefault
	{mso-style-type:export-only;
	margin-bottom:8.0pt;
	line-height:107%;}
@page WordSection1
	{size:595.3pt 841.9pt;
	margin:70.85pt 3.0cm 70.85pt 3.0cm;
	mso-header-margin:35.4pt;
	mso-footer-margin:35.4pt;
	mso-paper-source:0;}
div.WordSection1
	{page:WordSection1;}
-->
</style>
<!--[if gte mso 10]>
<style>
 /* Style Definitions */
 table.MsoNormalTable
	{mso-style-name:"Tabela normal";
	mso-tstyle-rowband-size:0;
	mso-tstyle-colband-size:0;
	mso-style-noshow:yes;
	mso-style-priority:99;
	mso-style-parent:"";
	mso-padding-alt:0cm 5.4pt 0cm 5.4pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:0cm;
	line-height:107%;
	mso-pagination:widow-orphan;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;
	mso-ascii-font-family:Calibri;
	mso-ascii-theme-font:minor-latin;
	mso-hansi-font-family:Calibri;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:"Times New Roman";
	mso-bidi-theme-font:minor-bidi;
	mso-fareast-language:EN-US;}
</style>
<![endif]--><!--[if gte mso 9]><xml>
 <o:shapedefaults v:ext="edit" spidmax="1026"/>
</xml><![endif]--><!--[if gte mso 9]><xml>
 <o:shapelayout v:ext="edit">
  <o:idmap v:ext="edit" data="1"/>
 </o:shapelayout></xml><![endif]-->
</head>

<body lang=PT-BR link="#0563C1" vlink="#954F72" style='tab-interval:35.4pt;
word-wrap:break-word'>

<div class=WordSection1>

<p style='margin:0cm;background:white'><strong><u><span style='font-size:10.0pt;
font-family:"Helvetica",sans-serif;color:gray'>TERMOS E CONDIÇÕES DE USO DA
PLATAFORMA BE DIGITAL<o:p></o:p></span></u></strong></p>

<p style='margin:0cm;background:white'><span style='font-size:10.0pt;
font-family:"Helvetica",sans-serif;color:gray'><o:p>&nbsp;</o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>Última
atualização destes Termos e Condições de Uso disponibilizada no APP: 02 de outubro
de 2020.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'><o:p>&nbsp;</o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>O <a
name="_Hlk42663212">aplicativo </a><span class=SpellE><span style='mso-bookmark:
_Hlk42663212'>BeDigital</span></span><span style='mso-bookmark:_Hlk42663212'>
(“App”)</span> é operado pela <a name="_Hlk42663056"><span style='mso-bidi-font-weight:
bold'>WF White Fox Educação Corporativa Ltda., sociedade empresária limitada,
com sede na Cidade de Barueri, Estado de São Paulo, na Avenida Andrômeda, 885,
sala 1121, </span></a><span class=SpellE><span style='mso-bookmark:_Hlk42663056'><span
style='mso-bidi-font-weight:bold'>Brascan</span></span></span><span
style='mso-bookmark:_Hlk42663056'><span style='mso-bidi-font-weight:bold'> <span
class=SpellE>Century</span> Plaza, Alphaville Empresarial, CEP 06473-000</span></span><span
style='mso-bidi-font-weight:bold'> (“<span class=SpellE>WhiteFox</span>”)</span>.
Estes Termos e Condições de Uso, em conjunto com sua Política de Privacidade,
estabelecem os termos e condições aplicáveis à utilização do App. É muito
importante que o usuário (“Usuário”) leia, compreenda e aceite estas regras
anteriormente à utilização do App. Estes Termos e Condições de Uso podem ser
alterados a qualquer momento e o Usuário será previamente informado caso isso
aconteça. Caso o Usuário não concorde com alguma mudança feita nestes termos,
ele deverá interromper a utilização do App.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'><o:p>&nbsp;</o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><strong><u><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>1. SOBRE
O SERVIÇO</span></u></strong><span style='font-size:10.0pt;font-family:"Helvetica",sans-serif;
color:gray'><o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>1.1. A <span
style='mso-bidi-font-weight:bold'>White Fox</span>, por meio do App, oferece um
serviço pelo qual os Usuários terão suas à sua disposição conteúdos que foram
produzidos pela equipe do App ou <span class=SpellE>conteúdos</span> de
terceiros que foram selecionados visando o desenvolvimento profissional dos
usuários. Os temas desses conteúdos são escolhidos de acordo com um conjunto de
competências e comportamentos profissionais que foram identificados e
detalhados pela White Fox a fim de guiar atividades como essa.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>1.2. Todas
as interações dos usuários são registradas de maneira a permitir a medição do
engajamento dos usuários, seus temas de interesse e o seu grau de fluência nos
temas relevantes para o seu desenvolvimento. Estes dados ficam armazenados nos
servidores do App e servem para a extração de relatórios e elaboração de dashboards
de monitoramento geral do uso do App.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>1.3. Para
atingir os propósitos do App, os dados de interação dos usuários serão compartilhados
com o contratante da White Fox (“Contratante”), o qual, usualmente, será o
empregador do Usuário. <o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'><o:p>&nbsp;</o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><b><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>O
USUÁRIO, DESDE JÁ, CONCORDA E ANUI DE FORMA EXPRESSA E IRREVOGÁVEL COM A
DISPONIBILIZAÇÃO DO RELATÓRIO AO CONTRATANTE.<o:p></o:p></span></b></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'><o:p>&nbsp;</o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>1.5. O
Usuário terá acesso ao Relatório, conforme determinado pelo Contratante.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'><o:p>&nbsp;</o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><strong><u><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>2.
CADASTRO E REGISTRO<o:p></o:p></span></u></strong></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'><o:p>&nbsp;</o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>2.1.
Para utilizar o App, o Usuário realizará o seu cadastro, fornecendo as suas
informações pessoais e criando um nome de usuário (“Login”) e senha que serão
utilizados para acesso ao App.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>2.1.1. O
Usuário é responsável pela veracidade, validade e precisão das informações
pessoais por ele fornecidas no seu cadastro, inclusive com relação à indicação
de endereço de e-mail válido e de sua titularidade, comprometendo-se, ainda, a
manter referido cadastro sempre atualizado.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>2.1.2. A
White Fox não terá qualquer obrigação de fiscalizar ou validar as informações
pessoais fornecidas pelo Usuário. Não obstante, a White Fox poderá se valer de
todas as formas lícitas para verificar, a qualquer tempo, se as informações que
o Usuário proveu são verdadeiras.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>2.1.3. A
senha e o Login criados pelo Usuário para acesso ao App são confidenciais e de
responsabilidade exclusiva do Usuário, o qual deverá entrar em contato com a White
Fox, imediatamente, na hipótese de comprometimento do sigilo de tais
informações.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>2.2. A White
Fox busca as melhores práticas do mercado para garantir a segurança do acesso e
cadastro do Usuário. Contudo, o sigilo e a segurança do Login e da senha são de
única e exclusiva responsabilidade do Usuário. Nenhuma reparação será devida em
razão de má proteção pelo Usuário sobre seus dados de acesso.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>2.3. Na
hipótese de a White Fox constatar que as informações do Usuário são de alguma
forma incompletas, imprecisas ou falsas, o Usuário poderá ter seu acesso
suspenso ou cancelado, a exclusivo critério da White Fox, sem prejuízos de
outras medidas que sejam aplicáveis.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'><o:p>&nbsp;</o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><strong><u><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>3.
CONTEÚDO</span></u></strong><span style='font-size:10.0pt;font-family:"Helvetica",sans-serif;
color:gray'><o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>3.1. A White
Fox mantém esforços contínuos para preservar o conteúdo do App sempre
atualizado e completo, livre de quaisquer erros ou vírus. A despeito de tais
esforços, compre destacar que a White Fox não é responsável por esses e outros
possíveis problemas que possam vir a ser observados no App.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>3.2. A White
Fox poderá, a qualquer momento, sem aviso prévio e a seu exclusivo critério,
adicionar ou remover conteúdos e funcionalidades do App.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>3.3. O
Usuário concorda em utilizar este App de boa-fé, sem usar, carregar, transmitir
ou enviar do App ou para o App qualquer material:<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>(a) Que
seja de cunho violento ou ameaçador, difamatório, obsceno, ofensivo,
pornográfico, abusivo, passível de incitar qualquer forma de ódio racial,
discriminatório ou em violação de privacidade de terceiro;<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>(b) Para
o qual não tenham sido obtidas pelo Usuário todas as licenças ou aprovações
necessárias;<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>(c) Que
constitua ou incite condutas que possam ser consideradas ilícitos criminais ou
civis, que violem direitos de terceiros seja no Brasil ou no exterior ou que
sejam meramente ilegais sob qualquer outra forma;<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>(d) Que
sejam tecnicamente danosos, incluindo, mas não limitado a vírus de computador,
componentes maliciosos, dados corrompidos e outros programas ou dados de
computador maliciosos ou que sejam projetados para interferir, interromper ou
derrubar as operações normais de um computador ou aplicativo; e,<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>(e) Que
tenha como objetivo fraudar, dissimular ou comprometer a realização dos
Serviços pelo App, seja pela criação de perfis falsos, violação de protocolos
de segurança ou qualquer outro meio.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>3.4. O
Usuário deve zelar para que o uso do App e dos Serviços não seja prejudicial à
estabilidade e à disponibilidade destes. Caso isso ocorra, o Usuário poderá ser
responsabilizado pelos prejuízos a que der causa.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>3.5. A White
Fox poderá ser requerida a cooperar com autoridades e com diligências judiciais
que visem a identificar os Usuários que atuem em descumprimento com as
disposições deste Item 3, hipótese em que a White Fox cooperará ativamente,
disponibilizando todas as informações e materiais requisitados por tais
autoridades.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'><o:p>&nbsp;</o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><strong><u><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>4.
DIREITOS E OBRIGAÇÕES DO USUÁRIO E DA </span></u></strong><b><u><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>WHITE
FOX</span></u></b><span style='font-size:10.0pt;font-family:"Helvetica",sans-serif;
color:gray'><o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>4.1.
Este Termo e Condições de Uso concede ao Usuário uma licença revogável, não
exclusiva e intransferível de uso do App, sendo certo que o Usuário não poderá
utilizar e nem permitir o uso do App para qualquer outra finalidade não
expressamente prevista neste instrumento.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>4.1. É
responsabilidade exclusiva do Usuário:<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>(i)
Informar e comprovar à White Fox quando o seu Login e senha para acesso ao App
estiverem sendo utilizados indevidamente por pessoas não autorizadas.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>4.2. O
Usuário não poderá:<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>(a)
Copiar, ceder, sublicenciar, vender, reproduzir, doar, transferir total ou
parcialmente, sob quaisquer modalidades, gratuita ou onerosamente, provisória
ou permanentemente, o App, assim como seus módulos, partes, telas ou quaisquer
informações relativas ao mesmo;<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>(b)
Reproduzir, adaptar, modificar e/ou empregar, no todo ou em parte, para
qualquer fim, o App ou qualquer conteúdo do App; ou,<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>(c)
Utilizar o App para finalidade diversa daquela para a qual foi disponibilizado
pela White Fox e/ou Contratante.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>4.3. O
Usuário é único e exclusivo responsável pela veracidade das respostas inseridas
durante a realização do Questionário.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>4.4. A White
Fox compromete-se a prestar os Serviços de acordo com os padrões de qualidade,
habilidade e zelo compatíveis com os praticados no mercado.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>4.5. O
Usuário outorga à White Fox o direito de analisar e utilizar os dados coletados
durante a sua interação com o App, a seu exclusivo critério, para a utilização
dos dados coletados a ser disponibilizado ao Contratante.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>4.5.1. Os
relatórios e dashboards podem incluir opiniões e observações baseadas em
pesquisa e estudos próprios da White Fox.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>4.5.2.
Não obstante os dados coletados podem ser utilizados para auxiliar no
desenvolvimento de negócios e estratégias comerciais, o Contratante não deverá
confiar ou embasar-se exclusivamente nele para tomar (ou deixar de tomar)
decisões de negócios. Neste sentido, qualquer decisão neste âmbito será de
responsabilidade exclusiva do Contratante.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>4.5.3. A
White Fox se isenta no que tange à eventuais perdas e/ou prejuízos sofridos
pelo Contratante e/ou pelo Usuário como resultado direto ou indireto da
utilização dos dados coletados.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>4.6. A White
Fox não será responsável, sob nenhuma hipótese, pela (i) perda de reputação ou
oportunidade; (<span class=SpellE>ii</span>) qualquer perda ou falha em obter o
lucro esperado, a receita, a economia ou qualquer outra forma de perda
econômica, seja ela de maneira direta ou indireta; ou (<span class=SpellE>iii</span>)
qualquer forma indireta, especial, incidental, punitiva ou consequencial de
perdas e danos.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'><o:p>&nbsp;</o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><strong><u><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>5.
DISPONIBILIDADE DO APP</span></u></strong><span style='font-size:10.0pt;
font-family:"Helvetica",sans-serif;color:gray'><o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>5.1. A White
Fox irá empreender seus melhores esforços para garantir a disponibilidade
durante 99% (noventa e nove por cento) do período de uso do App.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>5.2. O
acesso ao App pode ser interrompido, suspenso ou ficar intermitente, sem
qualquer aviso prévio, em caso de falha de sistema ou servidor, manutenção,
alteração de sistemas ou por qualquer motivo que escape ao controle da White Fox,
sem que se faça devida qualquer reparação por parte desta.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'><o:p>&nbsp;</o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><strong><u><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>6.
DIREITOS DE PROPRIEDADE INTELECTUAL</span></u></strong><span style='font-size:
10.0pt;font-family:"Helvetica",sans-serif;color:gray'><o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>6.1. O
uso comercial do nome, dos desenhos e da expressão “<span class=SpellE>BeDigital</span>”
como nome empresarial, marca ou nome de domínio, bem como os conteúdos das
telas do App assim como os programas, bancos de dados, documentos e demais
utilidades e aplicações relacionadas ao App e/ou aos Serviços são de
propriedade exclusiva da White Fox.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>6.2. O
uso indevido e a reprodução total ou parcial dos conteúdos referidos no Item
acima são proibidos, sendo certo que tais condutas poderão constituir crime
contra a propriedade intelectual e/ou violação de direito autoral.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>6.3. O
Usuário e/ou o Contratante poderão realizar o download ou imprimir uma cópia
das informações e relatórios disponibilizados por meio do App desde que para
uso pessoal e não comercializável. Qualquer utilização deste material e/ou do
conteúdo do App, no todo ou em parte, que não para uso pessoal do Usuário e/ou
do Contratante, conforme estabelecido estes Termos e Condições de Uso, sem que
haja prévio consentimento por escrito da White Fox, é expressamente proibida e
poderá acarretar em responsabilizações penais, civis e administrativas.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'><o:p>&nbsp;</o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'><o:p>&nbsp;</o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><strong><u><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>8.
VIGÊNCIA E RESCISÃO</span></u></strong><span style='font-size:10.0pt;
font-family:"Helvetica",sans-serif;color:gray'><o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>8.1.
Estes Termos e Condições de Uso vigerão por prazo indeterminado, a partir do
aceite do Usuário.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>8.2.
Para promover o bom funcionamento e qualidade do App, a White Fox se reserva no
direito de, sem notificação prévia, impedir ou interromper o acesso do Usuário
que, a exclusivo critério da White Fox, estiver por qualquer forma violando
qualquer disposição destes Termos e Condições de Uso, da Política de
Privacidade ou de qualquer outra obrigação assumida pelo Usuário.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>8.3. Nas
hipóteses de suspeita de uso inadequado ou de reincidência de tal uso indevido
do App, o Usuário também poderá ter o seu acesso ao App cancelado.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'><o:p>&nbsp;</o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><strong><u><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>9. DAS
ALTERAÇÕES DOS TERMOS E CONDIÇÕES DE USO</span></u></strong><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'><o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>9.1. A White
Fox poderá, a qualquer tempo, alterar estas disposições destes Termos e
Condições de Uso, mediante o envio de comunicação ao Usuário.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>9.2. A
comunicação ao Usuário das alterações destes Termos e Condições de Uso será
feita mediante qualquer meio de comunicação, cabendo ao Usuário manifestar-se
acerca da aceitação ou não das novas condições contratuais.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>9.3.
Caso o Usuário não concorde com as alterações comunicadas na forma do Item
anterior, isto acarretará a rescisão contratual.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>9.4. A
não manifestação do Usuário acerca das novas condições dos Termos e Condições
de Uso impedirá a utilização do App até que o Usuário se manifeste, nos termos
da Cláusula 9.2 acima.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'><o:p>&nbsp;</o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><strong><u><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>10.
DISPOSIÇÕES FINAIS</span></u></strong><span style='font-size:10.0pt;font-family:
"Helvetica",sans-serif;color:gray'><o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>10.1. A White
Fox é uma empresa constituída no Brasil e o App e seus Serviços são criados e
mantidos em<span style='mso-spacerun:yes'>  </span>cumprimento às leis
brasileiras e demais tratados que são incorporados à jurisdição brasileira,
incluindo, mas não se limitando a lei nº 13.709, de 14 de agosto de 2018 (“Lei
Geral de Proteção de Dados”). Caso o Usuário esteja usufruindo do acesso do App
fora do Brasil, o Usuário será responsável pelo cumprimento das leis locais, na
medida em que forem aplicáveis.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>10.2.
Caso a White Fox deixe de exercer qualquer direito previsto nestes Termos e
Condições de Uso, isto não deverá ser interpretado como uma renúncia, abdicação
ou revogação de disposição constante destes Termos e Condições de Uso.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>10.3. As
normas destes Termos e Condições de Uso devem ser interpretadas como um todo,
não sendo aplicável nenhuma suposição de que eventuais ambiguidades devam ser
resolvidas contra a parte que a redigiu inicialmente.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>10.4.
Caso qualquer das partes seja obrigada a recorrer a ações ou medidas judiciais
para fazer valer seus direitos, a parte culpada sujeitar-se-á ao pagamento de
perdas e danos, a serem arbitrados em Juízo, sem prejuízo das custas
processuais, honorários advocatícios que forem arbitrados pelo Poder
Judiciário, correção monetária e demais cominações de direito.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>10.5.
Todos os itens destes Termos e Condições de Uso serão regidos pelas leis
vigentes da República Federativa do Brasil. Para dirimir quaisquer
controvérsias é eleito o Foro da Cidade de São Paulo no Estado de São Paulo.<o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'><o:p>&nbsp;</o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><strong><u><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>11.
CONTATOS DA WHITE FOX</span></u></strong><span style='font-size:10.0pt;
font-family:"Helvetica",sans-serif;color:gray'><o:p></o:p></span></p>

<p style='margin:0cm;text-align:justify;background:white'><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'>11.1. As
notificações, correspondências, pedido de informações ou qualquer outro motivo
para contatar a White Fox deverão ser encaminhas à Rua Avenida Andrômeda, 885,
sala 1121, <span class=SpellE>Brascan</span> <span class=SpellE>Century</span>
Plaza, Alphaville Empresarial, CEP 06473-000, ou </span><span style='color:
black;mso-color-alt:windowtext'><a
href="file:///C:/Users/Fabio.Capricho/AppData/Local/Microsoft/Windows/INetCache/Content.Outlook/LBTR5YNF/pelo"><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray;
text-decoration:none;text-underline:none'>pelo</span></a></span><span
style='font-size:10.0pt;font-family:"Helvetica",sans-serif;color:gray'> <span
class=SpellE>e mail</span>: <a name="_Hlk42663312">“suporte@bedigital.net.br”</a>.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify'><o:p>&nbsp;</o:p></p>

</div>

</body>

</html>
` }} />
                      </Scrollbar>
                    </Form.Group>
                            
                            
            <FormGroup controlId="formBasicCheckbox">
              <Form.Check className={styles.checkText}
                type="checkbox" 
                label="Aceito os Termos"
                value={aceitaTermo}
                onChange={e => setAceitaTermo(e.target.checked)}
                />
            </FormGroup>
            <Form.Group>
              <Form.Check className={styles.checkText}
                type="checkbox" 
                label="Aceito receber comunicação"
                value={aceitaComunica}
                onChange={e => setAceitaComunica(e.target.checked)}
                />
            </Form.Group>

            <Button style={{ background: primaryColor, borderColor: primaryColor}} variant="primary" size="lg" disabled={!validateForm()} type="submit">
              Aceito os Termos
            </Button>
         </Form>
         </Card.Body>
                </Card>
            </Col>
        </Row>
    </div>
  );
}