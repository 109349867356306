import React from 'react';
import styles from './styles.module.scss';
import { FcCancel } from 'react-icons/fc';
import moment from "moment";
import StandartDataTable from '../../../../../components/StandartDataTable';

const Table = ({ List, returnSale, loading}) => {

    const columns = [
        { name: "Número da compra", selector: "idPurchase", sortable: true, },
        { name: "Usuário", selector: "userName", sortable: true, },
        { name: "Preço", selector: "totalPrice", sortable: true, },
        { name: "Data", selector: "purchaseDate", sortable: true },
        { name: "Status", selector: "status", sortable: false },
        { name: "Ações", selector: "action", sortable: false, },
    ];

    const treatedList = List.map( (item) => {
        const purchaseDate = Boolean(item.purchaseDate)
        return {
            ...item,
            purchaseDate: purchaseDate ? moment(item.purchaseDate).format("DD/MM/YYYY") : "-",
            totalPrice: item.totalPrice.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}),
            status: item.status ? (<span>Ativa</span>) : (<span>Cancelado</span>),
            action: item.status ? (
                <button className={styles.actionButton} onClick={() => returnSale(item)}>
                    <FcCancel className={styles.actionIcon} />
                </button>
            ) : <span></span>,
        }
    })

    return (
        <div className="report-table-container">
            <StandartDataTable
                columns={columns}
                data={treatedList}
                loading={ loading }
            />
        </div>
    );
}

export default Table;