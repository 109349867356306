import React, { useState, useEffect } from "react";
import Filtro from './Filtro';
import Table from './Table';
import ReportAcordeon from '../../../Components/ReportAcordeon'
import { lastRanking } from "../../../../../services/NexiDigitalAPI";


const WorstInRanking = ({ index, expanded }) => {
    const name = "Conteúdos com menos avaliações";
    const description = "Apresenta o conteúdo visitado pelos usuários com menos avaliações, podendo observar a média e a quantidade de avaliações.";
    const [loading, setLoading] = useState(false);
    const [dados, setDados] = useState([]);


    const actionFilter = async (values) => {
        setLoading(true);
        const data = await lastRanking(values);
        setDados(data);
        setLoading(false);
    }

    const filter = (
        <Filtro 
            actionFilter={actionFilter} 
            loading={loading} 
        />
    );

    const result = (
        <Table 
            dados={dados} 
            loading={loading} 
        />
    );
    
    return (
        <ReportAcordeon
            name={ name }
            description={description}
            filter={filter}
            result={result}
            index={index}
            expanded={expanded}
        />
    )

}

export default WorstInRanking;