import React, { useEffect } from "react";
import { withRouter } from 'react-router-dom'
import { transformText  } from '../utils/Utils'
import GestaoConteudo from "./cms/GestaoConteudo";
import GestaoAgenda from "./Calendario";
import UploadList from "./cms/UploadList";
import Biblioteca from "./Biblioteca";
import Gamification from "./Gamification";
import CompanyManager from "./CompanyManager/CompanyManager";
import Reports from "./Reports";
import TelaConfiguracao from "./configuracao/Configuracao"
import User from "./User";
import Loja from "./Loja";
import QrcodeManager from "./../containers/QrcodeManager";
import Profiles from "./../containers/Profiles";
import MenuManagement from "./MenuManagement";


function MenuSelector(props) {
    useEffect(() => {
        _getSectionTypeByName()
    }, [])

    function _getSectionTypeByName() {
        // gets the current path name
        let pathName = props.match.path.replace("/", "")

        // gets menu options stored on cache
        const menuOptionList = JSON.parse(localStorage.getItem('menu-option'))

        // gets the menu object according to the path name
        let menuItem = menuOptionList.filter(obj => {
            return (transformText(obj.text)+"/"+obj.sectionTo) === pathName
        })[0]

        return menuItem
    }

    function noSection(){
        return(
            <div style={{
                width: '100%',
                textAlign: 'center',
                color: 'rgba(255,255,255,0.8)',
                paddingTop: 'calc(25vh - 000px - 54px)',
                display: 'flex',
                justifyContent: 'center'
                }}>
                <div style={{ backgroundColor: 'gray', width: 250, borderRadius: 5 }}>
                    <span>
                        Seção não disponível
                    </span>
                </div>
            </div>
        )
    }

    function selectTemplate(type) {
        type = parseInt(type)
        switch (type) {
            case 26:
                return <GestaoConteudo idSection={_getSectionTypeByName().sectionTo} />
            case 27:
                return <User idSection={_getSectionTypeByName().sectionTo} />
            case 28:
                return <Reports idSection={_getSectionTypeByName().sectionTo} />
            case 30:
                return <TelaConfiguracao idSection={_getSectionTypeByName().sectionTo} />
            case 35:
                return <UploadList idSection={_getSectionTypeByName().sectionTo} />
            case 37:
                return <Biblioteca idSection={_getSectionTypeByName().sectionTo} />        
            case 39:
                return <CompanyManager idSection={_getSectionTypeByName().sectionTo} />
            case 40:
                return <GestaoAgenda idSection={_getSectionTypeByName().sectionTo} />
            case 41:
                return <Loja idSection={_getSectionTypeByName().sectionTo} />
            case 42:
                return <Gamification idSection={_getSectionTypeByName().sectionTo} />
            case 46:
                    return <QrcodeManager idSection={_getSectionTypeByName().sectionTo} />
            case 47:
                    return <Profiles idSection={_getSectionTypeByName().sectionTo} />
            case 50:
                    return <MenuManagement idSection={_getSectionTypeByName().sectionTo} />
            default:
                return noSection()
        }
    }


    return (
        selectTemplate(_getSectionTypeByName().idSectionType)
    )

}

export default withRouter(MenuSelector);
