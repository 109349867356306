
import React, { useState, useEffect, useCallback, useRef } from "react";
import { withRouter } from "react-router-dom";
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import CircularProgress from "@material-ui/core/CircularProgress";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import {
  getGroupList,
  getSchedules,
  insertSchedules,
  updateSchedules,
  deleteSchedules,
  getSchedulesTypes,
  getUsers
} from "../../services/NexiDigitalAPI";

import 'react-big-calendar/lib/sass/styles.scss';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';

import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';

import { DatePickerInput } from "rc-datepicker";
import DatePicker from "react-datepicker";

import './styles.scss';

import ptBR from 'date-fns/locale/pt-BR';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

const DragAndDropCalendar = withDragAndDrop(Calendar)

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


let allViews = Object.keys(Views).map(k => Views[k])

const localizer = momentLocalizer(moment)

const Calendario = (props) => {

  const [requiredFields,setRequiredFields] =  useState({
    title: true,
    idScheduleType: true,
    start: true,
    end: true,
    // idUser: true
  });

  const [listGroupList,setListGroupList] = useState('');
  const [listTypes,setListTypes] = useState('');
  const [listUsers,setListUsers] = useState('');
  const [newEvent,setNewEvent] = useState();
  const [eventsList,setEventsList] = useState([]);
  const [state,setState] =  useState({
    events: eventsList,
    displayDragItemInCell: true,
  });
  const isMountedRef = useRef(null);
  const isMountedRefGroupList = useRef(null);
  const isMountedRefSchendulesTypes = useRef(null);
  const isMountedRefListUsers = useRef(null);
  const _getGroupList = useCallback(async () => {
    if( listGroupList === '' ){
      await getGroupList()
      .then((response) => {
        if(response){
          let listagem = [];
            response.forEach(value => {
              if(value.idGroups){
                listagem[value.idGroups] = value

              }
           });
          setListGroupList(listagem);
        }
      })
      .catch((error) => {
        setListGroupList([])
        console.log("error", error);
      });
    }
  }, [listGroupList])

  const _getSchedulesTypes = useCallback(async () => {
    const result = await getSchedulesTypes();
    if(isMountedRefSchendulesTypes.current){
      if( listTypes === '' ){
        if(result){
            let listagem = [];

            result.forEach(value => {
                if(value.idScheduleType){
                  listagem[value.idScheduleType] = value

                }
             })
            setListTypes(listagem)
        }else{
          setListTypes([])
        }
      }
    }

  }, [listTypes])

  const _getUsers = useCallback(async () => {
    const result = await getUsers();
    if(isMountedRefListUsers.current){
      if( listUsers === '' ){
        if(result){
            let listagem = result.data.userList;
            listagem.forEach(value => {
                if(value.idUser){
                  listagem[value.idUser] = value

                }
             })
             setListUsers(listagem)
        }else{
          setListUsers([])
        }
      }
    }

  }, [listUsers])

  const _eventsList = useCallback(async () => {

      const result = await getSchedules();
      let _aux = []
      if(isMountedRefSchendulesTypes.current){
        try{
          if(result?.data.success === true ){


            const schedules = result?.data.scheduleResult.schedules
            _aux  =  schedules.map( (item, index )  => {

              let dt = (item.startDate).replace('Z','').replace('z','');
              let dateItemStart = new Date(dt);

              let dtEnd = (item.endDate).replace('Z','').replace('z','');
              let dateItemEnd = new Date(dtEnd);
              return {
                ...item,
                start: dateItemStart,
                end: dateItemEnd,
                id: item.idSchedule
              }
            });
            setEventsList(result)
            setState({...state,events: _aux});
          }
        }catch(e){
          setEventsList([])
          setState({...state,events: []});

        }
      }
  }, [])

  const appDetails = JSON.parse(localStorage.getItem('appDetails'));

  const eventStyleGetter = (event) => {
      var style = {
        backgroundColor: appDetails.primaryColor,
        color: appDetails.loginTextColor,
      };
      return {
          style: style
      };
  };

  useEffect(() => {
    _getGroupList();
  },[_getGroupList]);


  useEffect(() => {
    isMountedRefListUsers.current = true;
    if(isMountedRefListUsers.current){
      _getUsers();

    }
    return () => (isMountedRefListUsers.current = false);

  },[_getUsers]);

  useEffect(() => {
    isMountedRefSchendulesTypes.current = true;
    if(isMountedRefSchendulesTypes.current){
      _getSchedulesTypes();

    }
    return () => (isMountedRefSchendulesTypes.current = false);

  },[_getSchedulesTypes]);

  useEffect(() => {
    isMountedRef.current = true;
    _eventsList()
    return () => (isMountedRef.current = false);

  }, [_eventsList]);






const handleSelect = ({ start, end }) => {
  setModalFiels({
    title: "",
    start: start,
    end: start,
    groups: [],
    url: '',
    idScheduleType: '',
    idUser: '',
  });
  setOpen(true);
}



const requiredFieldsValidate = (event,callback = function(response){})=>{

  let verification = true;
  let status = {};

  Object.keys(requiredFields).forEach( (field)=>{
    if( (typeof event[field] != 'undefined' && event[field]) || event == true  ){
      status[field] = true;
    }else{
      status[field] = false;
      verification = false;
    }
  })

  setRequiredFields(status);
  callback(verification);

}



useEffect(() => {
  if( newEvent ){

    let newEventInsert = {};
    newEventInsert.title = newEvent.title;
    newEventInsert.url = newEvent.url;

    newEventInsert.startDate = moment(newEvent.start).format('YYYY-MM-DD HH:mm');
    newEventInsert.endDate = moment(newEvent.end).format('YYYY-MM-DD HH:mm');
    newEventInsert.description = newEvent.description;
    newEventInsert.idScheduleType = newEvent.idScheduleType;
    newEventInsert.idUser = parseInt(newEvent.idUser);
    newEventInsert.groups = newEvent.groups;
    if( newEventInsert.groups[0] === 'Todos' ){
      newEventInsert.groups.splice(0, 1);
    }

    insertSchedules(newEventInsert)
    .then((response) => {

      if ( response.success === true ) {
        setState({
          events: [
            ...state.events,
            {
              id: response.idSchedule[0],
              start: newEvent.start,
              end: newEvent.end,
              title: newEvent.title,
              url: newEvent.url,
              description: newEvent.description,
              idScheduleType: newEvent.idScheduleType,
              groups: newEvent.groups,
              idUser: newEvent.idUser,
            },
          ],
        })
      }

    })
    .catch((error) => {
      console.log("error", error);
    });

  }
},[newEvent])

const resizeEvent = ({ event, start, end }) => {
  const { events } = state

  const nextEvents = events.map(existingEvent => {
    return existingEvent.id == event.id
      ? { ...existingEvent, start, end }
      : existingEvent
  })

  setState({
    events: nextEvents,
  })

  let newEvent = event;
  newEvent.start = start;
  newEvent.end = end;
  updateEventApi(newEvent);

}


const deleteEvent = (eventID) => {
  const { events } = state;
  deleteSchedules(eventID)
  .then((response) => {

    if ( response.success == true ) {
      events.map( (value, index) => {
        if( value.id == eventID ){
          delete events[index];
        }
      })
      setState({
        events: events,
      })
    }

  })
  .catch((error) => {
    console.log("error", error);
  });
}


const updateEvent = (event) => {

    const { events } = state
  const listaEditada = events.map(existingEvent => {
    return existingEvent.id == event.id
      ? {
          ...existingEvent,
          title: event.title,
          url: event.url,
          start: event.start,
          end: event.end,
          idScheduleType: event.idScheduleType,
          groups: event.groups,
          idUser: event.idUser,
          description: event.description
        }
      : existingEvent
  })
  setState({
    events: listaEditada,
  })
}

const updateEventApi = (event) => {
  let updatEvent = event;
  updatEvent.startDate = moment(event.start).format('YYYY-MM-DD HH:mm');
  updatEvent.endDate = moment(event.end).format('YYYY-MM-DD HH:mm');
  updatEvent.groups = updatEvent.groups;
  updatEvent.idUser = updatEvent.idUser;
  if( updatEvent.groups[0] == 'Todos' ){
    updatEvent.groups.splice(0, 1);
  }
  updateSchedules(updatEvent);
}

const moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {


  const { events } = state

  let allDay = event.allDay

  if (!event.allDay && droppedOnAllDaySlot) {
    allDay = true
  } else if (event.allDay && !droppedOnAllDaySlot) {
    allDay = false
  }

  const nextEvents = events.map(existingEvent => {
    return existingEvent.id == event.id
      ? { ...existingEvent, start, end, allDay}
      : existingEvent
  })

  let newEvent = event;
  newEvent.start = start;
  newEvent.end = end;
  updateEventApi(newEvent);

  setState({
    events: nextEvents,
  })

}

const handleDragStart = event => {
  setState({ ...state, draggedEvent: event })
}

const dragFromOutsideItem = () => {
  return state.draggedEvent
}

const onDropFromOutside = ({ start, end, allDay }) => {
  const { draggedEvent } = state

  const event = {
    id: draggedEvent.id,
    title: draggedEvent.title,
    url: draggedEvent.url,
    start,
    end,
    allDay: allDay,
  }

  setState({ ...state, draggedEvent: null })
  moveEvent({ event, start, end })

}



const defaultMessages = {
  date: "Data",
  time: "Hora",
  event: "Evento",
  allDay: "Dia Todo",
  week: "Semana",
  work_week: "Eventos",
  day: "Dia",
  month: "Mês",
  previous: "Anterior",
  next: "Próximo",
  yesterday: "Ontem",
  tomorrow: "Amanhã",
  today: "Hoje",
  agenda: "Agenda",
  noEventsInRange: "Não há eventos no período.",
  showMore: function showMore(total) {
    return "+" + total + " mais";
  }
};


const [modalFields,setModalFiels] = useState({
  id: '',
  title: "",
  url: "",
  start: new Date(),
  end: new Date(),
  description: "",
  idScheduleType: "",
  groups: [],
  idUser: '',
});

const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


function changeDateStart (jsDate){
  setModalFiels({
    ...modalFields,
    start: jsDate
  })
}

function changeDateEnd (jsDate){
  setModalFiels({
    ...modalFields,
    end: jsDate
  })
}



const useStylesMultiselect = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {},
  noLabel: {}
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      marginTop: ITEM_HEIGHT,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const classesMultiselect = useStylesMultiselect();
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setPersonName(value);
  };

  const infoEvent = (e) => {
    if(listTypes.length && e){
      let title = e.title;
      let url = e.url;
      let tipo = ( e.idScheduleType ? ' - Tipo: ' + listTypes[e.idScheduleType].description : null );
      let user = ""
      if( listUsers.length > 0  ){
        user = ( e.idUser ? ' - Usuário: ' + ( typeof listUsers[e.idUser].name != "undefined" ? listUsers[e.idUser].name : "---" ) : null );
      }
      let grupos = ' - Grupos:' + ( (e.groups).length > 0 ? (e.groups).map( (value,index)=> ( Object.keys(listGroupList).includes( value.toString() ) ? ' ' + listGroupList[value].title : null ) ) : ' Todos' );
      let descricao = ( typeof e.description != 'undefined' && e.description && e.description != "null" ? ' - Descrição: ' + e.description : '' );
      return title + tipo + user + grupos + url + descricao;
    }

  }



  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">{ (modalFields.id ? 'Editar' : 'Adicionar') } Evento</h2>
      <div id="simple-modal-description">

        <label className="input-label">Título
        <TextField style={ ( requiredFields.title === true ? {} : {border: "solid 2px #843434"} ) } type="text" className="input" value={modalFields.title} onChange={(e) => setModalFiels({...modalFields,title:e.target.value})} />
        </label>
        <br />

        <div>
          <label className="input-label input-w-50">Inicio
            <div className="container-datePicker-item" style={ ( requiredFields.start === true ? {width:'100%'} : {border: "solid 2px #843434",width:'100%'} ) } >
              <DatePicker
                className="inputDateTime"
                selected={modalFields.start}
                showTimeSelect
                timeFormat="HH:mm"
                dateFormat="dd/MM/yyyy HH:mm"
                onChange={changeDateStart}
                locale={ptBR}
              />
            </div>
          </label>
          <label className="input-label input-w-50">Fim
            <div className="container-datePicker-item" style={ ( requiredFields.end === true ? {width:'100%'} : {border: "solid 2px #843434",width:'100%'} ) } >
              <DatePicker
                className="inputDateTime"
                selected={modalFields.end}
                showTimeSelect
                timeFormat="HH:mm"
                dateFormat="dd/MM/yyyy HH:mm"
                onChange={changeDateEnd}
                locale={ptBR}
              />
            </div>
          </label>
        </div>

        <label className="input-label">Tipo<br />
          <select style={ ( requiredFields.idScheduleType === true ? {} : {border: "solid 2px #843434"} ) }
            value={modalFields.idScheduleType}
            onChange={(e) => setModalFiels({...modalFields,idScheduleType:e.target.value})}
          >
            <option value="">Selecione</option>
            { listTypes && listTypes.map( ( value ) => (
                <option key={value.idScheduleType} value={value.idScheduleType} >{value.description}</option>
            )) }
          </select>
        </label>

        <label className="input-label">Usuário<br />
          <select
            value={modalFields.idUser}
            onChange={(e) => setModalFiels({...modalFields,idUser:e.target.value})}
          >
            <option value="">Selecione</option>
            { listUsers && listUsers.map( ( value ) => (
                <option key={value.idUser} value={value.idUser} >{value.name}</option>
            )) }
          </select>
        </label>



        <FormControl className={classesMultiselect.formControl}>
          <label className="input-label">Grupos<br />
          <Select
            labelId="demo-mutiple-chip-label"
            id="demo-mutiple-chip"
            multiple
            value={ (modalFields.groups).length > 0 ? modalFields.groups : ['Todos']}
            onChange={(e) => {setModalFiels({...modalFields,groups:e.target.value}); setPersonName(e.target.value); }}
            input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <div className={classesMultiselect.chips}>
                { selected.length == 1 && selected[0] == 'Todos' ?
                  <Chip key={'Todos'} label={'Todos'} className={classesMultiselect.chip} /> : null
                }
                { listGroupList && listGroupList.map( ( value ) => (selected.includes(value.idGroups) ?
                  <Chip key={value.idGroups} label={value.title} className={classesMultiselect.chip} />
                : null )) }
              </div>
            )}
            MenuProps={MenuProps}
          >
            { listGroupList && listGroupList.map( ( value ) => (
              <MenuItem key={value.title} value={value.idGroups} style={getStyles(value.title, personName, theme)}>
                {value.title}
              </MenuItem>
            ))}
          </Select>
        </label>
      </FormControl>

      

      <label className="input-label">Url
        <TextField type="text" className="input" value={modalFields.url} onChange={(e) => setModalFiels({...modalFields,url:e.target.value})} />
      </label>
      <br />

        <label className="input-label">Descrição
        <textarea
          type="text"
          className="form-control"
          aria-describedby="content"
          rows="5"
          value={modalFields.description}
          onChange={(e) => setModalFiels({...modalFields,description:e.target.value})}
        />
        </label>

      </div>

      <button
        type="button"
        className="btn btn-secondary novoEvento"
        onClick={(e) => {
          if(modalFields.id){
            requiredFieldsValidate(modalFields,function(response){
              if( response ){
                updateEvent(modalFields);
                updateEventApi(modalFields);
                setOpen(false);
              }
            })
          }else{
            requiredFieldsValidate(modalFields,function(response){
              if( response ){
                setNewEvent(modalFields);
                setOpen(false);
              }
            })
          };
        }}
      >
        { modalFields.id ? null : <AddCircleIcon /> } {modalFields.id?'Salvar':'Adicionar'} Evento
      </button>

      { modalFields.id ?
      <button
        type="button"
        className="btn btn-primary removerEvento"
        onClick={(e) => {
          deleteEvent(modalFields.id);
          setOpen(false);
        }}
      >
        Excluir Evento
      </button>
      : null }

    </div>
  );



  return (
    <div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>

      <div className="header-title-wrapper">
        <div className="header-title">Gestão de Agenda</div>
      </div>
      <div align="right" style={{ padding: "10px 0px" }}>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={(e) => { requiredFieldsValidate(true); setModalFiels({...modalFields,id:'',title:'',url:'',description:'',idScheduleType:'',groups:[],idUser: '', start:new Date(),end:new Date(),}); setOpen(true); }}
        >
          <AddCircleIcon /> Novo Evento
        </button>
      </div>

      <DragAndDropCalendar

        eventPropGetter={(e) => eventStyleGetter(e) }

        messages={defaultMessages}

        localizer={localizer}
        events={state.events}
        showMultiDayTimes={true}

        startAccessor="start"
        endAccessor="end"
        popup={true}
        selectable
        onEventDrop={moveEvent}
        onDragStart={console.log}

        dragFromOutsideItem={
          state.displayDragItemInCell ? dragFromOutsideItem : null
        }
        onDropFromOutside={onDropFromOutside}
        handleDragStart={handleDragStart}

       onSelectEvent={ e =>{
        setModalFiels({
          id: e.id,
          title: ( e.title != '' && e.title != 'undefined' ? e.title : '' ),
          url: e.url,
          start: e.start,
          end: e.end,
          description: (e.description != "null" ? e.description  : ""),
          idScheduleType: ( e.idScheduleType != '' && e.idScheduleType != 'undefined' ? e.idScheduleType : '' ),
          groups: ( e.groups != '' && e.groups != 'undefined' ? e.groups : '' ),
          idUser: e.idUser
        });
        requiredFieldsValidate(e);
        setOpen(true);
       }}


        onSelectSlot={handleSelect}
        onEventResize={resizeEvent}

        tooltipAccessor={(e) => infoEvent(e) }
        titleAccessor={(e) => infoEvent(e) }

        style={{ height: 500 }}

      />

      {/* vai ser utilizado quando tiver controle de acesso e o usuário so poder visualizar
      <Calendar

        messages={defaultMessages}

        localizer={localizer}
        events={state.events}
        showMultiDayTimes={true}

        startAccessor="start"
        endAccessor="end"

        style={{ height: 500 }}

      /> */}

    </div>
  );
};

export default withRouter(Calendario);
