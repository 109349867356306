import React, { useState, useEffect } from "react";
import Table from './Table';
import ReportAcordeon from '../../../Components/ReportAcordeon'
import { 
  listUsersAccess
} from "../../../../../services/NexiDigitalAPI";
import Filtro from "./Filtro";
import moment from "moment";
import "moment/locale/pt-br.js";
import { FaCheckCircle, FaMinusCircle } from "react-icons/fa";


const UsersAccess = ({ index, expanded }) => {
    const name = "Lista de usuários com acesso de conteúdo"
    const description = "Filtra usuários pelo conteúdo acessado.";

    const [loading, setLoading] = useState(false);
    const [dados, setDados] = useState([]);

    const actionFilter = async (values) => {

      setLoading(true);
      // Sessions
      const filterValues = {
        startDate: values.startDate,
        endDate: values.endDate,
        idContentItem: values.idContentItem,
        idGroup: values.idGroup,
      }
      const responseData = await listUsersAccess(filterValues);

      if (responseData) {
        responseData.map((item, index) => {
          responseData[index].lastAccess = moment(item.lastAccess).format("DD/MM/YYYY")
        })
      }     
      const convertedIconData = responseData.map((response) => {
        return {
          ...response,
          completed: Boolean(response.completed) ? <FaCheckCircle style={{fontSize: 20, color: "green",}}/> : <FaMinusCircle  style={{fontSize: 20, color: "red",}}/>
        }
      });

      setDados(convertedIconData);
 
      setLoading(false);
  }

    
    const filter = (
      <Filtro 
          actionFilter={actionFilter} 
          loading={loading} 
      />
  );

    const result = (
        <Table 
            dados={ dados } 
            loading={ loading }
        />
    );
    
    return (
        <ReportAcordeon
            name={name}
            description={description}
            filter={filter}
            result={result}
            index={ index }
            expanded={expanded}
        />
        
    )
}

export default UsersAccess;