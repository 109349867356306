import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { Button, Badge, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { MdEdit } from "react-icons/md";
import StoreIcon from "@material-ui/icons/Store";
import { FaCheckCircle, FaMinusCircle } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import Loading from "../../components/Loading";
import FilterResults from "react-filter-search";
import {
  getCompanyByChannel,
  getMenuData,
  deactivateCompany,
  deleteCompany,
} from "../../services/NexiDigitalAPI";
import "./index.css";
import SnackComponent from "../../components/SnackComponent";
import { getMenuRoutes } from "../../utils/Utils";

export default function CompanyManager() {
  const history = useHistory();
  const goToEdit = useCallback(
    (id, idChannel) =>
      history.push(`/edit-company?id=${id}&idChannel=${idChannel}`),
    [history]
  );
  const [companies, setCompanies] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const [meuId, setMeuId] = useState("");
  const [ok, setOk] = useState("");
  const [showDesativa, setShowDesativa] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [userListCount, setUserListCount] = useState(10);
  const [snackInfo, setSnackInfo] = useState({
    message: "",
    severity: "success",
  });
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const { loginData } = useContext(AuthContext);
  const primaryColor = loginData.primaryColor;

  const handleCloseDesativa = () => setShowDesativa(false);
  const handleShowDesativa = () => setShowDesativa(true);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  const secondaryColor = localStorage.getItem("secondaryColor");

  const isMountedRef = useRef(null);

  /* [Permission Module] - Permissions states */
  const [permissionAddCompany, setPermissionAddCompany] = useState(false);
  const [permissionEditCompany, setPermissionEditCompany] = useState(true);
  const [permissionDeleteCompany, setPermissionDeleteCompany] = useState(false);
  const [permissionChangeCompanyStatus, setPermissionChangeCompanyStatus] =
    useState(false);

  /* [Permission Module] - Menu Options */
  async function getMenuOption() {
    const menuOptionList = getMenuRoutes();

    const objCurrentMenu = menuOptionList.filter(
      (menu) => menu.text === "Gestão de Canal" // Use the name of the menu-option that is coming to your module
    );

    if (Boolean(objCurrentMenu.length)) {
      const resources = objCurrentMenu.map((menu, index) => menu.resource);

      getPermissionsPage(resources);
    }
  }

  /* [Permission Module] - Permission attrs in page by login data information */
  function getPermissionsPage(resources) {
    try {
      if (
        Boolean(
          resources[0].filter((menu) => menu.name === "Inserir Empresa").length
        )
      )
        setPermissionAddCompany(true);
      if (
        Boolean(
          resources[0].filter((menu) => menu.name === "Editar Empresa").length
        )
      )
        setPermissionEditCompany(true);
      if (
        Boolean(
          resources[0].filter((menu) => menu.name === "Remover Empresa").length
        )
      )
        setPermissionDeleteCompany(true);
      if (
        Boolean(
          resources[0].filter((menu) => menu.name === "Desabilitar Empresa")
            .length
        )
      )
        setPermissionChangeCompanyStatus(true);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    /* [Permission Module] - Function calls */
    getMenuOption();

    isMountedRef.current = true;

    getCompanies();

    return () => (isMountedRef.current = false);
  }, []);

  function setErrorSnack() {
    setSnackInfo({
      message: "Erro ao salvar dados",
      severity: "error",
    });
    setOpenSnackBar(true);
  }

  function setSuccessSnack() {
    setSnackInfo({
      message: "Alterado com sucesso!",
      severity: "success",
    });
    setOpenSnackBar(true);
  }

  function _deactivateCompany() {
    const deactivateData = { cnpj: selectedCompany.cnpj };
    deactivateCompany(deactivateData)
      .then(({ data }) => {
        if (data.success) {
          setSuccessSnack();
        } else {
          console.log("Erro ao salvar dados", data);
        }
      })
      .catch((err) => {
        setErrorSnack();
      });
    handleCloseDesativa();
  }

  function _deleteCompany() {
    handleCloseDelete();
    deleteCompany(selectedCompany?.idCompany)
      .then(({ data }) => {
        if (data.success) {
          const companyIndex = companies?.findIndex(
            (item) => item.idCompany === selectedCompany?.idCompany
          );
          let newCompanies = [...companies];
          newCompanies.splice(companyIndex, 1);
          setCompanies(newCompanies);
          setSuccessSnack();
        } else {
          console.log("Erro ao salvar dados", data);
        }
      })
      .catch((err) => {
        setErrorSnack();
      });
  }

  function getCompanies() {
    getCompanyByChannel(getMenuData()?.idChannel)
      .then(({ data }) => {
        if (isMountedRef.current) {
          if (data.success) {
            setMeuId(loginData.id);
            setCompanies(data?.companies);
            setOk(data.success);
          }
        }
      })
      .catch((e) => console.log("Erro ao listar usuarios", e));
  }

  const editTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Editar empresa
    </Tooltip>
  );

  const desativarEmpresa = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Desativar empresa
    </Tooltip>
  );

  const deletarEmpresa = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Deletar empresa
    </Tooltip>
  );

  return (
    <div className="User" style={{ paddingBottom: 50 }}>
      <SnackComponent
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
        message={snackInfo.message}
        severity={snackInfo.severity}
      />
      <div className="header-title-wrapper">
        <div className="header-title">Cadastro de empresas</div>
      </div>
      <Modal show={showDesativa} onHide={handleCloseDesativa} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Desativar empresa?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza de que quer desativar a empresa?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDesativa}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={_deactivateCompany}>
            Desativar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDelete} onHide={handleCloseDelete} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Deletar empresa?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza de que quer deletar a empresa?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDelete}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={_deleteCompany}>
            Deletar
          </Button>
        </Modal.Footer>
      </Modal>

      <div>
        {ok ? (
          <>
            {permissionAddCompany && (
              <Link
                to="/add-company"
                className="company-manager-add-button"
                style={{ backgroundColor: primaryColor }}
              >
                <StoreIcon style={{ color: "white", fontSize: 22 }} />
                <div style={{ marginLeft: 10 }}>Adicionar Empresa</div>
              </Link>
            )}

            <div
              className="post-form"
              style={{ width: "100%", marginTop: "0.5rem" }}
            >
              <div className="form-group">
                <div className="card">
                  <div
                    className="card-header template-card-header"
                    style={{ backgroundColor: primaryColor }}
                  >
                    Procurar Empresa
                  </div>
                  <div className="card-body template-card-body">
                    <div className="input-container">
                      <input
                        placeholder="Procurar por nome, email ou cnpj (Digite pelo menos 3 caracteres)"
                        type="text"
                        className="form-control input-default"
                        id="groupDetail"
                        aria-describedby="usuario"
                        value={currentUser}
                        onChange={(e) => setCurrentUser(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Table size="lg" style={{ marginBottom: 0 }}>
              <Thead>
                <Tr>
                  <Th>NOME</Th>
                  <Th>EMAIL</Th>
                  <Th>CNPJ</Th>
                  <Th>TERMOS ACEITO</Th>
                  {(permissionEditCompany ||
                    permissionDeleteCompany ||
                    permissionChangeCompanyStatus) && <Th>AÇÕES</Th>}
                </Tr>
              </Thead>

              <Tbody>
                <FilterResults
                  value={currentUser.length >= 3 ? currentUser : ""}
                  data={
                    currentUser.length < 3
                      ? companies.slice(0, userListCount)
                      : companies
                  }
                  renderResults={(results) => (
                    <>
                      {results.map((item, index) => (
                        <Tr
                          key={item.idCompany}
                          style={{
                            backgroundColor:
                              index % 2 === 0
                                ? `${secondaryColor.trim()}22`
                                : `${secondaryColor.trim()}14`,
                          }}
                        >
                          <Td>{item.name}</Td>
                          <Td>{item.responsibleEmail}</Td>
                          <Td>{item.cnpj}</Td>
                          {item.companyLicenceTermsAccepted === 1 ? (
                            <Td>
                              <Badge variant="success">SIM</Badge>
                            </Td>
                          ) : (
                            <Td>
                              <Badge variant="secondary">NÃO</Badge>
                            </Td>
                          )}
                          {(permissionEditCompany ||
                            permissionDeleteCompany ||
                            permissionChangeCompanyStatus) && (
                            <Td style={{ textAlign: "center" }}>
                              {item.companyLicenceTermsAccepted === 0 && (
                                <>
                                  {permissionEditCompany && (
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 0, hide: 0 }}
                                      overlay={editTooltip}
                                    >
                                      <MdEdit
                                        onClick={() =>
                                          goToEdit(
                                            item.idCompany,
                                            item.idChannel
                                          )
                                        }
                                        style={{
                                          cursor: "pointer",
                                          fontSize: 22,
                                        }}
                                      />
                                    </OverlayTrigger>
                                  )}

                                  {permissionDeleteCompany && (
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 0, hide: 0 }}
                                      overlay={deletarEmpresa}
                                    >
                                      <DeleteForeverIcon
                                        onClick={() => {
                                          setSelectedCompany(item);
                                          handleShowDelete();
                                        }}
                                        style={{
                                          cursor: "pointer",
                                          fontSize: 25,
                                          color: "red",
                                          marginLeft: 3,
                                        }}
                                      />
                                    </OverlayTrigger>
                                  )}
                                </>
                              )}

                              {permissionChangeCompanyStatus && (
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 0, hide: 0 }}
                                  overlay={desativarEmpresa}
                                >
                                  <FaMinusCircle
                                    onClick={() => {
                                      setSelectedCompany(item);
                                      handleShowDesativa();
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      fontSize: 20,
                                      color: "gray",
                                      marginLeft: 3,
                                    }}
                                  />
                                </OverlayTrigger>
                              )}
                            </Td>
                          )}
                        </Tr>
                      ))}
                    </>
                  )}
                />
              </Tbody>
            </Table>
            {currentUser.length < 3 && companies?.length > 10 && (
              <div
                className="user-show-more"
                onClick={() => setUserListCount(userListCount + 10)}
              >
                Mostrar mais...
              </div>
            )}
          </>
        ) : (
          <Loading center>Carregando empresas</Loading>
        )}
      </div>
    </div>
  );
}
