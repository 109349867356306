import React, { useState, useEffect } from "react";
import { appDetails } from "../services/NexiDigitalAPI";
import "./Home.css";
import LoginForm from "./LoginForm";
import AtivaCadastro from "./AtivaCadastro";
import { colors } from "../utils/Colors";

export default function Home() {
  const urlToken = window.location.search.slice(7);

  const [ativarCadastro, setAtivarCadastro] = useState(false);
  const [appDetailsData, setAppDetailsData] = useState({});
  const [loadingAppError, setLoadingAppError] = useState(false);
  const [loadingApp, setLoadingApp] = useState(true);

  function _appDetails() {
    setLoadingAppError(false);
    
    const bucketUrl = window.location.host;
    const staticUrl = 'homologadmin.bedigital.net.br';
    // homologadmin.bedigital.net.br
    const currentUrl = process.env.NODE_ENV === 'development' ? staticUrl : bucketUrl;

    let favicon = document.getElementById("favicon");
    appDetails(currentUrl)
      .then((res) => {
        if (res.data.app.length == 0) {
          setLoadingAppError(true);
          return;
        }
        localStorage.setItem("appDetails", JSON.stringify(res.data.app[0]));
        document.title = res.data.app[0].name;
        favicon.href = res.data.app[0].favIcon;
        setAppDetailsData(res.data.app[0]);
      })
      .catch((err) => {
        setLoadingAppError(true);
      })
      .finally(() => {
        setLoadingApp(false);
      });
  }

  useEffect(() => {
    _appDetails();
  }, []);

  return (
    <div
      className="Home"
      style={{
        backgroundColor: colors.blueSmooth,
        backgroundImage: `url(${appDetailsData?.loginImage})`,
      }}
    >
      <div className="home-container">
        {loadingApp ? (
          <div className="tela-login-loading">
            <div className="loader-container">
              <div
                style={{
                  borderTopColor: "blueviolet",
                  position: "initial",
                  margin: "initial",
                }}
                className="loader loader-center loader-big"
              ></div>
              <span
                style={{
                  marginTop: 10,
                  color: "rgba(0,0,0,0.7)",
                }}
              >
                Carregando Aplicação...
              </span>
            </div>
          </div>
        ) : (
          <>
            {loadingAppError ? (
              <div className="tela-login-loading bucket-error">
                <span>Nenhuma aplicação correspondente a URL</span>
              </div>
            ) : (
              <>
                <div
                  className="home-left-panel"
                  style={{ backgroundColor: appDetailsData?.primaryColor }}
                >
                  <div className="home-left-text">
                    <span style={{ color: colors.blueSmooth }}>
                      Painel de controle / {appDetailsData?.name}
                    </span>
                  </div>
                  <div>
                    <img
                      src={appDetailsData?.logo}
                      style={{ height: 100 }}
                      className="home-logo"
                    />
                  </div>
                </div>
                <div className="home-right-panel">
                  {ativarCadastro ? (
                    <AtivaCadastro
                      ativarCadastro={() => setAtivarCadastro(false)}
                      primaryColor={appDetailsData?.primaryColor}
                    />
                  ) : (
                    <LoginForm
                      urlToken={urlToken}
                      ativarCadastro={() => setAtivarCadastro(true)}
                      appDetailsData={appDetailsData}
                    />
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
