import React, { useState, useEffect, useContext, useRef } from "react";
import { AuthContext } from "../../context/AuthContext";
import { getSectionTypeList, saveSection, uploadImageApi, getSectionsStructureList } from '../../services/NexiDigitalAPI'
import Loading from "../../components/Loading";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CloudUpload from '@material-ui/icons/CloudUpload';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from "@material-ui/core/CircularProgress";
import ColorPicker from 'material-ui-color-picker'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import { RiSave3Line } from 'react-icons/ri';
import SnackComponent from "../../components/SnackComponent";
import { colors } from '../../utils/Colors';
import SortableTree from "react-sortable-tree";

function SectionCreate({ refreshSectionList }) {
    const defaultFormData = {
        name: '',
        ordering: 1,
        templateSection: 1,
        limitSearch: 0,
        context: 'app',
        idMenuItem: undefined,
        imageMenuItem: null,
        imageStyleMenuItem: 'tile',
        colorMenuItem: '#2b00ff',
    }
    const inputRef = useRef(null);
    const { loginData } = useContext(AuthContext)
    const { primaryColor, secondaryColor, thirdColor, fourthColor } = loginData
    const [sectionTypes, setSectionTypes] = useState(null)
    const [showDialog, setShowDialog] = useState(false)
    const [loadingSaveSection, setLoadingSaveSection] = useState(false)
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackInfo, setSnackInfo] = useState({ message: '', severity: 'success' });
    const [selectedSection, setSelectedSection] = useState({})
    const [formData, setFormData] = useState(defaultFormData)
    const [saveError, setSaveError] = useState(false)
    const [selectedMenuType, setSelectedMenuType] = useState(null)
    const [autoCompleteKey, setAutoCompleteKey] = useState(Math.random())
    const [loadingImageUpload, setLoadingImageUpload] = useState(false)
    const [loadingSecondaryImageUpload, setloadingSecondaryImageUpload] = useState(false)
    const [showConfirmCloseDialog, setShowConfirmCloseDialog] = useState(false)
    const appDetails = JSON.parse(localStorage.getItem('appDetails')) || {}
    const [loadingMenuTree, setLoadingMenuTree] = useState(true);
    const [sectionTreeList, setSectionTreeList] = useState([]);
    const [selectedNodeId, setSelectedNodeId] = useState(null);
    const [menuTreeError, setMenuTreeError] = useState(false);

    function getSectionTypes() {
        getSectionTypeList().then(res => {
            if (res.data.success == false) {
                console.log('Erro na chamada de API.')
                return
            }
            setSectionTypes(res.data.sectionType)
        }).catch(err => {
            console.log('Erro na chamada de API.', err)
        })
    }

    function showForm(section) {
        setShowDialog(true)
        setFormData({
            ...defaultFormData,
            idSectionType: section.idSectionType
        })
        setSelectedSection(section)
    }

    function setSnackError(addiotionalErrorMessage = '') {
        setOpenSnackBar(true)
        setSnackInfo({ message: `Erro ao salvar seção. ${addiotionalErrorMessage}`, severity: 'error' })
    }

    function saveForm() {
        setSaveError(false)
        setMenuTreeError(false)
        if (formData.name?.length <= 2) {
            setSaveError(true)
            return
        }
        if (selectedNodeId === null) {
            setMenuTreeError(true)
            return
        }
        setLoadingSaveSection(true)
        saveSection(formData).then(res => {
            if (res?.data?.success === false) {
                setSnackError()
                return
            }
            setShowDialog(false)
            setOpenSnackBar(true)
            setSnackInfo({ message: 'Seção salva com sucesso!', severity: 'success' })
            refreshSectionList({
                ...formData,
                sectionTypeName: selectedSection.name,
                format: selectedSection.format,
                idSection: res?.data?.idSection?.[0]
            })
            getSectionsList()
            setSelectedNodeId(null)
        }).catch(err => {
            console.log('err', err.message)
            setSnackError('Erro ao salvar dados.')
        }).finally(() => {
            setLoadingSaveSection(false)
        })
    }

    const uploadFile = (file, secondary = false) => {
      const filename = `${appDetails.name}-${secondary ? 'secondary' : 'menuItem'}-${Date.now()}`
      let selectedFile = file.files[0]

      const data = new FormData(); // If file selected
      if (selectedFile) {
          if (secondary) {
            setloadingSecondaryImageUpload(true)
          } else {
            setLoadingImageUpload(true)
          }
          data.append("fileName", filename)
          data.append("fileData", selectedFile)

          uploadImageApi(data)
              .then((response) => {
                  if (response.data.error) {
                      setSnackInfo({
                          message: 'Erro ao subir imagem.',
                          severity: 'error'
                      })
                      setOpenSnackBar(true)
                  } else {
                    if (secondary) {
                      setFormData({...formData, imageSecondary: response?.data?.url})
                    } else {
                      setFormData({...formData, imageMenuItem: response?.data?.url})
                    }
                  }
              })
              .catch(() => {
                  setSnackInfo({
                      message: 'Erro ao subir imagem.',
                      severity: 'error'
                  })
                  setOpenSnackBar(true)
              }).finally(() => {
                if (secondary) {
                  setloadingSecondaryImageUpload(false)
                } else {
                  setLoadingImageUpload(false)
                }
              })
      }
  };

  const confirmClose = () => {
    setShowConfirmCloseDialog(false)
    setShowDialog(false)
  }
  
  const transformArray = (sectionListArray) => {
    if (!Boolean(sectionListArray)) {
        return [];
    }
    sectionListArray.forEach((item) => {
        item.title = item.text;
        item.expanded = true
        if (item.children?.length > 0) {
            item.children = transformArray(item.children);
        }
    });

    return sectionListArray;
  }

  const getSectionsList = async () => {
    setLoadingMenuTree(true);
    try {
      const res = await getSectionsStructureList(appDetails?.idAppType);
      let result = res?.result?.appStructure.filter((item) => item.active);
      setSectionTreeList(transformArray(result));
    } catch (err) {
      console.log("Erro na chamada de API.", err);
    } finally {
      setLoadingMenuTree(false);  
    }
  };

  const nodeClicked = (event, rowInfo) => {
    if (event.target.className.includes('collapseButton') ||
        event.target.className.includes('expandButton')) {
        // ignore the event
    } else {
        setSelectedNodeId(rowInfo.node?.sectionTo);
        setFormData({ 
          ...formData, 
          idMenuItem: rowInfo.node?.idMenu
        })
        setSelectedMenuType(rowInfo.node?.idMenu)
    }
};

  const GreenButton = withStyles((theme) => ({
      root: {
          color: 'white',
          backgroundColor: '#29CC61',
          '&:hover': {
              backgroundColor: '#29CC57',
          },
      },
  }))(Button);

  useEffect(() => {
      getSectionTypes()
      getSectionsList()
  }, [])

  useEffect(() => {
      if (showDialog) {
          inputRef.current.focus();
      }
  }, [showDialog]);

  return (
      <div className="content-manager-container">
          <SnackComponent
              openSnackBar={openSnackBar}
              setOpenSnackBar={setOpenSnackBar}
              message={snackInfo.message}
              severity={snackInfo.severity}
          />
          <div className="section-manager-content-container">
              <div className="section-manager-content-title">
                  Selecione o tipo da seção
              </div>
              <div className="section-manager-content-list">
                  {
                      sectionTypes
                          ? sectionTypes.map((item, index) => {
                              return (
                                  <div
                                      className="section-manager-content-item"
                                      key={index}
                                      style={{ backgroundColor: item.color }}
                                      onClick={() => showForm(item)}
                                  >
                                      <img src={item.icon} style={{ height: 50 }} />
                                      <div className="section-manager-content-item-text">
                                          {item.name}
                                      </div>
                                      <div className="section-manager-content-item-type">
                                          {item.isMenuTemplate === 1 ? 'Menu' : 'Seção' } ({item.idSectionType})
                                      </div>
                                  </div>
                              )
                          })
                          : <Loading> Carregando... </Loading>
                  }
              </div>

              <Dialog
                open={showConfirmCloseDialog}
                onClose={() => setShowConfirmCloseDialog(false)}
                aria-labelledby="alert-dialog-title-close"
                aria-describedby="alert-dialog-description-close"
                contentStyle={{width: "100%", maxWidth: "none"}}
              >
                <DialogTitle id="alert-dialog-title-close">
                  Deseja fechar sem salvar?
                </DialogTitle>
                <DialogActions style={{ justifyContent: 'center', marginBottom: 10 }}>
                  <Button 
                    style={{ backgroundColor: colors.gray, color: 'white' }} 
                    onClick={() => setShowConfirmCloseDialog(false)}
                  >
                    Cancelar
                  </Button>
                  <Button 
                    style={{ backgroundColor: colors.red, color: 'white' }} 
                    onClick={confirmClose} 
                    autoFocus
                  >
                    Fechar sem salvar
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                  open={showDialog}
                  className="section-create-modal-container"
                  style={{ overflow: 'inherit' }}
                  keepMounted
                  onClose={() => setShowDialog(false)}
                  onBackdropClick={() => setShowConfirmCloseDialog(true)}
                  disableBackdropClick
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
              >
                  {/* <DialogTitle style={{ textAlign: 'center' }} id="alert-dialog-slide-title"> Detalhes da seção </DialogTitle> */}
                  <DialogContent>
                      <div className="section-create-close" onClick={() => setShowConfirmCloseDialog(true)}>
                        <CloseIcon style={{ fontSize: 20, color: 'gray' }} />
                      </div>
                      <div className="section-create-container">
                          <div className="section-create-title" style={{ backgroundColor: selectedSection.color }}>
                              <img src={selectedSection.icon} style={{ height: 30, marginRight: 10 }} />
                              {selectedSection.name}
                          </div>
                          <div className="section-create-form-container">
                            <div className="section-create-form">
                            <TextField
                                  inputRef={inputRef}
                                  style={{ flex: 1, marginBottom: '15px !important' }}
                                  id="outlined-basic-name"
                                  label="Nome da seção"
                                  variant="outlined"
                                  value={formData.name}
                                  onChange={(e) => setFormData({ ...formData, name: e.target.value, textMenuItem: e.target.value })}
                              />

                              <FormControl variant="outlined" style={{ flex: 1, marginBottom: 15 }}>
                                  <TextField
                                      id="outlined-number"
                                      label="Ordem do menu"
                                      type="number"
                                      InputLabelProps={{
                                          shrink: true,
                                      }}
                                      value={formData.ordering}
                                      variant="outlined"
                                      onChange={(e) => e.target.value >= 1 ? setFormData({ ...formData, ordering: e.target.value }) : null}
                                  />
                              </FormControl>

                              {
                                !selectedSection.isMenuTemplate &&
                                <FormControl variant="outlined" style={{ marginBottom: 15 }}>
                                    <InputLabel id="label">Permite paginação de conteúdo?</InputLabel>
                                    <Select
                                        labelId="select-limitSearch-label"
                                        id="select-limitSearch-outlined"
                                        defaultValue={0}
                                        value={formData.limitSearch}
                                        label="Permite paginação de conteúdo?"
                                        onChange={(e) => setFormData({ ...formData, limitSearch: e.target.value })}
                                    >
                                        <MenuItem value={1}>Sim</MenuItem>
                                        <MenuItem value={0}>Não</MenuItem>
                                    </Select>
                                </FormControl>
                              }

                              {
                                !selectedSection.isMenuTemplate &&
                                  <FormControl variant="outlined">
                                    <InputLabel  id="image-type">Tipo de imagem</InputLabel>
                                    <Select
                                      labelId="label-image-type"
                                      id="select-image-type"
                                      value={formData.imageStyleMenuItem}
                                      onChange={(e) => setFormData({ ...formData, imageStyleMenuItem: e.target.value })}
                                      label="Tipo de imagem"
                                    >
                                      <MenuItem value={'tile'}>Tile</MenuItem>
                                      <MenuItem value={'icon'}>Icon</MenuItem>
                                      <MenuItem value={'hybrid'}>Hybrid</MenuItem>
                                    </Select>
                                  </FormControl>
                              }

                              <div className="section-create-image-container">
                                <span className="section-create-image-text">Imagem do menu</span>
                                {
                                    loadingImageUpload
                                        ? (
                                            <div style={{ paddingTop: 15, paddingBottom: 15 }}>
                                                <CircularProgress size={70} />
                                            </div>
                                        )
                                        : formData.imageMenuItem ? (
                                            <img
                                                style={{ height: 100, marginBottom: 10 }}
                                                src={formData.imageMenuItem}
                                                alt="menu-icon"
                                            />
                                        ) : (
                                          <WallpaperIcon style={{ fontSize: 100, color: 'lightgray', marginBottom: 10 }} />
                                        )
                                }
                                <Button
                                  variant="contained" 
                                  color="primary" 
                                  component="label"
                                >
                                    <input
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        hidden
                                        onClick={e => e.target.value = null}
                                        onChange={(e) => uploadFile(e.target)}
                                    />
                                    <CloudUpload style={{ marginRight: 10 }} />
                                    Carregar imagem do menu
                                </Button>
                              </div>

                              {
                                formData.imageStyleMenuItem === "hybrid" &&
                                <div className="section-create-image-container">
                                <span className="section-create-image-text">Imagem Secundaria</span>
                                {
                                    loadingSecondaryImageUpload
                                        ? (
                                            <div style={{ paddingTop: 15, paddingBottom: 15 }}>
                                                <CircularProgress size={70} />
                                            </div>
                                        )
                                        : formData.imageSecondary ? (
                                            <img
                                                style={{ height: 100, marginBottom: 10 }}
                                                src={formData.imageSecondary}
                                                alt="menu-icon"
                                            />
                                        ) : (
                                          <WallpaperIcon style={{ fontSize: 100, color: 'lightgray', marginBottom: 10 }} />
                                        )
                                }
                                <Button
                                  variant="contained" 
                                  color="primary" 
                                  component="label"
                                >
                                    <input
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        hidden
                                        onChange={(e) => uploadFile(e.target, true)}
                                    />
                                    <CloudUpload style={{ marginRight: 10 }} />
                                    Carregar imagem secundaria
                                </Button>
                              </div>
                              }
                              
                              {
                                selectedMenuType !== 1 &&
                                  <div 
                                    className="section-create-color-picker"
                                    style={{ display: 'flex', alignItems: 'center' }}>
                                      <div className="content-managet-color-picker" style={{ backgroundColor: formData.colorMenuItem }} />
                                      <span style={{ marginRight: 10, width: 150 }}>Cor do menu</span>
                                      <ColorPicker
                                          autoComplete="off"
                                          className="content-manager-color-picker-input"
                                          style={{ backgroundColor: 'rgba(0,0,0,0.1)', borderRadius: 10 }}
                                          name='color'
                                          value={formData.colorMenuItem}
                                          TextFieldProps={{ value: formData.colorMenuItem }}
                                          onChange={(color) => setFormData({ ...formData, colorMenuItem: color })}
                                      />
                                  </div>
                              }
                            </div>
                            <div className="section-create-tree-container">
                              <span className="section-create-image-text">
                                Selecione o menu a ser atrelado { selectedNodeId }
                              </span>
                              {
                                loadingMenuTree ? (
                                        <Loading center> Carregando lista de menu... </Loading>
                                      ) : (
                                        <SortableTree
                                          treeData={sectionTreeList}
                                          canDrag={false}
                                          onChange={(treeData) => setSectionTreeList(treeData)}
                                          generateNodeProps={rowInfo => {
                                            let nodeProps =  { onClick: event => nodeClicked(event, rowInfo) };
                                            if (selectedNodeId === rowInfo.node.sectionTo) {
                                                nodeProps.className = 'tree-item-selected';
                                            }
                                            return nodeProps;
                                        }}
                                        />
                                      )
                              }
                            </div>
                              
                        </div>
                        <GreenButton 
                          onClick={() => saveForm()} 
                          style={{ marginBottom: 15, width: '100%', marginTop: 25 }} 
                          variant="contained" 
                          color="primary">
                            <div>
                                <RiSave3Line size={22} style={{ marginBottom: 3, marginRight: 10 }} />
                                {loadingSaveSection ? 'Salvando nova seção...' : 'Adicionar nova seção'}
                            </div>
                        </GreenButton>
                        {saveError && <span className="section-create-error">O nome deve conter pelo menos 3 caracteres.</span>}
                        {menuTreeError && <span className="section-create-error">Selecione um menu a ser atrelado.</span>}
                    
                      </div>
                  </DialogContent>
              </Dialog>
            </div>
        </div>
    );
}

export default SectionCreate
