import React, { useState} from "react";
import Filtro from './Filtro';
import Table from './Table';
import ReportAcordeon from '../../../Components/ReportAcordeon'
import { 
    getListCampaigns,listcampaignpointssummary
} from "../../../../../services/NexiDigitalAPI";


const RankingUser = ({ index }) => {
    const [loading, setLoading] = useState(false);
    const [dados, setDados] = useState([]);      
    const name = "Ranking campanha"
    const description = `Listagem do ranking da campanha`;

    const actionFilter = async (data) => {
        setLoading(true);
        listcampaignpointssummary(data.idCampanha)
        .then((res) => {
           
            setDados(res);          
            setLoading(false);
        })
        .catch((err) => {
            setDados([]);
        });
        //setDadosFilter(responseData);   
        setLoading(false);
    }

    const filter = (
        <Filtro 
            actionFilter={actionFilter} 
            loading={loading} 
        />
    );

    const result = (
        <Table 
            dados={ dados } 
            loading={ loading }
        />
    );
    
    return (
        <ReportAcordeon
            name={name}
            description={description}
            filter={filter}
            result={result}
            index={ index }
        />
        
    )
}

export default RankingUser;