import React, { useState, useEffect } from 'react'
import styles from './styles.module.scss';
import {
    getProductList,
    listPurchaseDetail
  } from "../../../../../services/NexiDigitalAPI";
import moment from "moment";
import InputWithLabel from '../../../../../components/InputWithLabel';
import StandartDataTable from '../../../../../components/StandartDataTable';

const Modal = ({ show, hideModal, info, submitOk }) => {

    const [detail, setDetail] = useState([])
    const [loading, setLoading] = useState(false);

    const showInfo = show ? {
        idPurchase: info.idPurchase,
        totalPrice: info.totalPrice.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}),
        purchaseDate: moment(info.purchaseDate).format("DD/MM/YYYY"),
        userName: info.userName,
    } : {
        idPurchase: '',
        totalPrice: '',
        purchaseDate: '',
        userName: '',
    }

    const columns = [
        { name: "Produto", selector: "productTitle", sortable: true, },
        { name: "Preço", selector: "price", sortable: true, },
    ]

    const submitForm = () => {
        submitOk(showInfo.idPurchase)
    }


    const opening = async () => {
        setLoading(true)
        const detailApi = await listPurchaseDetail(info.idPurchase)
        setDetail(detailApi);
        setLoading(false)
    }

    useEffect(  () => {
        if(show) {
            opening()
        }else{

        }
    },[show])

    const colorStyle = {backgroundColor: localStorage.getItem("secondaryColor")}

    const modalHtml = (
        <div className={styles.modalWrapper}>
            <div className={styles.modalTitle} style={colorStyle}>
                Estornar Venda
            </div>
            <div className={styles.modalFormWrapper}>
                Deseja realmente cancelar venda? <br/>
                *Ao estornar venda, não será possível retorná-la.
                <div style={{display:'flex'}}>
                    <div style={{padding:'10px 20px'}}> <InputWithLabel Label="ID" Value={showInfo.idPurchase} /> </div>
                    <div style={{padding:'10px 20px'}}> <InputWithLabel Label="Valor" Value={showInfo.totalPrice} /> </div>
                    <div style={{padding:'10px 20px'}}> <InputWithLabel Label="Data" Value={showInfo.purchaseDate} /> </div>
                </div>
                <div  style={{padding:'10px 20px'}}> <InputWithLabel Label="Usuário" Value={showInfo.userName} /> </div>
                <div  style={{padding:'10px 20px'}}>
                    <label htmlFor="cadastroProdutoModalTitulo"  className={styles.modalFormLabel}>
                        Produtos
                    </label>
                    <div className={styles.productTableContent}>
                        <StandartDataTable
                            columns={columns}
                            data={detail}
                            loading={ loading }
                        />
                    </div>
                </div>
                
                
                
            </div>
            <div className={styles.modalButtonWrapper}>
                <button onClick={submitForm} className="btn btn-primary" style={colorStyle}>
                    Confirmar
                </button>
                <button onClick={hideModal} className="btn btn-primary" style={colorStyle}>
                    Cancelar
                </button>
            </div>
        </div>
    )

    return show && modalHtml;
}

export default Modal;