import React, { useState, useEffect, useContext, useCallback } from "react";
import { Col, Form, ListGroup, Modal } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { AuthContext } from "../../../context/AuthContext";
import { getUsersApi } from "../../../services/NexiDigitalAPI";
import Select from "react-select";
import { useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/pt-br";
import {
  listcampaignpointssummary,getListCampaignsDetail
} from "../../../services/NexiDigitalAPI";
import Row from "../Components/Row"
const ReportGeneral = () => {
  const { loginData } = useContext(AuthContext);
  const primaryColor = loginData?.primaryColor;
  const secondaryColor = loginData?.secondaryColor;
  const [show, setShow] = useState(false);
  const [values, setValues] = useState([]);
  const { id } = useParams();
  const initialSelected = {
    value: 0,
    label: "",
  };

  const [userList, setUserList] = useState([]);
  const [selected, setSelected] = useState(initialSelected);
  const [options, setOptions] = useState([]);
  const [report, setReport] = useState([]);
  const [campanha, setCampanha] = useState('');
  const [startDate, setStartDate] = useState([]);
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [nameUser, setNameUser] = useState('')
  const [open, setOpen] = useState(false)
  let sum = 0;
  const checkEqual = (elem1, elem2) => {
    return elem1.sort().join(",") === elem2.sort().join(",");
  };
  async function startUsers() {
    const usersApi = await getUsersApi();
    setUserList(usersApi);
    const treatedOptions = usersApi.map((item) => {
      return { value: item.idUser, label: item.name };
    });
    setOptions(treatedOptions);
  }
  const findUser = (idUser) => {
    const first = userList.filter((obj) => obj.idUser === idUser);
    return first;
  };
  const changeUser = async (user) => {
    setSelected(user);
    const userInfo = await findUser(user.value);
    const hasUserInfo = Boolean(userInfo.length);
    if (hasUserInfo) {
      setValues(userInfo[0].idUser);
    }
  };
  useEffect(() => {
    if (id) {
      setLoading(true)
      listcampaignpointssummary(id)
        .then(async (res) => {
          setReport(res)
          setLoading(false)
        }).catch((err)=> {
          setReport([])
          setLoading(false)
        })
      

  }
  },[id])
  useEffect(() => {
    startUsers();
  }, []);
  useEffect(() => {
    if (id) {
      setLoading(true)

      getListCampaignsDetail(id)
        .then( (res) => {
          setCampanha(res.campaign[0].name);
          setEndDate(moment(res.campaign[0].endDate).format("DD/MM/YYYY"));
          setStartDate(moment(res.campaign[0].startDate).format("DD/MM/YYYY"));
          setLoading(false)
        })
    }
  },[id])
  return (
    <div className="Reports">     
      <div
        className="report-filter-container"
        style={{
          border: "1px solid lightgray",
          overflow: "inherit",
        }}
      >
        <div style={{ marginTop: 30, marginBottom: 30 }}>
          <Col xs="8">
            <Form.Label>Usuário</Form.Label>
            <Col xs={8} sm={8} style={{ paddingLeft: 0 }}>
                <input
                  type="text"
                  className="form-control"
                  value={nameUser}
                  onChange={(e) => {
                    setOpen(false);
                    setNameUser(e.target.value);
                  }}
                  placeholder={"Buscar usuário"}
                />
              </Col>
          </Col>
        </div>
      </div>
     
        
          <div
            className="report-filter-container"
            style={{
              border: "1px solid lightgray",
              overflow: "inherit",
              marginBottom: 30 
            }}
          >
        {loading ? (
          <div className="div-loading">
            <>
              {!error ? (
                <div className="loader loader-big" />
              ) : (
                <div>Tivemos uma falha ao carregar a tabela.</div>
              )}
            </>
          </div>
        ) : (
          <> {
            report.filter((elem) => elem.name.toLowerCase().indexOf(nameUser.toLowerCase()) > -1).length ? (
              <>
            
                <div style={{ margin: "30px 0px" }}>
                  <div className="text-left" style={{ color: "#403f3f" }}>
                    {campanha}
                  </div>
                  <div
                    className="text-left-subtitle"
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <div style={{ color: "#403f3f" }}>
                      <span style={{ marginRight: "10px" }}>{`Início ${startDate}`}</span>
                    </div>
                    <div style={{ color: "#403f3f" }}>
                      <span>{`Término ${endDate}`}</span>
                    </div>
                  </div>
                </div>
                <Table>
                  <Thead>
                    <Tr>
                      <Th style={{width:"20px"}}>Detalhes</Th>
                      <Th>Usuário</Th>
                      <Th style={{width:"20%"}}>Pontuação</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <>
                      {report.filter(item => item.name.toLowerCase().indexOf(nameUser.toLowerCase()) > -1 ).map( (item, index) => {
                        sum +=parseInt(item.points);
                      return   (<Row id={id}  backgroundRow={`${secondaryColor.trim()}22`} item={item}/> )               
                      })}
                    </>
                  </Tbody>
                </Table>
                <div className="score" style={{backgroundColor:`${secondaryColor}`}}>
                  <div>Total de pontos</div>
                  <div>{sum}</div>
                </div>
              </>
            ) : (
             <div
                style={{
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "#fff",
                }}
              >
                <span>{`Nenhuma usuário ${
                  nameUser ? "encontrado" : "participando no momento"
                }`}</span>
            </div>
            ) 
           
          }
          </>
        )}

      </div>
    </div>
  );
};

export default ReportGeneral;
