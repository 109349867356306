import React, { useEffect, useState } from 'react'
import { getSectionDesignList, saveDesignParameter } from "../../services/NexiDigitalAPI";
import CircularProgress from "@material-ui/core/CircularProgress";
import { colors } from '../../utils/Colors';

const JornadinhaColors = ({ idSection }) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [menuBackground, setMenuBackground] = useState({})
  const [menuBorder, setMenuBorder] = useState({})
  const [menuColumn, setMenuColumn] = useState({})
  const [menuText, setmenuText] = useState({})
  const [colorLoading, setColorLoading] = useState(false)
  const [saveSuccess, setSaveSuccess] = useState(null)

  const transformIntoGroups = (data) => {
    return data.reduce(function (r, a) {
      r[a.parameterGroup] = r[a.parameterGroup] || [];
      r[a.parameterGroup].push(a);
      return r;
    }, Object.create(null));
  };

  const _getSectionDesignList = () => {
    setLoading(true);
    setError(false);
    getSectionDesignList(Number(idSection))
      .then((res) => {
        if (res.data.success === true) {
          const transformedResponse = transformIntoGroups(res.data.sectionDesign)
          const menuBackgroundResponse = transformedResponse.general[transformedResponse.general.findIndex(a => a.parameter === "backgroundColor")]
          const menuBorderResponse = transformedResponse.firstItemText[transformedResponse.firstItemText.findIndex(a => a.parameter === "color")]
          const menuBorderColumnResponse = transformedResponse.general[transformedResponse.general.findIndex(a => a.parameter === "columnColor")]
          const menuTextResponse = transformedResponse.itemText[transformedResponse.itemText.findIndex(a => a.parameter === "color")]
          setMenuBackground(menuBackgroundResponse)
          setMenuBorder(menuBorderResponse)
          setMenuColumn(menuBorderColumnResponse)
          setmenuText(menuTextResponse)
        } else {
          console.log("Error", res);
          setError(true)
        }
      })
      .catch((err) => {
        console.log('err', err)
        setError(true)
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  const _saveDesignParameter = (bodyParams) => {
    setSaveSuccess(null)
    saveDesignParameter(bodyParams).then((res) => {
      if (res.data.success === true) {
        setSaveSuccess(true)
      } else {
        setSaveSuccess(false)
        console.log("Erro ao gravar dados!");
      }
    })
    .catch((err) => {
      setSaveSuccess(false)
      console.log('err', err)
    }).finally(() => {
      setColorLoading(false)
    })
  }

  const saveColor = (
    { target: { value } },
    parameterGroup,
    parameter
  ) => {

  const saveParams = {
    idSection: idSection,
    parameterGroup,
    parameter,
    value: value
  }
  
  setColorLoading(true)
  _saveDesignParameter(saveParams)
}

  useEffect(() => {
    _getSectionDesignList();
  }, []);

  return (
    loading ? (
      <div className="impact-colors-loading">
        {<CircularProgress style={{ marginRight: 10 }} />}
        Carregando cores... 
      </div>
    ) : (
      error ? <div>Erro ao carregar cores</div> :
      <div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
              <div className="content-managet-color-picker" style={{ backgroundColor: menuBackground?.value }} />
              Fundo do menu
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              id="titulo"
              aria-describedby="titulo"
              value={menuBackground?.value}
              onBlur={e => saveColor(e, "general", "backgroundColor")}
              onKeyDown={input => { if (input.key === 'Enter') { input.target.blur() } }}
              onChange={e => setMenuBackground({...menuBackground, value: e.target.value})}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
              <div className="content-managet-color-picker" style={{ backgroundColor: menuBorder?.value }} />
              Titulo e borda
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              id="titulo"
              aria-describedby="titulo"
              value={menuBorder?.value}
              onBlur={e => saveColor(e, "firstItemText", "color")}
              onKeyDown={input => { if (input.key === 'Enter') { input.target.blur() } }}
              onChange={e => setMenuBorder({...menuBorder, value: e.target.value})}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
              <div className="content-managet-color-picker" style={{ backgroundColor: menuColumn?.value }} />
              Coluna
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              id="titulo"
              aria-describedby="titulo"
              value={menuColumn?.value}
              onBlur={e => saveColor(e, "general", "columnColor")}
              onKeyDown={input => { if (input.key === 'Enter') { input.target.blur() } }}
              onChange={e => setMenuColumn({...menuColumn, value: e.target.value})}
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
              <div className="content-managet-color-picker" style={{ backgroundColor: menuText?.value }} />
              Texto
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              id="titulo"
              aria-describedby="titulo"
              value={menuText?.value}
              onBlur={e => saveColor(e, "itemText", "color")}
              onKeyDown={input => { if (input.key === 'Enter') { input.target.blur() } }}
              onChange={e => setmenuText({...menuText, value: e.target.value})}
            />
          </div>
          { colorLoading && <span>Salvando... {<CircularProgress style={{ marginLeft: 5 }} size={15} />}</span> }
          {saveSuccess === true && <span style={{ color: colors.green }}>Cor salva com sucesso</span>}
          {saveSuccess === false && <span style={{ color: colors.red }}>Erro ao salvar cor</span>}
      </div>
    )
  )
}

export default JornadinhaColors