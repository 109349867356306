import React, { useState, useEffect, useRef, useContext } from "react";
import { Button, Form, Col} from "react-bootstrap";
import { useHistory, withRouter } from "react-router-dom";
import { DatePicker, ConfigProvider } from "antd";
import moment from "moment";
import "moment/locale/pt-br";
import "antd/dist/antd.css";
import ptBR from "antd/es/locale/pt_BR";
import {
  getTags,
  getCampaignsEvents,
  insertCampaigns,
  getListCampaignsDetail,
  updatecampaign
} from "../../services/NexiDigitalAPI";
import _ from "underscore";
import { AuthContext } from "../../context/AuthContext";
import { RiSave3Line } from "react-icons/ri";
import { BiArrowBack } from "react-icons/bi";
import { VscLoading } from "react-icons/vsc";
import Tags from "../../components/Tags";
const { RangePicker } = DatePicker;
const Campaigns = (props) => {
  const [listaOrdenada, setListaOrdenada] = useState([]);
  const [loadingTags, setLoadingTags] = useState(true);
  const [loadingEvents, setLoadingEvents] = useState(true);
  const { loginData } = useContext(AuthContext);
  const { primaryColor, secondaryColor } = loginData;
  const [activeCampaigns, setActiveCampaigns] = useState(true);
  const isMountedRef = useRef(null);
  const [dates, setDates] = useState([]);
  const [id, setId] = useState(0);
  const [datesValue, setdatesValue] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [nameCampanha, setNameCampanha] = useState("");
  const [selectEvent, setSelectEvent] = useState([]);
  const [events, setEvents] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const disabledDate = (current) => {
    return current && current < moment(new Date()).subtract(1, "days");
  };
  const history = useHistory();

  useEffect(() => {
    const campaignId = props.match?.params?.id
      if (campaignId > 1 && listaOrdenada.length) {
        setLoadingEvents(true);
        getListCampaignsDetail(campaignId)
          .then((res) => {
            setId(campaignId);
            setDisabled(moment(new Date()) > moment(res.campaign[0].startDate));
            setNameCampanha(res.campaign[0].name);
            setdatesValue([
              moment(res.campaign[0].startDate),
              moment(res.campaign[0].endDate),
            ]);
            setActiveCampaigns(res.campaign[0].status);
            let _events = [];
            res.events.forEach((elem) => {
              let obj = {};
              obj.id = elem.idEvent;
              obj.tags = elem.tags.length
                ? elem.tags.map((elem) => {
                    let _tag = listaOrdenada.find((tag) => tag.id === elem);
                    if (_tag) {
                      return { id: _tag.id, name: _tag.name };
                    }
                  })
                : [];
              obj.score = elem.points;
              obj.conclude = elem.conclude;
              _events = [..._events, obj];
            });
            setSelectEvent(_events);
          })
          .catch((err) => {
            setSelectEvent([]);
          })
          .finally(() => setLoadingEvents(false));
      } else {
        setNameCampanha(campaignId || "");
      }
  }, [listaOrdenada]);
  useEffect(() => {
    isMountedRef.current = true;

    getTags()
      .then((res) => {
        if (isMountedRef.current) {
          const tagSearch = res.data.tagsearch;
          const tagsSorted = _.sortBy(tagSearch, "tag");
          let nova = [];

          for (let i = 0; i < tagsSorted.length; i++) {
            nova.push({ id: tagsSorted[i].idTag, name: tagsSorted[i].tag });
          }
          setListaOrdenada(nova);
        }
      })
      .catch((err) => {
        setListaOrdenada([])
      })
      .finally(() => {
        setLoadingTags(false);
      });

    return () => (isMountedRef.current = false);
  }, []);

  useEffect(() => {
    isMountedRef.current = true;
    setLoadingEvents(true);
    getCampaignsEvents()
      .then((res) => {
        if (isMountedRef.current) {
          setEvents(res);
        }
      })
      .catch((err) => {
        setEvents([]);
      })
      .finally(() => {
        setLoadingEvents(false);
      });

    return () => (isMountedRef.current = false);
  }, []);

  const renderTags = (id, index) => {
    let _selectEvent = selectEvent.find((elem) => elem.id === id);
    return (
      <div
        key={index}
        style={{
          padding: 10,
        }}
      >
        <Form.Label>TAGs</Form.Label>

        <div className="card-body template-card-body">
          <div className="row">
            <div className="col-md-12">
              <Tags
                handleNewTags={(newTags) => {
                  _selectEvent.tags = newTags;
                  setSelectEvent([
                    ...selectEvent.filter((elem) => elem.id !== id),
                    _selectEvent,
                  ]);
                }}
                disabled={disabled}
                lista={listaOrdenada}
                contentTags={_selectEvent.tags}
              />
            </div>
          </div>
          <div className="row" style={{ marginTop: 10 }}>
            <div className="col-md-6">
              <Form.Group controlId="formPoints">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    Pontuação
                  </span>
                  <Form.Control
                    disabled={disabled}
                    type="number"
                    placeholder="Digite a pontuação desse evento"
                    value={_selectEvent.score}
                    onChange={(e) => {
                      _selectEvent.score = e.target.value.replace(/\D/g, "");
                      setSelectEvent([
                        ...selectEvent.filter((elem) => elem.id !== id),
                        _selectEvent,
                      ]);
                    }}
                  />
                </div>
                {submit && !_selectEvent.score && (
                  <span className={"submit-error"}>Campo obrigatorio</span>
                )}
              </Form.Group>
            </div>
          </div>
          <div className="row" style={{ marginTop: 10 }}>
            <div className="col-md-5">
              <Form.Group controlId="formPoints">
                <div className="input-group-prepend">
                  <span className="input-group-text"> Concluído </span>
                  <Form.Control
                    as="select"
                    disabled={disabled}
                    value={_selectEvent.conclude}
                    onChange={(e) => {
                      _selectEvent.conclude = e.target.value;
                      setSelectEvent([
                        ...selectEvent.filter((elem) => elem.id !== id),
                        _selectEvent,
                      ]);
                    }}
                  >
                    <option value="1">SIM</option>
                    <option value="0">NÃO</option>
                  </Form.Control>
                </div>
              </Form.Group>
            </div>
          </div>
        </div>
      </div>
    );
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmit(true);
    setLoadingSubmit(true);
    let data = {};
    let events = [];
    try {
      if (
        !nameCampanha ||
        !datesValue ||
        (datesValue && !datesValue.length) ||
        !selectEvent.length
      ) {
        setLoadingSubmit(false);
        return false;
      }

      data.name = nameCampanha.trim();
      data.startDate = moment(datesValue[0]).format("YYYY-MM-DD");
      data.endDate = moment(datesValue[1]).format("YYYY-MM-DD");
      data.status = activeCampaigns ? 1 : 0;
      for (let i = 0; i < selectEvent.length; i++) {
        if (!selectEvent[i].score) {
          setLoadingSubmit(false);
          return false;
        }

        let obj = {};
        obj.idEvent = selectEvent[i].id;

        obj.tags = selectEvent[i].tags.length
          ? selectEvent[i].tags.map((elem) => elem.id)
          : [];
        obj.points = parseInt(selectEvent[i].score);
        obj.conclude = parseInt(selectEvent[i].conclude);
        events = [...events, obj];
      }
      data.events = events;
      if (id) {
        await updatecampaign(id, data)
          .then((result) => {
            setLoadingSubmit(false);
            history.goBack();
          })
          .catch((err) => {
            alert(e.message);
          });
      } else {
        await insertCampaigns(data)
          .then((result) => {
            setLoadingSubmit(false);
            history.goBack();
          })
          .catch((err) => {
            alert(e.message);
          });
      }
    } catch (e) {
      alert(e.message);
    }
  }

  return (
    <div className="User" style={{ paddingBottom: 50 }}>
      <div className="header-title-wrapper">
        <div className="header-title">Gestão de Campanha</div>
      </div>
      {loadingTags || loadingEvents ? (
        <div className="chart-wrapper">
          <div className="loader loader-big" />
        </div>
      ) : (
        <div className="user-edit-container">
          {disabled && (
            <div
              className="chart-wrapper"
              style={{ backgroundColor: "#f9f918ad" }}
            >
              <span>
                Esta campanha está em andamento. Para não alterar o resultado da
                campanha, o único campo editável é o status.
              </span>
            </div>
          )}
          <form
            className="formCampaigns"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <div className="card">
              <div
                style={{ backgroundColor: secondaryColor }}
                className="card-header template-card-header"
              >
                Campanha
              </div>

              <Col xs={12} sm={8} style={{ margin: 10 }}>
                <Form.Group controlId="formNameCampanha">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      Nome
                    </span>
                    <Form.Control
                      disabled={disabled}
                      type="text"
                      placeholder="Digite o nome da campanha"
                      value={nameCampanha || ""}
                      onChange={(e) => setNameCampanha(e.target.value)}
                    />
                  </div>
                  {submit && !nameCampanha && (
                    <span className={"submit-error"}>Campo obrigatorio</span>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} style={{ margin: 10 }}>
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    Intervalo
                  </span>
                  <ConfigProvider locale={ptBR}>
                    <RangePicker
                      disabled={disabled}
                      value={datesValue}
                      disabledDate={disabledDate}
                      onCalendarChange={(val) => {
                        setDates(val);
                      }}
                      onChange={(val) => setdatesValue(val)}
                      format={["DD/MM/YYYY", "DD/MM/YY", "MM/YY"]}
                      size={"large"}
                    />
                  </ConfigProvider>
                </div>
                {submit &&
                  (!datesValue || (datesValue && !datesValue.length)) && (
                    <span className={"submit-error"}>Campo obrigatorio</span>
                  )}
              </Col>
            </div>
            <div className="card">
              <div
                style={{ backgroundColor: secondaryColor }}
                className="card-header template-card-header"
              >
                Eventos
              </div>
              <Col xs={12} sm={12} style={{ margin: 10 }}>
                <>
                  {events.map((item, index) => (
                    <div
                      className="card"
                      key={item.idgamification_event}
                      id={"formEventos"}
                      style={{
                        marginTop: "5px",
                      }}
                    >
                      <Form.Group
                        controlId="formEventos"
                        style={{
                          backgroundColor: `${secondaryColor.trim()}22`,
                        }}
                        className="card-header template-card-header"
                      >
                        <Form.Check type="checkbox">
                          <Form.Check.Input
                            style={{
                              width: "20px",
                              height: "20px",
                              background: primaryColor,
                            }}
                            disabled={disabled}
                            checked={
                              selectEvent.find(
                                (elem) => elem.id === item.idgamification_event
                              ) || false
                            }
                            type={"checkbox"}
                            color={primaryColor}
                            id={`section-${item.idgamification_event}`}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectEvent([
                                  ...selectEvent,
                                  {
                                    id: item.idgamification_event,
                                    event: item.event,
                                    tags: [],
                                    score: 0,
                                    conclude: 1,
                                  },
                                ]);
                              } else {
                                setSelectEvent(
                                  selectEvent.filter(
                                    (elem) =>
                                      elem.id !== item.idgamification_event
                                  )
                                );
                              }
                            }}
                          />
                          <Form.Check.Label style={{ color: "black" }}>
                            {`${item.event}`}
                          </Form.Check.Label>
                        </Form.Check>
                      </Form.Group>
                      {selectEvent.find(
                        (elem) => elem.id === item.idgamification_event
                      ) && renderTags(item.idgamification_event, index)}
                    </div>
                  ))}
                  {submit && !selectEvent.length && (
                    <span className={"submit-error"}>
                      Selecione pelo menos um evento pra continuar
                    </span>
                  )}
                </>
              </Col>
            </div>
            <div className="card">
              <div
                style={{ backgroundColor: secondaryColor }}
                className="card-header template-card-header"
              >
                Publicação
              </div>
              <Col xs={12} sm={12} style={{ paddingLeft: 0, margin: 10 }}>
                <Form.Group controlId="formActiveCampaigns">
                  <Form.Check type="checkbox">
                    <Form.Check.Input
                      style={{
                        width: "20px",
                        height: "20px",
                        background: primaryColor,
                      }}
                      type={"checkbox"}
                      checked={activeCampaigns}
                      onChange={(e) => {
                        setActiveCampaigns(e.target.checked);
                      }}
                    />
                    <Form.Check.Label style={{ color: "black" }}>
                      {`Ativar Campanha`}
                    </Form.Check.Label>
                  </Form.Check>
                </Form.Group>
              </Col>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 30,
                flexDirection: "flex-end",
                justifyContent: "space-around",
              }}
            >
              <Button
                onClick={() => {
                  history.goBack();
                }}
                variant="secondary"
                style={{ marginRight: 15 }}
                disabled={loadingSubmit}
              >
                <div>
                  <BiArrowBack
                    size={22}
                    style={{ marginBottom: 3, marginRight: 10 }}
                  />
                  Voltar
                </div>
              </Button>

              <Button variant="success" type="submit" disabled={loadingSubmit}>
                {!loadingSubmit ? (
                  <div>
                    <RiSave3Line
                      size={22}
                      style={{ marginBottom: 3, marginRight: 10 }}
                    />
                    Salvar
                  </div>
                ) : (
                  <div>
                    <VscLoading
                      size={22}
                      style={{ marginBottom: 3, marginRight: 10 }}
                    />
                    Salvando
                  </div>
                )}
              </Button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default withRouter(Campaigns);
