import React, { useState, useEffect, useCallback } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { RiSave3Line } from "react-icons/ri";
import { BiArrowBack } from "react-icons/bi";
import { MdEdit } from "react-icons/md";
import { getUser, editUser } from "../services/NexiDigitalAPI";
import queryString from "query-string";
import MaskedFormControl from "react-bootstrap-maskedinput";
import { useHistory } from "react-router-dom";
import { cpf } from "cpf-cnpj-validator";

export default function EditUser(props) {
  const history = useHistory();
  const goBack = useCallback(() => history.push("/user"), [history]);
  const [error, setError] = useState("");
  const [errorSave, setErrorSave] = useState("");
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [wrongPassword, setWrongPassword] = useState(false);
  const [passwordEqualError, setPasswordEqualError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [invalidCpf, setInvalidCpf] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [userData, setUserData] = useState({
    idUser: 1,
    idCompany: 1,
    email: "",
    name: "",
    phone: "",
    cpf: "",
    userType: 1,
    userLicenceTermsAccepted: 2,
    communicationAccepted: 1,
    guestCompany: "",
    guestCnpj: "",
    admin: 1,
    active: 1,
    photo: "",
    department: "",
    role: "",
    password: "",
  });
  // função para receber os dados do usuário
  function getUserData(id) {
    getUser(id)
      .then((res) => {
        if (res.data.success == true) {
          setLoading(false);
          setUserData({ ...res.data.user, password: "" });
        } else {
          setError("Erro ao carregar dados do usuário");
        }
      })
      .catch((err) => {
        setError("Erro ao carregar dados do usuário");
      });
  }

  const getUserDataCallBack = useCallback(() => {
    let search = queryString.parse(props.location.search);
    if (search.id) {
      getUserData(search.id);
    }
  }, [props.location.search]);

  useEffect(() => {
    getUserDataCallBack();
    window.scrollTo(0, 0);
  }, [getUserDataCallBack]);

  function saveData(event) {
    event.preventDefault();

    if (userData.cpf && !cpf.isValid(userData.cpf)) {
      setInvalidCpf(true);
      return;
    }

    const isPhoneValid =
      /^\([1-9]{2}\) (?:[2-8]|[1-9][1-9])[0-9]{3}\-[0-9]{4}$/.test(
        userData.phone
      );
    if (!isPhoneValid) {
      setInvalidPhone(true);
      return;
    }

    // resetar o status de erros
    setWrongPassword(false);
    setPasswordEqualError(false);
    setSuccessMessage("");
    setInvalidCpf(false);
    setErrorSave("");
    setInvalidPhone(false);

    let data = Object.assign({});
    const userId = userData.idUser;

    // adicionar campos necessarios para a chamada
    data = { ...userData };

    let newUserData = {
      active: userData.active,
      admin: userData.admin,
      askPWA: userData.askPWA,
      communicationAccepted: userData.communicationAccepted,
      cpf: userData.cpf,
      department: userData.department,
      email: userData.email,
      guestCnpj: userData.guestCnpj,
      guestCompany: userData.guestCompany,
      name: userData.name,
      phone: userData.phone,
      photo: userData.photo,
      role: userData.role,
      userLicenceTermsAccepted: userData.userLicenceTermsAccepted,
      userType: userData.userType,
    };

    // adicionar password caso haja
    if (userData.password.length > 0) {
      newUserData.password = userData.password;
    }

    //validar senha (8 digit and a number)
    let passwordMatch = /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/gm.test(
      newUserData.password
    );
    if (!passwordMatch && newUserData.password) {
      setWrongPassword(true);
      return;
    }

    //validar confirmação de senha
    if (newUserData.password && newUserData.password != confirmPassword) {
      setPasswordEqualError(true);
      return;
    }
    // salva os dados do usuário
    setSaving(true);
    editUser(userId, newUserData)
      .then((res) => {
        setSaving(false);
        if (res.data.success == true) {
          setSuccessMessage("Dados salvo com sucesso!");
        } else {
          setErrorSave("Erro ao gravar dados!");
        }
      })
      .catch((err) => {
        setSaving(false);
        setErrorSave("Erro ao gravar dados!");
      });
  }

  return (
    <>
      <div class="not-header-title" />
      <div className="user-edit-container" style={{marginTop:"8%"}}>
        {loading && (
          <div className="user-edit-loading">
            {error.length > 0 ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {error}
                <Button onClick={goBack} variant="secondary">
                  <div>
                    <BiArrowBack
                      size={22}
                      style={{ marginBottom: 3, marginRight: 10 }}
                    />
                    Voltar
                  </div>
                </Button>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  className="loader loader-big"
                  style={{ marginBottom: 15 }}
                />
                Carregando dados do usuário...
              </div>
            )}
          </div>
        )}
        <Form onSubmit={(event) => saveData(event)}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              fontSize: 22,
              marginBottom: 25,
            }}
          >
            <MdEdit style={{ marginTop: 4, marginRight: 10 }} />
            Edição de usuário
          </div>
          <div className="user-edit-inside">
            <div className="user-edit-inside-part">
              <Form.Group>
                <Row>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      required
                      value={userData.name}
                      type="text"
                      placeholder="Digite um nome"
                      onChange={(e) =>
                        setUserData({ ...userData, name: e.target.value })
                      }
                    />
                  </Col>
                  <Col lg="6" md="6" xs="12" sm="5">
                    <Form.Label>
                      Admin{" "}
                      <span style={{ color: "gray" }}>(0 = Não, 1 = Sim)</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      value={userData.admin}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          admin: parseInt(e.target.value),
                        })
                      }
                    >
                      <option value={0}>0</option>
                      <option value={1}>1</option>
                    </Form.Control>
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group>
                <Row>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      required
                      value={userData.email}
                      type="email"
                      placeholder="Digite um email"
                      onChange={(e) =>
                        setUserData({ ...userData, email: e.target.value })
                      }
                    />
                  </Col>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>Departamento</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite um departamento"
                      value={userData.department}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          department: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group>
                <Row>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>Telefone</Form.Label>
                    <MaskedFormControl
                      required
                      value={userData.phone}
                      mask="(11) 11111-1111"
                      type="tel"
                      placeholder="Digite um Telefone"
                      onChange={(e) =>
                        setUserData({ ...userData, phone: e.target.value })
                      }
                    />
                  </Col>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>Função</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite uma função"
                      value={userData.role}
                      onChange={(e) =>
                        setUserData({ ...userData, role: e.target.value })
                      }
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group>
                <Row>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>
                      CPF <span style={{ color: "gray" }}>(Opcional)</span>
                    </Form.Label>
                    <MaskedFormControl
                      type="text"
                      name="phoneNumber"
                      mask="111.111.111-11"
                      value={userData.cpf}
                      onChange={(e) =>
                        setUserData({ ...userData, cpf: e.target.value })
                      }
                    />
                  </Col>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>Empresa</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite o nome da empresa"
                      value={userData.guestCompany}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          guestCompany: e.target.value,
                        })
                      }
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group>
                <Row>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>Tipo de usuário</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite um tipo de usuário"
                      value={userData.userType}
                      onChange={(e) =>
                        setUserData({ ...userData, userType: e.target.value })
                      }
                    />
                  </Col>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>CNPJ da Empresa</Form.Label>
                    <MaskedFormControl
                      mask="11.111.111/1111-11"
                      type="text"
                      placeholder="Digite o CNPJ da Empresa"
                      value={userData.guestCnpj}
                      onChange={(e) =>
                        setUserData({ ...userData, guestCnpj: e.target.value })
                      }
                    />
                  </Col>
                </Row>
              </Form.Group>
            </div>
          </div>

          <hr style={{ marginTop: 30 }} />

          <div className="user-edit-inside">
            <Form.Group className="user-edit-inside-part">
              <Row>
                <Col lg="6" md="6" xs="12" sm="7">
                  <Form.Label>
                    Nova senha <span style={{ color: "gray" }}>(Opcional)</span>
                  </Form.Label>
                  <Form.Control
                    className={wrongPassword ? "border-red" : "border-none"}
                    type="password"
                    autoComplete="new-password"
                    placeholder="Senha"
                    onChange={(e) =>
                      setUserData({ ...userData, password: e.target.value })
                    }
                  />
                  <Form.Text className="text-muted">
                    Pelo menos 8 caracteres
                  </Form.Text>
                  <Form.Text className="text-muted">
                    Pelo menos uma letra
                  </Form.Text>
                  <Form.Text className="text-muted">
                    Pelo menos um número
                  </Form.Text>
                </Col>
                <Col lg="6" md="6" xs="12" sm="7">
                  <Form.Label>Confirmar senha</Form.Label>
                  <Form.Control
                    className={
                      passwordEqualError ? "border-red" : "border-none"
                    }
                    type="password"
                    disabled={userData.password.length == 0}
                    autoComplete="new-password"
                    placeholder="Senha"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>
          </div>

          {/* Botões */}
          <div style={{ display: "flex", marginTop: 30 }}>
            <Button
              onClick={goBack}
              variant="secondary"
              style={{ marginRight: 15 }}
            >
              <div>
                <BiArrowBack
                  size={22}
                  style={{ marginBottom: 3, marginRight: 10 }}
                />
                Voltar
              </div>
            </Button>

            <Button variant="success" type="submit">
              {saving ? (
                <div style={{ display: "flex" }}>
                  <RiSave3Line
                    size={22}
                    style={{ marginBottom: 3, marginRight: 10 }}
                  />
                  Salvando{" "}
                  <div
                    className="loader loader-white"
                    style={{ marginLeft: 15 }}
                  />
                </div>
              ) : (
                <div>
                  <RiSave3Line
                    size={22}
                    style={{ marginBottom: 3, marginRight: 10 }}
                  />
                  Salvar
                </div>
              )}
            </Button>
          </div>

          {/* Mensagens de erro e sucesso */}
          <div style={{ marginTop: 10 }}>
            <div style={{ color: "green" }}>{successMessage}</div>
            <div style={{ color: "red" }}>
              {errorSave}
              {wrongPassword && "Senha não segue os padrões."}
              {passwordEqualError && "Confirme sua senha corretamente."}
              {invalidCpf && "CPF invalido."}
              {invalidPhone && "Telefone invalido."}
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}
