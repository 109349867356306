import React, {useContext} from 'react';

import { AuthContext } from "../../context/AuthContext";

const CmsAutor = ({autor, setAutor, autorEmail, setAutorEmail}) => {

    const { loginData } = useContext(AuthContext);
    const fourthColor = loginData?.fourthColor;
    const secondaryColor = loginData?.secondaryColor;

    return (
        <div className="form-group">
            <div className="card">
                <div
                    style={{ backgroundColor: fourthColor || secondaryColor }}
                    className="card-header template-card-header"
                >
                    Autor
                </div>
                <div className="card-body template-card-body">
                    <div className="form-row">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="autor">
                                    Nome
                                </span>
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                value={autor}
                                onChange={(e) => setAutor(e.target.value)}
                            />
                        </div>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="autor">
                                    E-mail
                                </span>
                            </div>
                            <input
                                type="email"
                                className="form-control"
                                value={autorEmail}
                                onChange={(e) => setAutorEmail(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CmsAutor;