import React, { useState,useEffect } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import moment from "moment";
import "moment/locale/pt-br";
import {
    listcampaignuserpoints
  } from "../../../services/NexiDigitalAPI";
const Row = ({backgroundRow, item,id}) => {
    const [open, setOpen] = useState(false);
    const [user, setUser] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    
    const getUser = async (userId = '123') => {
        setLoading(true);
        await listcampaignuserpoints({idCampaign:parseInt(id),idUser: userId})
        .then(async (res) => {
            setUser(res)
            setLoading(false)
        }).catch((err)=> {
            setUser([])
            setLoading(false)
            setError(error)
        })
    }
    return(
    <> 
        <Tr
            key={item.idUser}
            style={{
            backgroundColor:backgroundRow
            }}
        >
            <Td > 
            <IconButton aria-label="expand row" size="small" onClick={(e) => {if(!open){getUser(item.idUser)};setOpen(!open)}}>
                {item.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            </Td>
            <Td>{item.name}</Td>
            <Td style={{textAlign:'right'}}>{item.points}</Td>
        </Tr>
        <Td colSpan="6">
            <Collapse in={open} timeout="auto" unmountOnExit>
                {
                    loading ? 
                    (
                        <div className="div-loading">
                        <>
                          {!error ? (
                            <div className="loader loader-big" />
                          ) : (
                            <div>Tivemos uma falha ao carregar a tabela.</div>
                          )}
                        </>
                      </div>
                    ):(
                        <Table>
                            <Thead>
                                <Tr>                          
                                <Th>Evento</Th>
                                <Th>Pontuação</Th>
                                <Th>Data</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {user.map((elem, index) => (
                                    <Tr key={index}>                            
                                        <Td>{elem.event}</Td>
                                        <Td>{elem.points}</Td>
                                        <Td>{moment(elem.eventDate).utc().format("DD/MM/YYYY HH:mm:ss ")}</Td>
                                    </Tr>              
                                ))}
                            </Tbody>
                        </Table>
                    )
                    
                }
            </Collapse>
        </Td>
    </>
    )
}

export default Row;