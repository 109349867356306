import React, { useState, useEffect } from "react";
import { Switch } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

function ToggleSwitch(props)
{

  const [isChecked, setIsChecked] = useState(false);
  const [switchValue, setSwitchValue] = useState(props.value);

  const toggleSwitch = () => {
    setIsChecked(!isChecked);
  }

  const useStyles = makeStyles((theme) => ({
    switch_track: {
      backgroundColor: props.color,
    },
    switch_base: {
      color: "#FFF",
      "&.Mui-disabled": {
        color: "#de9ee8"
      },
      "&.Mui-checked": {
        color: props.color
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: props.color,
      }
    },
    switch_primary: {
      "&.Mui-checked": {
        color: props.color,
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: props.color,
      },
    },
  }));

  const classes = useStyles();

  useEffect(()=> {

    if(props.permissionToSave){
      setIsChecked(true);
    }

    if(props.checked){
      setIsChecked(true);
    }

  }, []);

  return (
    <Switch
      value={switchValue}
      checked={isChecked}
      onClick={e => toggleSwitch()}
      onChange={props.onChange}
      title={props.title}
      classes={{
        track: classes.switch_track,
        switchBase: classes.switch_base,
        colorPrimary: classes.switch_primary,
      }}
      inputProps={{ 'aria-label': 'secondary checkbox' }}
      disabled={(props.disabled) ? props.disabled : false}
    />
  );
}

export default ToggleSwitch;
