import React, { useState } from "react";
import Filtro from './Filtro';
import Table from './Table';
import ReportAcordeon from '../../../Components/ReportAcordeon'
import { 
    getReportContentCommented
} from "../../../../../services/NexiDigitalAPI";


const Commented = ({ index, expanded }) => {
    const name = "Conteúdos mais comentados"
    const description = "Exibe Tipos de conteúdo mais comentados pelos usuários.";

    const [loading, setLoading] = useState(false);
    const [dados, setDados] = useState([]);

    const actionFilter = async (values) => {

        setLoading(true);
        // Sessions
        const responseData = await getReportContentCommented(values);
        setDados(responseData);
   
        setLoading(false);
    }

    const filter = (
        <Filtro 
            actionFilter={actionFilter} 
            loading={loading} 
        />
    );

    const result = (
        <Table 
            dados={ dados } 
            loading={loading} 
        />
    );
    
    return (
        <ReportAcordeon
            name={name}
            description={description}
            filter={filter}
            result={result}
            index={ index }
            expanded={expanded}
        />
        
    )
}

export default Commented;