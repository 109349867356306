import React from 'react';
import moment from "moment";
import StandartDataTable from '../../../../../components/StandartDataTable';
import DataTableExtensions from "react-data-table-component-extensions";

const Table = ({ List, loading}) => {

    const columns = [
        { name: "Data", selector: "date", sortable: true, },
        { name: "Quantas compras", selector: "numberOfPurchases", sortable: true, },
        { name: "Valor", selector: "totalPrice", sortable: true, },
    ];

    const treatedList = List.map( (item) => {
        return {
            ...item,
            date:  moment(item.date).format("DD/MM/YYYY")
        }
    })
    
    const tableData = {
        columns: columns,
        data: treatedList
    }

    return (
        <div className="report-table-container">
            <DataTableExtensions filter={false} print={false} {...tableData} exportHeaders={true}>
                <StandartDataTable
                    columns={columns}
                    data={treatedList}
                    loading={ loading }
                />
            </DataTableExtensions>
        </div>
    );
}

export default Table;