import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { findContentItem } from "../../services/NexiDigitalAPI";
import SinglePage from "./SinglePage";
import MainMenu from './MainMenu';
import Podcast from "./Podcast";
import Video from "./Video";
import MultiFormat from './MultiFormat';
import Post from "./Post";
import Jornada from "./Jornada";
import Jornadinha from "./Jornadinha";
import Capitulo from "./Capitulo";
import Pagina from "./Pagina";
import YoutubeLive from "./YoutubeLive";
import YoutubePlaylist from "./YoutubePlaylist";
import File from "./File";
import Meeting from "./Meeting";
import VideoLink from "./VideoLink";
import { getMenuRoutes } from "../../utils/Utils";
import Loading from "../../components/Loading";

function TemplateSelector(props) {
  const [data, setData] = useState([]);
  const [tags, setTags] = useState([]);
  const [id, setId] = useState([]);
  const [group, setGroup] = useState([]);
  const [loading, setLoading] = useState(true);

  function _findContentType(id) {
    setLoading(true)
    findContentItem(id)
      .then((res) => {
        if (res.data.success === true) {
          setData(res.data.content[0]);
          setTags(res.data.tags);
          setId(res.data.content[0].idContentType);
          setGroup(res.data.content[0].idContentGroup);
        }
      })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
        setLoading(false)
      })
  }

  /* [Permission Module] - Permissions states */
  const [permissionApproveContent, setPermissionApproveContent] = useState(false);

  /* [Permission Module] - Menu Options */
  async function getMenuOption() {
    const menuOptionList = getMenuRoutes();

    const objCurrentMenu = menuOptionList.filter(
      (menu) => menu.text === "Gestão de Conteúdo"
    );

    if (Boolean(objCurrentMenu.length)) {
      const resources = objCurrentMenu.map((menu, index) => menu.resource);

      getPermissionsPage(resources);
    }
  }

  /* [Permission Module] - Permission attrs in page by login data information */
  function getPermissionsPage(resources) {
    try {
      if (
        Boolean(
          resources[0].filter((menu) => menu.name === "Aprovar Conteúdo").length
        )
      )
        setPermissionApproveContent(true);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    _findContentType(props.match.params.id);
    getMenuOption();
  }, [0]);

  function noSection() {
    return (
      <div
        style={{
          width: "100%",
          textAlign: "center",
          paddingTop: "calc(25vh - 5rem - 54px)",
        }}
      >
        <span>{/* Seção não disponível */}</span>
      </div>
    );
  }

  function selectTemplate(type) {
    if(loading) {
      return <Loading center> Carregando... </Loading>
    }
    type = parseInt(type);
    switch (type) {
      case 1:
        return <Podcast data={data} contentTags={tags} permissionApproveContent={permissionApproveContent} />;
      case 2:
        return <Video data={data} contentTags={tags} permissionApproveContent={permissionApproveContent} />;
      case 3:
        return <Post data={data} contentTags={tags} permissionApproveContent={permissionApproveContent} />;
      case 4:
        return <Jornada data={data} contentTags={tags} permissionApproveContent={permissionApproveContent} />;
      case 6:
        return <YoutubeLive data={data} contentTags={tags} permissionApproveContent={permissionApproveContent} />;
      case 5:
        return <SinglePage data={data} contentTags={tags} permissionApproveContent={permissionApproveContent} />;
      case 7:
        return <YoutubePlaylist data={data} contentTags={tags} permissionApproveContent={permissionApproveContent} />;
      case 8:
        return <Meeting data={data} contentTags={tags} permissionApproveContent={permissionApproveContent} />;
      case 9:
        return <VideoLink data={data} contentTags={tags} permissionApproveContent={permissionApproveContent} />;
      case 15:
        return <Jornadinha data={data} contentTags={tags} permissionApproveContent={permissionApproveContent} />;
      case 16:
        return <Capitulo data={data} contentTags={tags} permissionApproveContent={permissionApproveContent}/>;
      case 17:
        return <Pagina data={data} contentTags={tags} permissionApproveContent={permissionApproveContent}/>;
      case 18:
        return <File data={data} contentTags={tags} permissionApproveContent={permissionApproveContent}/>;
      case 19:
        return <MultiFormat data={data} contentTags={tags} permissionApproveContent={permissionApproveContent} />;
      case 20:
        return <MainMenu data={data} contentTags={tags} permissionApproveContent={permissionApproveContent} />;
      case 22:
        return <Jornadinha data={data} contentTags={tags} permissionApproveContent={permissionApproveContent} />;
      case 23:
        return <Capitulo data={data} contentTags={tags} permissionApproveContent={permissionApproveContent}/>;
      case 24:
        return <Pagina data={data} contentTags={tags} permissionApproveContent={permissionApproveContent}/>;
      case 25:
        return <File data={data} contentTags={tags} permissionApproveContent={permissionApproveContent}/>;
      default:
        return noSection();
    }
  }

  return selectTemplate(id);
}

export default withRouter(TemplateSelector);
