// Basic
import React from "react";
import Content from './SubReports/Content';
import Activity from './SubReports/Activity';
import Historic from './SubReports/Historic';
import ReportWrapper from '../Components/ReportWrapper';
import ReportListTitle from '../Components/ReportListTitle';


const ReportUser = ({ rel }) => {

  const reportList = [
    (index, expanded) => <Content index={index} expanded={expanded} key={index}/>,
    (index, expanded) => <Activity index={index} expanded={expanded} key={index} />,
    (index, expanded) => <Historic index={index} expanded={expanded} key={index} />,
  ]

  return (
    <ReportWrapper>
      <ReportListTitle>
        Lista de Relatórios
      </ReportListTitle>
      {
        reportList.map((report, index) => {
          const expand = (index.toString() === rel);
          return report(index, expand)
        }
      )}
    </ReportWrapper>
  )
}

export default ReportUser