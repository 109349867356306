import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/pt-br.js";
import Filtro from './Filtro';
import Table from './Table';
import ReportAcordeon from '../../../Components/ReportAcordeon'
import { 
    getReportContentengagement
} from "../../../../../services/NexiDigitalAPI";


const Content = ({ index, expanded }) => {
    const name = "Tipos de conteúdo mais acessados"
    const description = "Exibe Tipos de conteúdo mais acessados pelos usuários.";

    const [loading, setLoading] = useState(false);
    const [dados, setDados] = useState([]);

    const actionFilter = async (values) => {

        setLoading(true);
        // Sessions
        const responseData = await getReportContentengagement(values);
        const convertedData = responseData.map((response) => {
            return {
                ...response,
                timeStart: moment(new Date(response.timeStart)).format("DD/MM/YYYY HH:mm") 
            }
    })
        setDados(convertedData);
   
        setLoading(false);
    }

    const filter = (
        <Filtro 
            actionFilter={actionFilter} 
            loading={loading} 
        />
    );

    const result = (
        <Table 
            dados={ dados } 
            loading={ loading }
        />
    );

    useEffect(()=>{
        actionFilter()
    },[]);
    
    return (
        <ReportAcordeon
            name={name}
            description={description}
            filter={filter}
            result={result}
            index={ index }
            expanded={ expanded }
        />
        
    )
}

export default Content;