import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useHistory  } from 'react-router-dom'
import {
  updateGroup,
  getGroupUsers,
  linkUserToGroup,
  unlinkUserFromGroup,
  getUsers
} from "../../../services/NexiDigitalAPI";
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import EditIcon from '@material-ui/icons/Edit';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import _ from "underscore";
import FilterResults from "react-filter-search";
import { Button, Tooltip } from "@material-ui/core";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import './Groups.scss'
import { AuthContext } from "../../../context/AuthContext";
import Loading from '../../../components/Loading';


const useStyles = makeStyles({
  indicator: {
    backgroundColor: '#FFF'
  },
  root: {
    marginBottom: '-10px'
  }
});

const GroupDetail = () => {
  const { groupId, groupTitle } = useParams()
  const history = useHistory();
  const classes = useStyles()
  const groupNameInputRef = useRef(null)
  const isMountedRef = useRef(null);

  const [listaUsuariosDoGrupo, setListaUsuariosDoGrupo] = useState([]);
  const [listaUsuariosDisponiveis, setListaUsuariosDisponiveis] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [groupName, setGroupName] = useState(groupTitle);
  const [currentUser, setCurrentUser] = useState('')
  const [groupNameEditMode, setGroupNameEditMode] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(true)

  const { loginData } = useContext(AuthContext)
  const secondaryColor = loginData.secondaryColor

  useEffect(function getAllUsers() {
    isMountedRef.current = true;
    async function getUsersInGroupAndAvailable () {
      try {
        let [ usersInGroup, usersAvailable ] = await Promise.all([getGroupUsers(groupId), getUsers()])

        if (isMountedRef.current) {
          setListaUsuariosDoGrupo(_.sortBy(usersInGroup.data.users, "name"));

          if (usersInGroup.data.users.length === 0) {
            // abre aba usuarios disponiveis quando grupo esta vazio
            setCurrentTab(1)
          }

          usersAvailable = usersAvailable.data.userList.filter(availableUser => {
            return availableUser.active === 1 && !usersInGroup.data.users.find(existingUser => existingUser.idUser === availableUser.idUser )
          })

          setListaUsuariosDisponiveis(_.sortBy(usersAvailable), "name")
          setLoadingUsers(false)
        }
      } catch(err) {
        console.log(err)
      }
    }
    getUsersInGroupAndAvailable()
    return () => isMountedRef.current = false;
  }, [groupId])

  useEffect(function focusGroupNameInput() {
    groupNameInputRef.current && groupNameInputRef.current.focus()
  }, [groupNameEditMode])

  function updateGroupName () {
    return updateGroup(groupId, groupName).then(() => {
      setGroupNameEditMode(false)
    })
  }

  function handleUnlinkUser(user) {
    return unlinkUserFromGroup(groupId, user.idUser).then(() => {
      setListaUsuariosDoGrupo([ ...listaUsuariosDoGrupo.filter(u => u.idUser !== user.idUser)])
      setListaUsuariosDisponiveis(_.sortBy([ ...listaUsuariosDisponiveis, user], "name"))
    })
  }

  function handleLinkUser(user) {
    return linkUserToGroup(groupId, user.idUser).then(() => {
      setListaUsuariosDisponiveis([ ...listaUsuariosDisponiveis.filter(u => u.idUser !== user.idUser)])
      setListaUsuariosDoGrupo(_.sortBy([ ...listaUsuariosDoGrupo, user], "name"))
    })
  }

  return (
    <div className="container groups">
      <div className="template-header">
        {/* edita nome grupo */}
        <h3 className="group-users-header">
          { !groupNameEditMode
            ? <span >
                {groupName}
                <EditIcon onClick={() => setGroupNameEditMode(true)}  style={{ color: '#6c757d', marginLeft: '10px', marginTop: '-4px', cursor: 'pointer' }}/>
              </span>
            : <span className="group-name-editor">
                <input
                  className="form-control input-default"
                  ref={groupNameInputRef}
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                />
                <CheckCircleOutlineOutlinedIcon size="large" onClick={updateGroupName} style={{ color: '#1e7e34', marginLeft: '10px', marginTop: '-4px' , cursor: 'pointer' }}/>
              </span>
          }
          <Button
            variant="contained"
            style={{ backgroundColor: secondaryColor, color: "#fff", outline: "none" }}
            startIcon={<KeyboardBackspaceOutlinedIcon />}
            onClick={() => history.goBack()}
          >
            Voltar
          </Button>
        </h3>
      </div>
      {/* busca de usuario */}
      <div className="post-form">
        <div className="form-group">
          <div className="card">
            <div
              className="card-header template-card-header"
              style={{
                color: "#FFF",
                backgroundColor: secondaryColor,
              }}
            >
              Procurar Usuário
            </div>
            <div className="card-body template-card-body">
              <div className="row">
                <div className="col-md-10 input-container">
                  <input
                    type="text"
                    className="form-control input-default"
                    id="groupDetail"
                    aria-describedby="usuario"
                    value={currentUser}
                    onChange={(e) => setCurrentUser(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        loadingUsers ? <Loading>Carregando usuários</Loading>
        :
          <div className="user-list-container">
            {/* Lista usuarios do Grupo*/}
            <div className="card">
              <div
                className="card-header template-card-header"
                style={{
                  color: "#FFF",
                  backgroundColor: secondaryColor,
                }}
              >
                Usuários do Grupo
              </div>
              <div className="card-body template-card-body">
                <ul className="list-group">
                  {listaUsuariosDoGrupo.map((item, index) => {
                    return (
                      <div key={item.idUser}>
                        <li className="list-group-item">
                          {item.name}
                          <Tooltip title="Remover Usuário" placement="top">
                            <span
                              className="btn btn-sm float-right"
                              onClick={() => handleUnlinkUser(item)}
                            >
                              <RemoveCircleOutlineOutlinedIcon style={{ color: '#dc3545' }}  />
                            </span>
                          </Tooltip>
                        </li>
                      </div>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div className="user-list-separator"></div>

            {/* Lista usuarios Disponiveis*/}
            <div className="card">
            <div
              className="card-header template-card-header"
              style={{
                color: "#FFF",
                backgroundColor: secondaryColor,
              }}
            >
              Usuários Disponíveis
            </div>
            <div className="card-body template-card-body">
              <FilterResults
                value={currentUser}
                data={listaUsuariosDisponiveis}
                renderResults={(results) => (
                  <ul className="list-group">
                    {results.map((item, index) => {
                      return (
                        <div key={item.idUser}>
                          <li className="list-group-item">
                            {item.name}
                            <Tooltip title="Adicionar Usuário" placement="top">
                              <span
                                className="btn btn-sm float-right"
                                onClick={() => handleLinkUser(item)}
                              >
                                <AddCircleOutlineOutlinedIcon style={{ color: '#1e7e34' }}  />
                              </span>
                            </Tooltip>
                          </li>
                        </div>
                      );
                    })}
                  </ul>
                )}
              />
            </div>
          </div>
        </div>
      }

    </div>
  );
};

export default GroupDetail;
