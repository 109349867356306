import React, { useState, useContext } from "react";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

export default function SnackComponent({ 
    message = '',
    openSnackBar,
    setOpenSnackBar,
    severity = 'success'
}) {

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    
    return (
        <Snackbar 
            open={openSnackBar} 
            autoHideDuration={6000} 
            onClose={() => setOpenSnackBar(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert onClose={() => setOpenSnackBar(false)} severity={severity}>
                { message }
            </Alert>
        </Snackbar>
    )
}