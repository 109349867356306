import React, { useState, useEffect, useContext } from "react";
import { Redirect, NavLink } from 'react-router-dom'
import '../../assets/css/TelaPrincipal.css'
import { transformText, capitalizeFirstLetter, urlExtension, getMenuImage, getTypeName } from '../../utils/Utils';
import TagIcon from '@material-ui/icons/LocalOffer';
import VideocamIcon from '@material-ui/icons/Videocam';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import { AuthContext } from "../../context/AuthContext";
import { getMenuRoutes } from "../../utils/Utils"; // The location path will depend of your current module

function Configuracao(props) {
    const [redirect, setRedirect] = useState(false);
    const [data, setData] = useState([]);

    const { loginData } = useContext(AuthContext)
    const primaryColor = loginData.primaryColor
    const [permissions, setPermission] = useState({
        'Gerenciamento de Tags': false,
        'Look And Feel App': false,
    });

    async function getMenuOption() {
        const menuOptionList = getMenuRoutes();
        
        const objCurrentMenu = menuOptionList.filter(
          (menu) => menu.text === "Configurações" // Use the name of the menu-option that is coming to your module
        );

        console.log(objCurrentMenu)
    
        if (Boolean(objCurrentMenu.length)) {
          getPermissionsPage(objCurrentMenu[0].resource);
        }
    }

    /* [Permission Module] - Permission attrs in page by login data information */
    function getPermissionsPage(resources) {
        try {
            let newObj = {};
            for(let prop in permissions){
                newObj = {
                    ...newObj,
                    [prop]: Boolean( resources.filter((menu) => menu.name === prop).length)
                }
            }

            setPermission(
                {
                    ...permissions,
                    ...newObj,
                }
            )
            
        } catch (error) {
        console.log(error);
        }
    }

    useEffect(() => {
        getMenuOption();
    },[])


    useEffect(() => {
            let array = [];
            permissions['Gerenciamento de Tags'] && array.push({ idContentGroup: 'tags', idContentType: 'tags', name: 'tags' })
            permissions['Look And Feel App'] && array.push({ idContentGroup: 'gestao-de-cor-e-logo', idContentType: 'gestao-de-cor-e-logo', name: 'Gestão de cor e logo' })
            // array.push({ idContentGroup: 'zoom', idContentType: 'zoom', name: 'zoom' })
            // array.push({ idContentGroup: 'grupos', idContentType: 'grupos', name: 'grupos' })
            setData(array);
    }, [permissions])

    if (redirect) {
        return <Redirect to='/login' />;
    }

    function getIconByName(name) {
        switch (name) {
            case "tags":
                return <TagIcon style={{ fontSize: 50 }} className="static-icon"/>;
            case "zoom":
                return <VideocamIcon style={{ fontSize: 50 }} className="static-icon"/>;
            case "grupos":
                return <GroupOutlinedIcon style={{ fontSize: 50 }} className="static-icon"/>;
            case 'gestao-de-cor-e-logo':
                return <ColorLensIcon style={{ fontSize: 50 }} className="static-icon"/>;
        }
    }
    return (
        <div>
        <div className="header-title-wrapper">
            <div className="header-title"> 
                Configurações
            </div>
        </div>
            <div style={{display: 'flex', alignItems: 'stretch', justifyContent: 'center', alignContent: 'stretch',flexWrap: 'wrap', marginLeft: -10, marginRight: -10 }}>
                {
                    data.map((item, index) => {
                        console.log(item)
                        return (
                            <NavLink className="menu-card-content col-lg-3 col-md-6 col-sm-6 col-xs-6 col-12" 
                                key={index} to={`/configuracao/${transformText(item.name)}/`} 
                                style={{ padding: 10, border: 'none', textDecoration: 'none', cursor: 'pointer' }}>
                                <div className="card card-effect" style={{ height: '100%' }}>
                                    <div className="image-card" style={{color: '#FFF', backgroundColor: primaryColor}}>
                                        {getIconByName(transformText(item.name))}
                                    </div>
                                    <div className="card-body">
                                        <h6 className="card-title" style={{ color: '#861414' }}>{capitalizeFirstLetter(item.name)}</h6>
                                        <span className="badge badge-pill badge-secondary badge-render">{getTypeName(item.idContentType)}</span>
                                    </div>
                                </div>
                            </NavLink>
                        )
                    })
                }
            </div>
        </div >
    );
}

export default Configuracao
