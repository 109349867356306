import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import {
  ButtonGroup,
  Button,
  Badge,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { FiUserPlus } from "react-icons/fi";
import { MdEdit, MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { FaCheckCircle, FaMinusCircle } from "react-icons/fa";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import Loading from "../components/Loading";
import FilterResults from "react-filter-search";
import { getMenuRoutes } from "../utils/Utils";
import { sendEmailBoasVindasApi } from "../services/NexiDigitalAPI";

const api_url = process.env.REACT_APP_BACKEND_API;

export default function User() {
  const history = useHistory();
  const goToEdit = useCallback(
    (id) => history.push(`/edit-user?id=${id}`),
    [history]
  );
  const [users, setUsers] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const [company, setCompany] = useState("");
  const [meuId, setMeuId] = useState("");
  const [ok, setOk] = useState("");
  const token = localStorage.getItem("token");
  const [licencasEmUso, setlicencasEmUso] = useState("");
  const [licencasRestantes, setlicencasRestantes] = useState("");
  const [showDesativa, setShowDesativa] = useState(false);
  const [showAtiva, setShowAtiva] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertBoasVindas, setShowAlertBoasVindas] = useState(false);

  const [selectedUser, setSelectedUser] = useState(null);
  const [userListCount, setUserListCount] = useState(10);

  const { loginData } = useContext(AuthContext);
  const primaryColor = loginData.primaryColor;

  const handleCloseDesativa = () => setShowDesativa(false);
  const handleShowDesativa = () => setShowDesativa(true);

  const handleCloseAtiva = () => setShowAtiva(false);
  const handleShowAtiva = () => setShowAtiva(true);

  const handleCloseAlert = () => setShowAlert(false);
  const handleShowAlert = () => setShowAlert(true);

  const handleCloseAlertBoasVindas = () => setShowAlertBoasVindas(false);
  const handleShowAlertBoasVindas = () => setShowAlertBoasVindas(true);

  const [resetSenha, setResetSenha] = useState(false);
  const [emailBoasVindas, setEmailBoasVindas] = useState(false);

  const secondaryColor = localStorage.getItem("secondaryColor");

  const isMountedRef = useRef(null);

  /* [Permission Module] - Permissions states */
  const [permissionAddUser, setPermissionAddUser] = useState(false);
  const [permissionImportUsers, setPermissionImportUsers] = useState(false);
  const [permissionAddGroup, setPermissionAddGroup] = useState(false);
  const [permissionEditUser, setPermissionEditUser] = useState(false);
  const [permissionResetUserPassword, setPermissionResetUserPassword] =
    useState(false);
  const [permissionUpdateUserStatus, setPermissionUpdateUserStatus] =
    useState(false);

  /* [Permission Module] - Menu Options */
  async function getMenuOption() {
    const menuOptionList = getMenuRoutes();

    const objCurrentMenu = menuOptionList.filter(
      (menu) => menu.text === "Cadastro de Usuários"
    );

    if (Boolean(objCurrentMenu.length)) {
      const resources = objCurrentMenu.map((menu, index) => menu.resource);

      getPermissionsPage(resources);
    }
  }

  /* [Permission Module] - Permission attrs in page by login data information */
  function getPermissionsPage(resources) { 
    try {
      if (
        Boolean(
          resources[0].filter((menu) => menu.name === "Inserir Usuário").length
        )
      )
        setPermissionAddUser(true);
      if (
        Boolean(
          resources[0].filter((menu) => menu.name === "Envio de Senha").length
        )
      )
        setPermissionResetUserPassword(true);
      if (
        Boolean(
          resources[0].filter((menu) => menu.name === "Edição de Usuários")
            .length
        )
      )
        setPermissionEditUser(true);
      if (
        Boolean(
          resources[0].filter((menu) => menu.name === "Upload de Usuário")
            .length
        )
      )
      setPermissionImportUsers(true);
      if (
        Boolean(
          resources[0].filter((menu) => menu.name === "Criar Grupos").length
        )
      )
        setPermissionAddGroup(true);
      if (
        Boolean(
          resources[0].filter((menu) => menu.name === "Status Usuário").length
        )
      )
        setPermissionUpdateUserStatus(true);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    /* [Permission Module] - Function calls */
    getMenuOption();

    isMountedRef.current = true;

    getUsers();

    return () => (isMountedRef.current = false);
  }, []);

  function handleActiveUser(usuario) {
    Axios.put(
      `${api_url}/users/${usuario.idUser}`,
      {
        email: usuario.email,
        name: usuario.name,
        phone: usuario.phone,
        cpf: usuario.cpf,
        userType: usuario.userType,
        userLicenceTermsAccepted: usuario.userLicenceTermsAccepted,
        communicationAccepted: usuario.communicationAccepted,
        guestCompany: usuario.guestCompany,
        guestCnpj: usuario.guestCnpj,
        admin: usuario.admin,
        active: usuario.active,
        photo: usuario.photo,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        "Content-Type": "application/json",
      }
    )
      .then(({ data }) => {
        if (data.success) {
          getUsers();
        }
      })
      .catch((e) =>
        console.log("Falha ao tentar ativar/desativar o id", usuario.idUser)
      );
  }

  function resetaSenha(email) {
    Axios.post(`${api_url}/login/forgot`, {
      email,
    })
      .then(({ data }) => {
        if (data.success) {
          setResetSenha(true);
        }
      })
      .catch((e) => console.log("Erro ao resetar a senha", e));
  }

  function sendEmailBoasVindas(email) {
    sendEmailBoasVindasApi(email)
  }

  function desativaUser() {
    const usuarioParaDesativar = selectedUser;
    usuarioParaDesativar.active = 0;
    handleCloseDesativa();
    handleActiveUser(usuarioParaDesativar);
  }

  function ativaUser() {
    const usuarioParaAtivar = selectedUser;
    usuarioParaAtivar.active = 1;
    handleCloseAtiva();
    handleActiveUser(usuarioParaAtivar);
  }

  function getUsers() {
    Axios.get(`${api_url}/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      "Content-Type": "application/json",
    })
      .then(({ data }) => {
        if (isMountedRef.current) {
          if (data.success) {
            setCompany(data.company);

            const usuarios = data.userList;
            setMeuId(loginData.id);

            const licencasEmUso = usuarios.filter(function (dado) {
              return dado.active === 1;
            });

            setlicencasEmUso(licencasEmUso.length);
            setlicencasRestantes(
              data.company.numberOfLicences - licencasEmUso.length
            );
            setUsers(usuarios);
            setOk(data.success);
          }
        }
      })
      .catch((e) => console.log("Erro ao listar usuarios", e));
  }

  const editTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Editar Usuário
    </Tooltip>
  );

  const emailTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Enviar e-mail de boas-vindas
    </Tooltip>
  );

  const redefinirSenha = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Redefinir Senha
    </Tooltip>
  );

  const desativarUsuario = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Desativar usuário
    </Tooltip>
  );

  const ativarUsuario = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Ativar usuário
    </Tooltip>
  );

  return (
    <div className="User" style={{ paddingBottom: 50 }}>
      <div className="header-title-wrapper">
        <div className="header-title">Cadastro de usuários</div>
      </div>
      <Modal show={showDesativa} onHide={handleCloseDesativa} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Desativar Usuário?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza de que quer desativar o usuário?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDesativa}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={desativaUser}>
            Desativar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAtiva} onHide={handleCloseAtiva} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Desativar Usuário?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem certeza de que quer ativar o usuário?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAtiva}>
            Cancelar
          </Button>
          <Button variant="success" onClick={ativaUser}>
            Ativar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAlert} onHide={handleCloseAlert} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Reset de senha</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Um e-mail foi enviado para o usuário com instruções de como trocar a
          senha.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={handleCloseAlert}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAlertBoasVindas} onHide={handleCloseAlertBoasVindas} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Boas-Vindas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Um e-mail foi enviado para o usuário com instruções de boas-vindas.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={handleCloseAlertBoasVindas}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <div>
        {ok ? (
          <>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>
                    <strong>CNPJ</strong>
                  </Th>
                  <Th>
                    <strong>EMPRESA</strong>
                  </Th>
                  <Th>
                    <strong>LICENÇAS UTILIZADAS</strong>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>{company.cnpj}</Td>
                  <Td>{company.name}</Td>
                  <Td>
                    {licencasEmUso}/{company.numberOfLicences}
                  </Td>
                </Tr>
              </Tbody>
            </Table>

            <div className={"buttonActionUser"}>
              {permissionAddUser && (
                <ButtonGroup size="lg" className="mb-2">
                  <Button
                    disabled={licencasRestantes <= 0}
                    className="blast-button"
                    style={{ backgroundColor: primaryColor, marginRight: 10 }}
                    onClick={() => history.push('/user/add')}
                  >
                    <FiUserPlus color="white" size={22} />
                    Adicionar Usuário
                  </Button>
                </ButtonGroup>
              )}

              {permissionImportUsers && (
                <ButtonGroup size="lg" className="mb-2">
                  <Button
                    className="blast-button"
                    style={{ backgroundColor: primaryColor, marginRight: 10 }}
                    onClick={() => history.push('/import-users')}
                  >
                    <PublishOutlinedIcon />
                    Importar Usuários
                  </Button>
                </ButtonGroup>
              )}

              {permissionAddGroup && (
                <ButtonGroup size="lg" className="mb-2">
                  <Button
                    className="blast-button"
                    style={{ backgroundColor: primaryColor }}
                    onClick={() => history.push('/configuracao/grupos')}
                  >
                    <PeopleOutlineIcon size={22} />
                    Adicionar Grupo
                  </Button>
                </ButtonGroup>
              )}
            </div>

            <div
              className="post-form"
              style={{ width: "100%", marginTop: "0.5rem" }}
            >
              <div className="form-group">
                <div className="card">
                  <div
                    className="card-header template-card-header"
                    style={{ backgroundColor: primaryColor }}
                  >
                    Procurar Usuário
                  </div>
                  <div className="card-body template-card-body">
                    <div className="input-container">
                      <input
                        placeholder="Procurar por nome ou email (Digite pelo menos 3 caracteres)"
                        type="text"
                        className="form-control input-default"
                        id="groupDetail"
                        aria-describedby="usuario"
                        value={currentUser}
                        onChange={(e) => setCurrentUser(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Table striped bordered hover size="lg" style={{ marginBottom: 0 }}>
              <Thead>
                <Tr>
                  <Th>PERMISSÃO</Th>
                  <Th>NOME</Th>
                  <Th>EMAIL</Th>
                  <Th>ATIVO</Th>
                  {(permissionEditUser ||
                    permissionResetUserPassword ||
                    permissionUpdateUserStatus) && <Th>AÇÕES</Th>}
                </Tr>
              </Thead>

              <Tbody>
                <FilterResults
                  value={currentUser.length >= 3 ? currentUser : ""}
                  data={
                    currentUser.length < 3
                      ? users.slice(0, userListCount)
                      : users
                  }
                  renderResults={(results) => (
                    <>
                      {results.map((item, index) => (
                        <Tr
                          key={item.idUser}
                          style={{
                            backgroundColor:
                              index % 2 === 0
                                ? `${secondaryColor.trim()}22`
                                : `${secondaryColor.trim()}14`,
                          }}
                        >
                          {item.admin === 1 ? (
                            <Td>
                              <Badge variant="success">ADMIN</Badge>
                            </Td>
                          ) : (
                            <Td></Td>
                          )}
                          <Td>{item.name}</Td>
                          <Td>{item.email}</Td>
                          {item.active === 1 ? (
                            <Td>
                              <Badge variant="primary">SIM</Badge>
                            </Td>
                          ) : (
                            <Td>
                              <Badge variant="secondary">NÃO</Badge>
                            </Td>
                          )}
                          {(permissionEditUser ||
                            permissionResetUserPassword ||
                            permissionUpdateUserStatus) && (
                            <Td style={{ textAlign: "center" }}>
                              {permissionEditUser && (
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 0, hide: 0 }}
                                  overlay={editTooltip}
                                >
                                  <MdEdit
                                    onClick={() => goToEdit(item.idUser)}
                                    style={{
                                      cursor: "pointer",
                                      fontSize: 22,
                                    }}
                                  />
                                </OverlayTrigger>
                              )}

                              {permissionResetUserPassword && (
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 0, hide: 0 }}
                                  overlay={redefinirSenha}
                                >
                                  <RiLockPasswordFill
                                    onClick={() => {
                                      resetaSenha(item.email);
                                      handleShowAlert();
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      fontSize: 22,
                                    }}
                                  />
                                </OverlayTrigger>
                              )}
                              {permissionUpdateUserStatus &&
                              meuId != item.idUser ? (
                                item.active === 1 ? (
                                  <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 0, hide: 0 }}
                                    overlay={desativarUsuario}
                                  >
                                    <FaMinusCircle
                                      onClick={() => {
                                        setSelectedUser(item);
                                        handleShowDesativa();
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        fontSize: 20,
                                        color: "red",
                                        marginLeft: 3,
                                      }}
                                    />
                                  </OverlayTrigger>
                                ) : licencasRestantes > 0 ? (
                                  <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 0, hide: 0 }}
                                    overlay={ativarUsuario}
                                  >
                                    <FaCheckCircle
                                      onClick={() => {
                                        setSelectedUser(item);
                                        handleShowAtiva();
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        fontSize: 20,
                                        color: "green",
                                        marginLeft: 3,
                                      }}
                                    />
                                  </OverlayTrigger>
                                ) : null
                              ) : null}
                               <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 0, hide: 0 }}
                                  overlay={emailTooltip}
                                >
                                  <MdEmail
                                    onClick={() => {
                                      sendEmailBoasVindas(item.email);
                                      handleShowAlertBoasVindas();
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      fontSize: 22,
                                    }}
                                  />
                                </OverlayTrigger>
                            </Td>
                          )}
                        </Tr>
                      ))}
                    </>
                  )}
                />
              </Tbody>
            </Table>
            {currentUser.length < 3 && (
              <div
                className="user-show-more"
                onClick={() => setUserListCount(userListCount + 10)}
              >
                Mostrar mais...
              </div>
            )}
            <div style={{ flexBasis: "100%" }} />
            <div>
              <h6>
                * Usuário ativos são aqueles que já acessaram o aplicativo e
                aceitaram os termos de uso.
              </h6>
            </div>
          </>
        ) : (
          <Loading center>Carregando usuários</Loading>
        )}
      </div>
    </div>
  );
}
