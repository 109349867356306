import React, { useState, useContext, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { colors } from "../utils/Colors";
import { setLoginData, API_URL } from "../services/NexiDigitalAPI";
import SnackComponent from "../components/SnackComponent";


export default function LoginForm({ ativarCadastro, appDetailsData, urlToken }) {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [resetSenha, setResetSenha] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackInfo, setSnackInfo] = useState({
    message: "",
    severity: "success",
  });

  const authContext = useContext(AuthContext);

  function resetaSenha(email) {
    if (email.length === 0) {
      setSnackInfo({
        message: "Por favor informar o e-mail para resetar a senha",
        severity: "error",
      });
      setOpenSnackBar(true);
    } else {
      axios
        .post(
          `${API_URL}/login/forgot`,
          {
            email,
          },
          { "Content-Type": "application/json" }
        )
        .then(({ data }) => {
          if (data.success) {
            setSnackInfo({
              message:
                "Solicitação concluída, confira seu e-mail para efetuar a troca de senha",
              severity: "success",
            });
            setOpenSnackBar(true);
            setResetSenha(true);
          }
        })
        .catch((e) => {
          setSnackInfo({
            message: "Erro ao resetar a senha",
            severity: "error",
          });
          setOpenSnackBar(true);
        });
    }
  }

  function onSuccessLogin(data) {
    if (data.success === true && data.login.active === 1) {
      const menuOption = data?.menu?.[0]?.menuOption || [];
      localStorage.setItem("menu-option", JSON.stringify(menuOption));
      setLoginData(data);
      setLoading(false);
      authContext.signIn(data.token, data.login);
      history.push("/");
    } else {
      setSnackInfo({
        message: "Usuário ou senha inválidos",
        severity: "error",
      });
      setOpenSnackBar(true);
      setLoading(false);
    }
  }

  function onErrorLogin(e) {
    console.log("falha no login", e);
    setLoading(false);
  }

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function isAutomaticLogin() {
    return urlToken && !email && !password;
  }

  async function handleSubmit(event) {
    event && event.preventDefault();
    setLoading(true);
    try {
      if (isAutomaticLogin()) {
        return axios
          .post(
            `${API_URL}/logincpanel/renewlogin`,
            {},
            {
              "Content-Type": "application/json",
              headers: {
                Authorization: "Bearer " + urlToken,
              },
            }
          )
          .then(({ data }) => onSuccessLogin(data))
          .catch((e) => onErrorLogin(e));
      }

      if (validateForm()) {
        axios
          .post(
            `${API_URL}/logincpanel`,
            {
              email,
              password,
              idAppType: appDetailsData.idAppType,
            },
            { "Content-Type": "application/json" }
          )
          .then(({ data }) => onSuccessLogin(data))
          .catch((e) => onErrorLogin(e));
      } else {
        console.log("não logado");
        setLoading(false);
      }
    } catch (e) {
      alert(e.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    isAutomaticLogin() && handleSubmit();
  }, []);

  return (
    <div className="Login">
      <SnackComponent
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
        message={snackInfo.message}
        severity={snackInfo.severity}
      />
      <Form onSubmit={handleSubmit}>
        <Form.Group
          controlId="formBasicEmail"
          className="login-input-container"
        >
          <Form.Label>Email</Form.Label>
          <TextField
            inputProps={{ style: { backgroundColor: colors.blueSmooth } }}
            variant="outlined"
            placeholder="Digite seu email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
          />
        </Form.Group>

        <Form.Group
          controlId="formBasicPassword"
          className="login-input-container"
        >
          <Form.Label>Senha</Form.Label>
          <TextField
            inputProps={{ style: { backgroundColor: colors.blueSmooth } }}
            variant="outlined"
            placeholder="Digite sua senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />
        </Form.Group>

        <div className="login-button-container">
          <Button
            style={{
              backgroundColor: appDetailsData.primaryColor,
              borderColor: appDetailsData?.secondaryColor,
              width: "100%",
              color: colors.white,
            }}
            variant="contained"
            size="large"
            type="submit"
          >
            {isLoading ? "Conectando..." : "Entrar"}
          </Button>

          <div className="login-forget-container">
            <Form.Text>
              <div
                className="login-forget-text"
                onClick={() => resetaSenha(email)}
              >
                Esqueci minha senha
              </div>
            </Form.Text>
            <Form.Text>
              <div
                className="login-forget-text"
                onClick={() => ativarCadastro()}
              >
                Ativar Contrato
              </div>
            </Form.Text>
          </div>
        </div>
      </Form>
    </div>
  );
}
