import React, { useContext, useState } from "react";
import { Button, Form, Card } from "react-bootstrap";
import { useHistory, Redirect } from "react-router-dom";
import axios from "axios";
import MaskInput from 'react-maskinput';
import BreadCrumbPhase1 from '../assets/images/breadcrumb01.png';
import BreadCrumbPhase2 from '../assets/images/breadcrumb02.png';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getAppType } from "../utils/Utils"
import { AuthContext } from "../context/AuthContext";

const api_url = process.env.REACT_APP_BACKEND_API;

export default function AtivaCadastro({ ativarCadastro, primaryColor }) {
    const [fone, setFone] = useState("");
    const [pin, setPin] = useState("");
    const [pinValido, setPinValido] = useState("");
    const [habilitaTela2, setHabilitaTela2] = useState(false);
    const [erro, setErro] = useState("");
    const [mensagem, setMensagem] = useState("");
    const [redirectTerms, setRedirectTerms] = useState(false);
    const [findLoading, setFindLoading] = useState(false);
    const history = useHistory();

    const { loginData } = useContext(AuthContext)

    function validateForm1() {
        return fone.length > 0;
    }

    function validateForm2() {
        return pin.length > 0;
    }

    async function handleSubmit1(event) {
        event.preventDefault();
        setErro(false);
        try {
            if (validateForm1()) {
                setFindLoading(true)
                axios
                    .post(`${api_url}/contract/sendpin`, {
                        phone: fone,
                        idAppType: getAppType()
                    }, { "Content-Type": "application/json" }
                    )
                    .then(({ data }) => {
                        if (data.success) {
                            setHabilitaTela2(true);
                            setPinValido(data.pin)
                        } else {
                            setErro(true);
                            setMensagem(data.message);
                            console.log('data', data)
                        }
                    })
                    .catch((e) => {
                        console.log("Erro ao enviar o numero do telefone")
                    }).finally(() => {
                      setFindLoading(false)
                    })
            }

        } catch (e) {
            alert(e.message);
        }
    }

    async function handleSubmit2(event) {
        event.preventDefault();

        try {
            if (validateForm2()) {
                axios
                    .post(`${api_url}/contract/checkpin`, {
                        phone: fone,
                        pin
                    }, { "Content-Type": "application/json" }
                    )
                    .then(({ data }) => {
                        if (data.success) {
                            localStorage.setItem("fone", fone)
                            localStorage.setItem("pin", pin)

                            // redireciona para a tela de termos
                            setRedirectTerms(true)
                            // history.push("/termos");
                        }
                        else {
                            setErro(true);
                            setMensagem(data.message);
                        }
                    })
                    .catch((e) => {
                        console.log("Erro ao enviar o numero do telefone")
                    })
            }

        } catch (e) {
            alert(e.message);
        }
    }

    if(redirectTerms){
        return(
            <Redirect to="/termos" />
        )
    }

    return (
        <div className="ativa-cadastro">
            <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer', marginBottom: 10, position: 'absolute', zIndex: 999 }} onClick={() => ativarCadastro()}>
                <div style={{ fontWeight: 'bold', marginRight: 5, fontSize: 20 }}> {'<'} </div> <div style={{ marginTop: 5 }}>Voltar</div>
            </div>
            <div style={{ marginTop: 50, marginBottom: 15 }}>
                <img src={habilitaTela2 ? BreadCrumbPhase2 : BreadCrumbPhase1} style={{ width: '100%' }} />
            </div>

            <div className="ativa-container">
                {
                    habilitaTela2
                        ? (
                            <Form onSubmit={handleSubmit2}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>PIN</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Digite o número do PIN"
                                        value={pin}
                                        onChange={e => setPin(e.target.value)}
                                    />
                                    <Form.Text className="text-muted">
                                        Digite o PIN enviado por SMS para o seu celular.
                    </Form.Text>
                                </Form.Group>

                                <Button className="blast-button" style={{ backgroundColor: primaryColor }} disabled={!validateForm2()} type="submit">
                                    Ativar Cadastro
                </Button>
                            </Form>
                        )
                        : (
                            <Form onSubmit={handleSubmit1}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Telefone</Form.Label>
                                    <br />
                                    <MaskInput
                                        alwaysShowMask
                                        mask={'(00) 00000-0000'}
                                        size={20}
                                        showMask
                                        maskChar="_"
                                        placeholder="Digite o número do seu celular"
                                        value={fone}
                                        onChange={e => setFone(e.target.value)}
                                    />
                                    <Form.Text className="text-muted">
                                        Use o telefone que consta no seu cadastro.
                                    </Form.Text>
                                </Form.Group>
                                <Button style={{ background:  primaryColor, borderColor: primaryColor, width: '100%' }} disabled={!validateForm1()} type="submit">
                                    Buscar Contrato
                                    {
                                      findLoading &&
                                      <CircularProgress 
                                        style={{ 
                                          height: 20, 
                                          width: 20, 
                                          color: 'white',
                                          marginLeft: 10,
                                          marginBottom: -4, 
                                        }} 
                                      />
                                    }
                                </Button>
                            </Form>
                        )

                }
                {
                    erro && <Card style={{ marginTop: 15, padding: '0px 5px' }} bg='warning' text='dark'> {mensagem}</Card>
                }
            </div>

        </div>
    );
}
