import React, { useState, useEffect } from "react";
import {
    Col,
    Form,
} from "react-bootstrap";
import {
    FaFilter,
} from "react-icons/fa";
import FilterWrapper from '../../../../../components/FilterWrapper';
import styles from './styles.module.scss';
import ButtonWithLoading from "../../../../../components/ButtonWithLoading";
import { DatePickerInput } from "rc-datepicker";
import moment from "moment";
import "moment/locale/pt-br.js";

const Filter = ({ actionFilter, initialInfo, loading }) => {

    const [value, setValue] = useState(initialInfo)
    const submitFilter = () => {
        actionFilter(value)
    }

    const changeStartData = (jsDate) => {
        setValue({
            ...value,
            startDate:  moment(jsDate).format("YYYY-MM-DD")
        })
    }

    const changeEndData = (jsDate) => {
        setValue({
            ...value,
            endDate:  moment(jsDate).format("YYYY-MM-DD")
        })
    }

    return (
        <FilterWrapper>
            <Form style={{ marginTop: 30, marginBottom: 30 }}>
                <Form.Row className="align-items-end" style={{justifyContent: 'space-between',}}>
                    <Col xs="12" md="4" lg="2" className={styles.formSpace}>
                        <Form.Label htmlFor="dateStart">Data inicial</Form.Label>
                        <DatePickerInput
                            name="dateStart"
                            locale="pt-br"
                            onChange={changeStartData}
                            value={value.startDate}
                        />
                    </Col>
                    <Col xs="12" md="4" lg="2" className={styles.formSpace}>
                        <Form.Label htmlFor="dateStart">Data final</Form.Label>
                        <DatePickerInput
                            name="dateStart"
                            locale="pt-br"
                            onChange={changeEndData}
                            value={value.endDate}
                        />
                    </Col>
                    <Col xs="12" md="4" lg="2" className={styles.formSpace}>
                    <Form.Group className="mb-0">
                        <ButtonWithLoading 
                            onClickHandler={submitFilter}
                            loading={loading}
                            text={(<> Aplicar filtro <FaFilter style={{ marginLeft: 5 }} /> </>)}
                        />
                    </Form.Group>
                    </Col>
                </Form.Row>
            </Form>
        </FilterWrapper>
    );
}

export default Filter;